import React, { useContext, useState } from 'react';
import { VariantContext } from '../../hooks/VariantContext';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { CssBaseline, Container, Box, Grid, Avatar, Typography, TextField, Button, Link } from '@material-ui/core';
import { withStyles, makeStyles } from '@material-ui/core';
import SuccessIcon from '@material-ui/icons/CheckCircleOutline';
import ErrorIcon from '@material-ui/icons/ErrorOutline';
import styles from './Login.styles';

const validationSchema = Yup.object({
  email: Yup.string('Enter your email').email('Enter a valid email').required('Email is required'),
});

const Login = (props) => {
  const useStyles = makeStyles((theme) => styles(theme));
  const classes = useStyles();

  const [passwordResetSuccess, setPasswordResetSuccess] = useState(null);
  const { appVariant } = useContext(VariantContext);

  const { handleSubmit, handleChange, values, touched, errors } = useFormik({
    initialValues: {
      email: '',
    },
    validationSchema,
    onSubmit: (values, { setStatus, setErrors, setFieldError }) => {
      const payload = {
        email: values.email,
      };

      fetch(`${process.env.REACT_APP_API_URL}/auth/forgot-password`, {
        method: 'POST',
        body: JSON.stringify(payload),
        headers: { 'Content-Type': 'application/json' },
      }).then((res) => {
        if (res.status === 204) {
          /*res.json().then((data) => {
            console.log('DATA', data)
          });*/
          setPasswordResetSuccess(true);
        } else {
          setPasswordResetSuccess(false);
          /*res.json().then((error) => {
            if (error.code === 401) {
              setFieldError('password', error.message);
            } else {
              setFieldError('api', error.message);
            }
          });*/
        }
      });
    },
  });

  if (passwordResetSuccess !== null) {
    return (
      <Grid
        container
        spacing={0}
        direction="column"
        alignItems="center"
        align="center"
        justifyContent="center"
        style={{ minHeight: '80vh' }}
      >
        <Grid item xs={6}>
          <Box>
            {passwordResetSuccess === true && <SuccessIcon style={{ fontSize: '60px', color: '#9ccc65' }} />}
            {passwordResetSuccess === false && <ErrorIcon style={{ fontSize: '60px', color: '#ff6961' }} />}
            <br />
            <br />
            <Typography variant="h5">
              {passwordResetSuccess === true && (
                <b>Du erhältst in Kürze eine E-Mail mit den Anweisungen zum Zurücksetzen Deines Passworts.</b>
              )}
              {passwordResetSuccess === false && <b>Das Passwort konnte nicht zurückgesetzt werden.</b>}
            </Typography>
          </Box>
        </Grid>
      </Grid>
    );
  }

  return (
    <Container component="main" maxWidth="xs" style={{ minHeight: '75vh' }}>
      <CssBaseline />
      <div className={classes.paper}>
        {appVariant === 'bloomup' && <Avatar src="/images/login-symbol.png"></Avatar>}
        {appVariant === 'ljs' && <Avatar src="/images/login-symbol-ljs.png"></Avatar>}
        <Typography component="h1" variant="h5">
          Reset password
        </Typography>
        <form onSubmit={handleSubmit} className={classes.form} noValidate>
          <TextField
            variant="outlined"
            margin="normal"
            required
            fullWidth
            id="email"
            label="Email Address"
            name="email"
            autoComplete="email"
            autoFocus
            value={values.email}
            onChange={handleChange}
            error={touched.email && Boolean(errors.email)}
            helperText={touched.email && errors.email}
          />
          <Button type="submit" fullWidth variant="contained" color="primary" className={classes.submit}>
            Reset password
          </Button>
          <Grid container>
            <Grid item xs>
              <Link href="/login" variant="body2">
                Back to login
              </Link>
            </Grid>
          </Grid>
        </form>
      </div>
    </Container>
  );
};

export default withStyles(styles, { withTheme: true })(Login);
