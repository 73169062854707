import React, { Component } from 'react';

import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';

class HomePage extends Component {
  render() {
    return (
      <div>
        <Grid container spacing={0} direction="column" alignItems="center" justify="center" style={{ minHeight: '150vh' }}>
          <Grid item xs={6} style={{ maxWidth: '60vw' }}>
            <Typography variant="body1" gutterBottom style={{ padding: '10px' }}>
              <p>
                <b>Datenschutzerklärung für die Nutzung von Google Analytics</b>
              </p>
              <p>
                Diese Website benutzt Google Analytics, einen Webanalysedienst der Google Inc. ("Google"). Google Analytics
                verwendet sog. "Cookies", Textdateien, die auf Ihrem Computer gespeichert werden und die eine Analyse der
                Benutzung der Website durch Sie ermöglichen. Die durch den Cookie erzeugten Informationen über Ihre Benutzung
                dieser Website werden in der Regel an einen Server von Google in den USA übertragen und dort gespeichert. Im
                Falle der Aktivierung der IP-Anonymisierung auf dieser Webseite wird Ihre IP-Adresse von Google jedoch
                innerhalb von Mitgliedstaaten der Europäischen Union oder in anderen Vertragsstaaten des Abkommens über den
                Europäischen Wirtschaftsraum zuvor gekürzt.
              </p>
              <p>
                Nur in Ausnahmefällen wird die volle IP-Adresse an einen Server von Google in den USA übertragen und dort
                gekürzt. Im Auftrag des Betreibers dieser Website wird Google diese Informationen benutzen, um Ihre Nutzung
                der Website auszuwerten, um Reports über die Websiteaktivitäten zusammenzustellen und um weitere mit der
                Websitenutzung und der Internetnutzung verbundene Dienstleistungen gegenüber dem Websitebetreiber zu
                erbringen. Die im Rahmen von Google Analytics von Ihrem Browser übermittelte IP-Adresse wird nicht mit
                anderen Daten von Google zusammengeführt.
              </p>
              <p>
                Sie können die Speicherung der Cookies durch eine entsprechende Einstellung Ihrer Browser-Software
                verhindern; wir weisen Sie jedoch darauf hin, dass Sie in diesem Fall gegebenenfalls nicht sämtliche
                Funktionen dieser Website vollumfänglich werden nutzen können. Sie können darüber hinaus die Erfassung der
                durch das Cookie erzeugten und auf Ihre Nutzung der Website bezogenen Daten (inkl. Ihrer IP-Adresse) an
                Google sowie die Verarbeitung dieser Daten durch Google verhindern, indem sie das unter dem folgenden Link
                verfügbare Browser-Plugin herunterladen und installieren:{' '}
                <a href="https://tools.google.com/dlpage/gaoptout?hl=de" target="_blank" rel="noreferrer">
                  https://tools.google.com/dlpage/gaoptout?hl=de
                </a>
                .
              </p>
              <p>Für die Inhalte dieser Seiten ist die LJS Media Group GmbH verantwortlich.</p>
              <p>Für die Inhalte zu anderen verlinkten Domains wird keine Verantwortung und Haftung übernommen.</p>
              <p>
                <a href="www.getbloomup.com">www.getbloomup.com</a> ist eine Informations-, Kontakt-, und
                Präsentationsplattform der Firma LJS Media Group GmbH.
              </p>

              <p>
                <b>Haftung für Inhalte</b>
              </p>
              <p>
                Alle Inhalte wurden mit größter Sorgfalt zusammengestellt. Trotzdem können inhaltliche Fehler nicht
                ausgeschlossen werden. Alle Angaben erfolgen ohne Gewähr. Eine Haftung für Schäden, die sich aus der
                Verwendung der in www.imt-sales.com veröffentlichten Inhalte ergeben, ist ausgeschlossen. Als Diensteanbieter
                sind wir gemäß § 7 Abs.1 TMG für eigene Inhalte auf diesen Seiten nach den allgemeinen Gesetzen
                verantwortlich. Nach §§ 8 bis 10 TMG sind wir als Diensteanbieter jedoch nicht verpflichtet, übermittelte
                oder gespeicherte fremde Informationen zu überwachen oder nach Umständen zu forschen, die auf eine
                rechtswidrige Tätigkeit hinweisen. Verpflichtungen zur Entfernung oder Sperrung der Nutzung von Informationen
                nach den allgemeinen Gesetzen bleiben hiervon unberührt. Eine diesbezügliche Haftung ist jedoch erst ab dem
                Zeitpunkt der Kenntnis einer konkreten Rechtsverletzung möglich. Bei Bekanntwerden von entsprechenden
                Rechtsverletzungen werden wir diese Inhalte umgehend entfernen.
              </p>

              <p>
                <b>Haftung für Links </b>
              </p>
              <p>
                Unser Angebot enthält Links zu externen Websites Dritter, auf deren Inhalte wir keinen Einfluss haben.
                Deshalb können wir für diese fremden Inhalte auch keine Gewähr übernehmen. Für die Inhalte der verlinkten
                Seiten ist stets der jeweilige Anbieter oder Betreiber der Seiten verantwortlich. Die verlinkten Seiten
                wurden zum Zeitpunkt der Verlinkung auf mögliche Rechtsverstöße überprüft. Rechtswidrige Inhalte waren zum
                Zeitpunkt der Verlinkung nicht erkennbar.
              </p>
              <p>
                Eine permanente inhaltliche Kontrolle der verlinkten Seiten ist jedoch ohne konkrete Anhaltspunkte einer
                Rechtsverletzung nicht zumutbar. Bei Bekanntwerden von Rechtsverletzungen werden wir derartige Links umgehend
                entfernen.
              </p>

              <p>
                <b>Urheberrecht</b>
              </p>
              <p>
                Die durch die Seitenbetreiber erstellten Inhalte und Werke auf diesen Seiten unterliegen dem deutschen
                Urheberrecht. Die Vervielfältigung, Bearbeitung, Verbreitung und jede Art der Verwertung außerhalb der
                Grenzen des Urheberrechtes bedürfen der schriftlichen Zustimmung des jeweiligen Autors bzw. Erstellers.
                Downloads und Kopien dieser Seite sind nur für den privaten, nicht kommerziellen Gebrauch gestattet.
              </p>
              <p>
                Soweit die Inhalte auf dieser Seite nicht vom Betreiber erstellt wurden, werden die Urheberrechte Dritter
                beachtet. Insbesondere werden Inhalte Dritter als solche gekennzeichnet. Sollten Sie trotzdem auf eine
                Urheberrechtsverletzung aufmerksam werden, bitten wir um einen entsprechenden Hinweis. Bei Bekanntwerden von
                Rechtsverletzungen werden wir derartige Inhalte umgehend entfernen.
              </p>

              <p>
                Quelle: <a href="https://www.e-recht24.de">https://www.e-recht24.de</a>
              </p>

              <p>
                <b>Betreiber</b>
              </p>
              <p>
                <a href="www.getbloomup.com">www.getbloomup.com </a> wird von der Firma LJS Media Group GmbH betrieben.{' '}
              </p>
            </Typography>
          </Grid>
        </Grid>
      </div>
    );
  }
}

export default HomePage;
