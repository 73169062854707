import { createTheme } from '@material-ui/core/styles';

export const theme = createTheme({
  typography: {
    button: {
      textTransform: 'none',
    },
  },
  palette: {
    background: {
      default: 'white',
    },
    primary: {
      light: '#9A92DF',
      main: '#312783',
      dark: '#5E50CB',
      contrastText: '#fff',
    },
    text: {
      primary: 'rgba(50, 50, 50, 1)',
      secondary: 'rgba(100, 100, 120, 1)',
    },
  },
});

export default theme;
