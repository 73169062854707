import React, { useEffect, useState, useContext } from 'react';
import { Link } from 'react-router-dom';
import { withStyles } from '@material-ui/core/styles';
import {
  Container,
  Grid,
  Box,
  Card,
  CardContent,
  CardActions,
  Paper,
  Modal,
  TextField,
  FormControl,
  FormControlLabel,
  FormLabel,
  Radio,
  RadioGroup,
  Stack,
  Button,
  Typography,
  CircularProgress,
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import {
  Elements,
  useStripe,
  useElements,
  CardElement,
  PaymentElement,
  IbanElement,
  SofortElement,
  SepaElement,
  KlarnaElement,
} from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js';
import { UserContext } from '../hooks/UserContext';

const useStyles = makeStyles((theme) => ({
  paper: {
    position: 'absolute',
    width: '600px',
    backgroundColor: theme.palette.background.paper,
    outline: 'none',
    borderRadius: 5,
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 3),
  },
  stripeBox: {
    width: '100%',
    margin: '25px 0 0 0',
    padding: '10px',
  },
  cardElement: {
    padding: '10px',
    marginBottom: '20px',
  },
}));

const StripeCheckoutModal = (props) => {
  const { modalOpen, addressData, couponCode, paymentMethods, onComplete, onCancel } = props;

  const { user } = useContext(UserContext);

  const classes = useStyles();

  const width = 500;
  const height = 400;

  const [paymentMethod, setPaymentMethod] = useState('card');
  //const [modalClosed, setModalClosed] = useState(false);
  const [paymentProcessing, setPaymentProcessing] = useState(false);
  const [paymentError, setPaymentError] = useState(null);
  const [paymentSuccess, setPaymentSuccess] = useState(null);

  const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_API_PUBLISHABLE_KEY);

  const handleClose = () => {
    //setModalClosed(true);
    if (onCancel) onCancel();
    //setModalClosed(false);
  };

  function getModalStyle() {
    const top = 50;
    const left = 50;

    return {
      top: `${top}%`,
      left: `${left}%`,
      transform: `translate(-${top}%, -${left}%)`,
    };
  }

  const [modalStyle] = useState(getModalStyle);

  const CardForm = (cardProps) => {
    const stripe = useStripe();
    const elements = useElements();

    const { method } = cardProps;

    const cardOptions = {
      hidePostalCode: true,
      style: {
        base: {
          iconColor: '#74019b',
          color: '#981869',
          fontSize: '20px',
          fontFamily: '"Open Sans", sans-serif',
          fontSmoothing: 'antialiased',
          '::placeholder': {
            color: '#CFD7DF',
          },
        },
        invalid: {
          color: '#e5424d',
          ':focus': {
            color: '#303238',
          },
        },
      },
    };

    const ibanOptions = {
      supportedCountries: ['SEPA'],
    };

    const sofortOptions = {};

    function handlePaymentThatRequiresCustomerAction({ subscription, invoice, priceId, paymentMethodId, isRetry }) {
      if (subscription && subscription.status === 'active') {
        // Subscription is active, no customer actions required.
        return { subscription, priceId, paymentMethodId };
      }

      // If it's a first payment attempt, the payment intent is on the subscription latest invoice.
      // If it's a retry, the payment intent will be on the invoice itself.
      let paymentIntent = invoice ? invoice.payment_intent : subscription.latest_invoice.payment_intent;

      if (
        paymentIntent.status === 'requires_action' ||
        (isRetry === true && paymentIntent.status === 'requires_payment_method')
      ) {
        return stripe
          .confirmCardPayment(paymentIntent.client_secret, {
            payment_method: paymentMethodId,
          })
          .then((result) => {
            if (result.error) {
              // Start code flow to handle updating the payment details.
              // Display error message in your UI.
              // The card was declined (i.e. insufficient funds, card has expired, etc).
              throw result;
            } else {
              if (result.paymentIntent.status === 'succeeded') {
                // Show a success message to your customer.
                // There's a risk of the customer closing the window before the callback.
                // We recommend setting up webhook endpoints later in this guide.
                return {
                  priceId: priceId,
                  subscription: subscription,
                  invoice: invoice,
                  paymentMethodId: paymentMethodId,
                };
              }
            }
          })
          .catch((error) => {
            console.error(error);
          });
      } else {
        // No customer action needed.
        return { subscription, priceId, paymentMethodId };
      }
    }

    function handleRequiresPaymentMethod({ subscription, paymentMethodId, priceId }) {
      if (subscription.status === 'active') {
        // subscription is active, no customer actions required.
        return { subscription, priceId, paymentMethodId };
      } else if (subscription.latest_invoice.payment_intent.status === 'requires_payment_method') {
        // Using localStorage to manage the state of the retry here,
        // feel free to replace with what you prefer.
        // Store the latest invoice ID and status.
        localStorage.setItem('latestInvoiceId', subscription.latest_invoice.id);
        localStorage.setItem('latestInvoicePaymentIntentStatus', subscription.latest_invoice.payment_intent.status);
        throw { error: { message: 'Your card was declined.' } };
      } else {
        return { subscription, priceId, paymentMethodId };
      }
    }

    function onSubscriptionComplete(result) {
      // Payment was successful.
      if (result.subscription.status === 'active') {
        // Change your UI to show a success message to your customer.
        setPaymentSuccess(true);
        if (onComplete) onComplete(result);
        // Call your backend to grant access to your service based on
        // `result.subscription.items.data[0].price.product` the customer subscribed to.
      }

      console.log('ON SUBSCRIPTION COMPLETE');

      return result;
    }

    const handleStripeSubscriptionPurchase = async (attributes) => {
      const hasAlreadySubscribed = false;
      // TODO: is simultaneous subscription / product payment even sensible?
      // User should not be able to purchase prints if there is no app subscription
      if (hasAlreadySubscribed) {
        return;
      }

      console.log('HANDLE SUB PURCHASE', attributes);

      const billingDetails = {
        name: `${user?.addresses?.billing.firstname} ${user?.addresses?.billing.lastname}`,
        email: user.email,
        address: {
          line1: user?.addresses?.billing.address,
          postal_code: user?.addresses?.billing.zipcode,
          city: user?.addresses?.billing.city,
          state: '',
        },
      };

      const { paymentMethod } = await stripe.createPaymentMethod({
        type: 'card',
        card: elements.getElement(CardElement),
        billing_details: billingDetails,
      });

      console.log('PAYMENT METHOD', paymentMethod);

      if (!paymentMethod) return;

      setPaymentError(null);
      setPaymentProcessing(true);

      //const interval = product.attributes.find((attribute) => attribute.name === 'payment_interval');
      //const paymentInterval = interval.options[interval.position];
      const subscriptionType = props.params.type;
      const paymentInterval = props.params.interval;
      const productId = props.params.product;
      const price = props.amount;

      const payload = {
        email: user.email,
        userId: user.id,
        productType: subscriptionType,
        productId: productId,
        price: price,
        paymentMethodId: paymentMethod.id,
        paymentInterval: paymentInterval,
        referredBy: addressData.referredBy,
        //paymentMethodId: 'pm_1JalGTGoLMWq8PGdvEW1wLKX',
        //priceId: '<price id>',
      };

      console.log('SUBSCRIPTION PAYLOAD', payload);

      const purchaseTypeUrl = !user.subscribed ? 'purchase-subscription' : 'change-subscription';

      const subscription = await fetch(`${process.env.REACT_APP_API_URL}/payments/${purchaseTypeUrl}`, {
        method: 'POST',
        body: JSON.stringify(payload),
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${localStorage.getItem('token')}`,
        },
      })
        .then((response) => response.json())
        .then((result) => {
          console.log('PAYMENT ERROR:', result);
          setPaymentProcessing(false);
          if (result.code === 400 || result.error) {
            setPaymentError(result.error.message);
            throw result;
          }
          //if (result.error) {
          // The card had an error when trying to attach it to a customer.
          //throw result;
          //}
          return result;
        })
        .then((result) => {
          //setPaymentProcessing(false);
          return {
            paymentMethodId: paymentMethod.id,
            priceId: result.plan.id,
            subscription: result,
          };
        })
        .then(handlePaymentThatRequiresCustomerAction)
        .then(handleRequiresPaymentMethod)
        .then(onSubscriptionComplete)
        .catch((error) => {
          console.error('CARD ERROR:', error);
        });
    };

    function onProductComplete(result) {
      // Payment was successful.
      console.log('ON PRODUCT COMPLETE', result);
      if (result.productId) {
        // Change your UI to show a success message to your customer.
        setPaymentSuccess(true);
        if (onComplete) onComplete(result);
        // Call your backend to grant access to your service based on
        // `result.subscription.items.data[0].price.product` the customer subscribed to.
      }

      return result;
    }

    const handleStripePrintPurchase = async (attributes, cart) => {
      //const emptyValues = Object.entries(addressValues).filter(([k, v], i) => !v).length;
      //console.log(emptyValues);
      //if (emptyValues) return;

      const product = cart[0];

      const productIds = {
        hardcover_211x211: 847,
        softcover_211x211: 478,
        hardcover_210x297: 886,
        softcover_210x297: 888,
        pdf_211x211: 848,
        pdf_210x297: 848,
      };

      const productId = productIds[`${product.output}_${product.format}`];

      const price = props.amount;
      const shippingCosts = props.shippingCosts;

      const payload = {
        productId: productId,
        quantity: attributes.quantity,
        pages: attributes.pages,
        coupon: props.couponCode,
        //amount: price,
        //shipping: shippingCosts,
        //output: product.output,
        currency: 'eur',
        //method: method,
      };

      console.log('INTENT PAYLOAD', payload);

      const paymentIntent = await fetch(`${process.env.REACT_APP_API_URL}/payments/create-intent`, {
        method: 'POST',
        body: JSON.stringify(payload),
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${localStorage.getItem('token')}`,
        },
      })
        .then((response) => response.json())
        .catch((error) => {
          console.error('PAYMENT INTENT ERROR:', error);
        });

      const cardElement = elements.getElement(CardElement);
      const ibanElement = elements.getElement(IbanElement);
      //const paymentElement = elements.getElement(PaymentElement);

      const billingDetails = {
        name: `${user?.addresses?.billing.firstname} ${user?.addresses?.billing.lastname}`,
        email: user.email,
        address: {
          line1: user?.addresses?.billing.address,
          postal_code: user?.addresses?.billing.zipcode,
          city: user?.addresses?.billing.city,
          state: '',
        },
      };

      let paymentMethod;
      if (method !== 'sofort') {
        const test = await stripe.createPaymentMethod({
          type: method,
          card: elements.getElement(CardElement),
          sepa_debit: elements.getElement(IbanElement),
          billing_details: billingDetails,
        });
        paymentMethod = test.paymentMethod;

        if (!paymentMethod) return;
      }

      console.log('PAYMENT METHOD', paymentMethod, 'PAYMENT INTENT', paymentIntent);

      setPaymentError(null);
      setPaymentProcessing(true);

      if (!paymentIntent) return;

      const purchasePayload = {
        email: user.email,
        userId: user.id,
        // TODO: generalize to cart products later:
        printId: product.printId,
        printTitle: encodeURIComponent(product.title),
        productId: productId,
        paymentMethodId: paymentMethod?.id,
        //paymentId: confirmedPayment.paymentIntent.id,
        amount: paymentIntent.amount / 100, // props.amount if coupon discount should be saved separately
        coupon: props.couponCode,
        shipping: shippingCosts,
        format: product.format,
        type: product.type,
        output: product.output,
        // for backend validation and order confirmation:
        cartContents: [{ ...props.cartContents[0], ...{ title: encodeURIComponent(props.cartContents[0].title) } }],
      };

      let confirmedPayment;

      if (method === 'sepa_debit') {
        console.log('PAY SEPA');
        confirmedPayment = await stripe.confirmSepaDebitPayment(paymentIntent.client_secret, {
          payment_method: paymentMethod.id,
          /*payment_method: {
              sepa_debit: iban,
              billing_details: {
                name: document.querySelector('input[name="name"]').value,
                email: document.querySelector('input[name="email"]').value
              }
            }*/
        });
      }

      const url = new URL(window.location);
      const returnUrl = `${url.protocol}//${url.host}${url.pathname}?payload=${JSON.stringify(purchasePayload)}`;

      if (method === 'sofort') {
        confirmedPayment = await stripe.confirmSofortPayment(paymentIntent.client_secret, {
          //payment_method: paymentMethod.id,
          payment_method: {
            sofort: {
              country: 'DE',
            },
          },
          return_url: returnUrl,
        });
      }

      if (method === 'card') {
        confirmedPayment = await stripe.confirmCardPayment(paymentIntent.client_secret, {
          payment_method: paymentMethod.id,
        });
      }

      console.log('PAYMENT CONFIRMATION', confirmedPayment);
      if (!confirmedPayment || confirmedPayment.error) {
        setPaymentProcessing(false);
        setPaymentError(confirmedPayment.error.message);
        return;
      }

      purchasePayload.paymentId = confirmedPayment.paymentIntent.id;

      console.log('PURCHASE PAYLOAD', purchasePayload);

      const purchase = await fetch(`${process.env.REACT_APP_API_URL}/payments/purchase-product`, {
        method: 'POST',
        body: JSON.stringify(purchasePayload),
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${localStorage.getItem('token')}`,
        },
      })
        .then((response) => response.json())
        .then((data) => {
          console.log('CHECKOUT MODAL PURCHASE DATA:', data);

          return data;
        })
        .then(onProductComplete)
        .then((result) => {
          if (result.error) {
            // The card had an error when trying to attach it to a customer.
            throw result;
          }
          return result;
        });

      if (confirmedPayment.paymentIntent.status === 'succeeded') {
        setPaymentSuccess(true);
      }
    };

    const handleStripeProductPurchase = async (attributes, cart) => {
      const productIds = cart.map((cartProduct) => cartProduct.id);

      //const productId = productIds[`${product.output}_${product.format}`];

      const price = props.amount;
      const shippingCosts = props.shippingCosts;

      const payload = {
        products: cart,
        //pages: attributes.pages,
        coupon: props.couponCode,
        //amount: price,
        //shipping: shippingCosts,
        //output: product.output,
        currency: 'eur',
        //method: method,
      };

      console.log('INTENT PAYLOAD', payload);

      const paymentIntent = await fetch(`${process.env.REACT_APP_API_URL}/payments/create-intent`, {
        method: 'POST',
        body: JSON.stringify(payload),
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${localStorage.getItem('token')}`,
        },
      })
        .then((response) => response.json())
        .catch((error) => {
          console.error('PAYMENT INTENT ERROR:', error);
        });

      const cardElement = elements.getElement(CardElement);
      const ibanElement = elements.getElement(IbanElement);
      //const paymentElement = elements.getElement(PaymentElement);

      const billingDetails = {
        name: `${user?.addresses?.billing.firstname} ${user?.addresses?.billing.lastname}`,
        email: user.email,
        address: {
          line1: user?.addresses?.billing.address,
          postal_code: user?.addresses?.billing.zipcode,
          city: user?.addresses?.billing.city,
          state: '',
        },
      };

      let paymentMethod;
      if (method !== 'sofort') {
        const test = await stripe.createPaymentMethod({
          type: method,
          card: elements.getElement(CardElement),
          sepa_debit: elements.getElement(IbanElement),
          billing_details: billingDetails,
        });
        paymentMethod = test.paymentMethod;

        if (!paymentMethod) return;
      }

      console.log('PAYMENT METHOD', paymentMethod, 'PAYMENT INTENT', paymentIntent);

      setPaymentError(null);
      setPaymentProcessing(true);

      if (!paymentIntent) return;

      const purchasePayload = {
        email: user.email,
        userId: user.id,
        //printId: product.printId,
        //printTitle: encodeURIComponent(product.title),
        products: cart,
        paymentMethodId: paymentMethod?.id,
        //paymentId: confirmedPayment.paymentIntent.id,
        amount: paymentIntent.amount / 100, // props.amount if coupon discount should be saved separately
        coupon: props.couponCode,
        shipping: shippingCosts,
        //format: product.format,
        //type: product.type,
        //output: product.output,
        // for backend validation and order confirmation:
        cartContents: props.cartContents,
      };

      console.log('purchase Payload:', purchasePayload);
      setPaymentProcessing(false);
      setPaymentSuccess(false);
      //return;

      let confirmedPayment;

      if (method === 'sepa_debit') {
        console.log('PAY SEPA');
        confirmedPayment = await stripe.confirmSepaDebitPayment(paymentIntent.client_secret, {
          payment_method: paymentMethod.id,
          /*payment_method: {
              sepa_debit: iban,
              billing_details: {
                name: document.querySelector('input[name="name"]').value,
                email: document.querySelector('input[name="email"]').value
              }
            }*/
        });
      }

      const url = new URL(window.location);
      const returnUrl = `${url.protocol}//${url.host}${url.pathname}?payload=${JSON.stringify(purchasePayload)}`;

      if (method === 'sofort') {
        confirmedPayment = await stripe.confirmSofortPayment(paymentIntent.client_secret, {
          //payment_method: paymentMethod.id,
          payment_method: {
            sofort: {
              country: 'DE',
            },
          },
          return_url: returnUrl,
        });
      }

      if (method === 'card') {
        confirmedPayment = await stripe.confirmCardPayment(paymentIntent.client_secret, {
          payment_method: paymentMethod.id,
        });
      }

      console.log('PAYMENT CONFIRMATION', confirmedPayment);
      if (!confirmedPayment || confirmedPayment.error) {
        setPaymentProcessing(false);
        setPaymentError(confirmedPayment.error.message);
        return;
      }

      purchasePayload.paymentId = confirmedPayment.paymentIntent.id;

      console.log('PURCHASE PAYLOAD', purchasePayload);

      const purchase = await fetch(`${process.env.REACT_APP_API_URL}/payments/purchase-product`, {
        method: 'POST',
        body: JSON.stringify(purchasePayload),
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${localStorage.getItem('token')}`,
        },
      })
        .then((response) => response.json())
        .then((data) => {
          console.log('CHECKOUT MODAL PURCHASE DATA:', data);

          return data;
        })
        .then(onProductComplete)
        .then((result) => {
          if (result.error) {
            // The card had an error when trying to attach it to a customer.
            throw result;
          }
          return result;
        });

      if (confirmedPayment.paymentIntent.status === 'succeeded') {
        setPaymentSuccess(true);
      }
    };

    const handlePayment = async (event) => {
      event.preventDefault();
      //console.log('HANDLE PAYMENT, PROPS:', props);
      if (!stripe || !elements) {
        return;
      }

      switch (props.product) {
        case 'subscription':
          handleStripeSubscriptionPurchase(props.params);
          break;
        case 'print':
          handleStripePrintPurchase(props.params, props.cartContents);
          break;
        default:
          handleStripeProductPurchase(props.params, props.cartContents);
          break;
      }
    };

    console.log('METHOD', method);

    return (
      <form onSubmit={handlePayment}>
        {method === 'sepa_debit' && <IbanElement className={classes.cardElement} options={ibanOptions} />}
        {method === 'sofort' && (
          <Typography variant="body2" gutterBottom>
            Mit dem Klicken auf Bestellen werden Sie zum Zahlungsdienstleister SofortÜberweisung weitergeleitet.
          </Typography>
        )}
        {method === 'card' && (
          <CardElement
            className={classes.cardElement}
            options={cardOptions}
            onReady={() => {
              console.log('CardElement [ready]');
            }}
            onChange={(event) => {
              console.log('CardElement [change]', event);
            }}
            onBlur={() => {
              console.log('CardElement [blur]');
            }}
            onFocus={() => {
              console.log('CardElement [focus]');
            }}
          />
        )}
        <Button variant="contained" color="primary" spacing={2} type="submit" disabled={!stripe}>
          Zahlungspflichtig bestellen
        </Button>
        &nbsp;
        <Button variant="outlined" color="primary" onClick={handleClose}>
          Abbrechen
        </Button>
      </form>
    );
  };

  const handleChangePaymentMethod = (event) => {
    setPaymentMethod(event.target.value);
  };

  const modalBody = (
    <div style={modalStyle} className={classes.paper}>
      <h2>Zahlung</h2>
      <div>
        <Box p={3}>
          <Grid item xs={12}>
            <Card>
              <CardContent>
                <Typography variant="h6" gutterBottom>
                  Rechnungsadresse:
                </Typography>
                <Typography variant="body2">
                  {addressData?.firstname || user?.addresses?.billing.firstname}{' '}
                  {addressData?.lastname || user?.addresses?.billing.lastname}
                </Typography>
                <Typography variant="body2">{addressData?.address || user?.addresses?.billing.address}</Typography>
                <Typography variant="body2">
                  {addressData?.zipcode || user?.addresses?.billing.zipcode}{' '}
                  {addressData?.city || user?.addresses?.billing.city}
                </Typography>
              </CardContent>
            </Card>
          </Grid>
          <Grid item xs={12}>
            <Box className={classes.stripeBox} hidden={paymentProcessing}>
              <Grid item xs={12}>
                <FormControl component="fieldset">
                  <FormLabel component="legend">Zahlungsweise</FormLabel>
                  <RadioGroup
                    row
                    aria-label="payment"
                    name="payment_method"
                    value={paymentMethod}
                    onChange={handleChangePaymentMethod}
                  >
                    {paymentMethods.includes('sepa') && (
                      <FormControlLabel
                        value={'sepa_debit'}
                        control={<Radio disabled={!props.cartContents} />}
                        label="SEPA"
                      />
                    )}
                    {paymentMethods.includes('sofort') && (
                      <FormControlLabel
                        value={'sofort'}
                        control={<Radio />}
                        disabled={!props.cartContents}
                        label="SofortÜberweisung"
                      />
                    )}
                    <FormControlLabel value={'card'} control={<Radio />} label="Kreditkarte" />
                  </RadioGroup>
                </FormControl>
              </Grid>

              <Typography variant="h6" gutterBottom>
                Zahlungsdaten:
              </Typography>
              <Elements stripe={stripePromise}>
                <CardForm method={paymentMethod} />
              </Elements>
            </Box>
            {paymentProcessing && (
              <Box p={6} style={{ textAlign: 'center' }}>
                <Typography variant="body2" gutterBottom>
                  Zahlung wird bearbeitet
                </Typography>
                <br />
                <CircularProgress />
              </Box>
            )}
            {paymentError && (
              <Box p={6} style={{ textAlign: 'center' }}>
                <Typography variant="body2" color="secondary" gutterBottom>
                  Fehler bei der Zahlungsabwicklung. Es wurde keine Zahlung abgebucht.
                </Typography>
                <Typography variant="caption">Error: {paymentError}</Typography>
                <br />
                {/*<Link to="/subscription">← zurück</Link>*/}
              </Box>
            )}
          </Grid>
        </Box>
      </div>
    </div>
  );

  return (
    <Container width={width} height={height}>
      <Modal
        open={modalOpen}
        onClose={handleClose}
        aria-labelledby="simple-modal-title"
        aria-describedby="simple-modal-description"
      >
        {modalBody}
      </Modal>
    </Container>
  );
};

export default StripeCheckoutModal; //withStyles(styles)(StripeCheckoutModal);
