import React, { useContext, useState } from 'react';
import { VariantContext } from '../../hooks/VariantContext';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { CssBaseline, Container, Box, Grid, Avatar, Typography, TextField, Button, Link } from '@material-ui/core';
import { withStyles, makeStyles } from '@material-ui/core';
import SuccessIcon from '@material-ui/icons/CheckCircleOutline';
import ErrorIcon from '@material-ui/icons/ErrorOutline';
import styles from './Login.styles';

const validationSchema = Yup.object({
  password: Yup.string().min(8).required('Enter a new password'),
  password_repeat: Yup.string()
    .required('Please retype your new password.')
    .oneOf([Yup.ref('password'), null], 'Your passwords do not match.'),
});

const Login = (props) => {
  const useStyles = makeStyles((theme) => styles(theme));
  const classes = useStyles();

  const [passwordUpdateSuccess, setPasswordUpdateSuccess] = useState(null);
  const { appVariant } = useContext(VariantContext);

  const url = new URL(window.location);
  const tokenParam = url.searchParams.get('token');

  const { handleSubmit, handleChange, values, touched, errors } = useFormik({
    initialValues: {
      password: '',
      password_repeat: '',
    },
    validationSchema,
    onSubmit: (values, { setStatus, setErrors, setFieldError }) => {
      const payload = {
        password: values.password,
      };

      fetch(`${process.env.REACT_APP_API_URL}/auth/reset-password?token=${tokenParam}`, {
        method: 'POST',
        body: JSON.stringify(payload),
        headers: { 'Content-Type': 'application/json' },
      }).then((res) => {
        console.log('RES', res, res.status);
        if (res.status === 204) {
          setPasswordUpdateSuccess(true);
        } else {
          console.log('ERROR', res);
          setPasswordUpdateSuccess(false);
          /*res.json().then((error) => {
            if (error.code === 401) {
              setFieldError('password', error.message);
            } else {
              setFieldError('api', error.message);
            }
          });*/
        }
      });
    },
  });

  if (passwordUpdateSuccess !== null) {
    return (
      <Grid
        container
        spacing={0}
        direction="column"
        alignItems="center"
        align="center"
        justifyContent="center"
        style={{ minHeight: '80vh' }}
      >
        <Grid item xs={6}>
          <Box>
            {passwordUpdateSuccess === true && <SuccessIcon style={{ fontSize: '60px', color: '#9ccc65' }} />}
            {passwordUpdateSuccess === false && <ErrorIcon style={{ fontSize: '60px', color: '#ff6961' }} />}
            <br />
            <br />
            <Typography variant="h5">
              {passwordUpdateSuccess === true && <b>Das neue Passwort wurde erfolgreich gespeichert.</b>}
              {passwordUpdateSuccess === false && (
                <b>Der Link zum Zurücksetzen des Passworts ist ungültig. Bitte erneut anfordern.</b>
              )}
            </Typography>
          </Box>
        </Grid>
      </Grid>
    );
  }

  return (
    <Container component="main" maxWidth="xs" style={{ minHeight: '75vh' }}>
      <CssBaseline />
      <div className={classes.paper}>
        {appVariant === 'bloomup' && <Avatar src="/images/login-symbol.png"></Avatar>}
        {appVariant === 'ljs' && <Avatar src="/images/login-symbol-ljs.png"></Avatar>}
        <Typography component="h1" variant="h5">
          Password zurücksetzen
        </Typography>
        <form onSubmit={handleSubmit} className={classes.form} noValidate>
          <TextField
            variant="outlined"
            margin="normal"
            required
            fullWidth
            name="password"
            label="Password"
            type="password"
            id="password"
            autoComplete="new-password"
            value={values.password}
            onChange={handleChange}
            error={touched.password && Boolean(errors.password)}
            helperText={touched.password && errors.password}
          />
          <TextField
            variant="outlined"
            margin="normal"
            required
            fullWidth
            name="password_repeat"
            label="Password wiederholen"
            type="password"
            id="password_repeat"
            autoComplete="new-password-repeat"
            value={values.password_repeat}
            onChange={handleChange}
            error={touched.password_repeat && Boolean(errors.password_repeat)}
            helperText={touched.password_repeat && errors.password_repeat}
          />
          <Button type="submit" fullWidth variant="contained" color="primary" className={classes.submit}>
            Neues Passwort festlegen
          </Button>
          <Grid container>
            <Grid item xs>
              <Link href="/login" variant="body2">
                zurück zum Login
              </Link>
            </Grid>
          </Grid>
        </form>
      </div>
    </Container>
  );
};

export default withStyles(styles, { withTheme: true })(Login);
