import React, { Fragment, useEffect, useState } from 'react';
import { /*Redirect,*/ useHistory } from 'react-router-dom';
import { isMobile } from 'react-device-detect';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { v1 as uuid } from 'uuid';
import { CssBaseline, Grid, Container, Avatar, Typography, TextField, Button, Link, Box } from '@material-ui/core';
import { Alert } from '@material-ui/lab';
import { makeStyles } from '@material-ui/core/styles';
import styles from './Login.styles';

//import styles from './Login.styles';

const SignUp = () => {
  const useStyles = makeStyles((theme) => styles(theme));
  const classes = useStyles();
  const history = useHistory();

  const [verificationStatus, setVerificationStatus] = useState(null);
  const [showAppcodeInput, setShowAppcodeInput] = useState(false);
  const [forwardedToAppstore, setForwardedToAppstore] = useState(false);

  //const { token } = useParams(); // Does not work (?)
  //const currentUrl = new URL(window.location).searchParams;
  const queryParams = new URLSearchParams(window.location.search);
  const token = queryParams.get('token');
  const referrer = queryParams.get('referrer');
  const friendEventId = queryParams.get('friendEventId');

  const verifyEmail = (token) => {
    const payload = {
      token: token,
    };

    fetch(`${process.env.REACT_APP_API_URL}/auth/verify-email?token=${token}`, {
      method: 'POST',
      body: JSON.stringify(payload),
      headers: { 'Content-Type': 'application/json' },
    }).then((res) => {
      if (res.status === 404) {
        setVerificationStatus('already_verified');
      } else if (res.ok === true) {
        setVerificationStatus('success');
      } else {
        setVerificationStatus('error');
      }
    });
  };

  useEffect(() => {
    if (token) verifyEmail(token);
  }, [token]);

  const validationSchema = Yup.object({
    name: Yup.string('Bitte gib Deinen Namen ein').required('Name ist ein Pflichtfeld'),
    //email: Yup.string('Bitte gib deine E-Mail Adresse an.').required('E-Mail Adresse ist ein Pflichtfeld'),
    //firstname: Yup.string('Bitte gib deinen Vornamen an.').required('Vorname ist ein Pflichtfeld'),
    //lastname: Yup.string('Bitte gib deinen Nachnamen an.').required('Nachname ist ein Pflichtfeld'),
    appcode: Yup.string('Bitte gib deinen App-Code ein.')
      //.min(8, 'Der App-Code muss aus mindestens 8 Zeichen bestehen')
      .nullable(),
  });

  const { handleSubmit, handleChange, values, touched, errors } = useFormik({
    initialValues: {
      name: '',
      appcode: referrer,
      //firstname: '',
      //lastname: '',
    },
    validationSchema: validationSchema,
    onSubmit: (values, { setStatus, setErrors, setFieldError }) => {
      const payload = {
        //uuid: uuid.v4(),
        appcode: values.appcode,
        friendEventId: friendEventId,
        //name: `${values.firstname} ${values.lastname}`,
        name: values.name,
      };

      fetch(`${process.env.REACT_APP_API_URL}/auth/register-web`, {
        method: 'POST',
        body: JSON.stringify(payload),
        headers: { 'Content-Type': 'application/json' },
      }).then((res) => {
        if (res.status === 201) {
          res.json().then((data) => {
            const params = {};
            params.otp = data.tokens.otp.token;
            if (payload.appcode) params.referrer = payload.appcode;

            const appRedirectUrl = `${process.env.REACT_APP_FIREBASE_APP_URL}`;

            if (friendEventId) params.friendEventId = friendEventId;

            const referrerParams = encodeURIComponent(JSON.stringify(params));
            const urlParams = encodeURIComponent(`https://app.getbloomup.com/login?referrer=${referrerParams}`);
            const linkParam = `&link=${urlParams}`;

            setForwardedToAppstore(true);

            window.location.href = `${appRedirectUrl}${linkParam}`;
          });
        } else {
          res.json().then((error) => {
            if (error.code === 400) {
              setFieldError('appcode', error.message);
            } else {
              setFieldError('api', `Beim Registrieren ist ein Fehler aufgetreten: ${error.message}`);
            }
          });
        }
      });
    },
  });

  const handleShowAppcodeInput = () => {
    setShowAppcodeInput(!showAppcodeInput);
  };

  if (verificationStatus !== null) {
    return (
      <Grid
        container
        spacing={0}
        direction="column"
        alignItems="center"
        justifyContent="center"
        style={{ minHeight: '80vh' }}
      >
        <Grid item xs={6} style={{ maxWidth: '90vw' }}>
          <Typography variant="h5" gutterBottom style={{ padding: '10px' }}>
            {verificationStatus === 'success' && 'Erfolgreich'}
            {verificationStatus === 'error' && 'Fehler'}
          </Typography>
        </Grid>
        <Grid item xs={6} style={{ maxWidth: '90vw' }}>
          <Typography variant="body1">
            {verificationStatus === 'success' && 'Deine E-Mail Adresse wurde validiert.'}
            {verificationStatus === 'already_verified' &&
              'Der Verifizierungslink ist veraltet oder Deine E-Mail Adresse wurde bereits validiert.'}
            {verificationStatus === 'error' && 'Beim Validieren deiner E-Mail Adresse ist ein Fehler aufgetreten.'}
          </Typography>
        </Grid>
      </Grid>
    );
  }

  //return <Redirect to={'/login'} />;

  if (!isMobile) {
    return (
      <Container component="main" maxWidth="xs" style={{ minHeight: '75vh' }} align="center">
        <div className={classes.paper}>
          <Box mb={3}>
            <a href="/">
              <img src="/images/bloomUp-my-life-logo-primary.png" alt="bloomUp my life logo" />
            </a>
          </Box>

          <Typography component="h1" variant="h5" gutterBottom>
            <span className={classes.line}>Herzlich Willkommen bei</span>&nbsp;
            <span className={classes.line}>
              <b>bloomUp my life®</b>,
            </span>
            &nbsp;
            <span className={classes.line}>lieber interessierter Bloomer.</span>&nbsp;
            <br />
            <br />
            <span className={classes.line}>
              Dieser Link und unsere App ist NUR für Mobile-Geräte nutzbar. Bitte öffne diesen Link mit Deinem mobilen Gerät
              (Handy). Wir wünschen Dir viel Freude beim bloomen.
            </span>
          </Typography>
        </div>
      </Container>
    );
  }

  if (forwardedToAppstore) {
    return (
      <Container component="main" maxWidth="xs" style={{ minHeight: '75vh' }} align="center">
        <div className={classes.paper}>
          <Box mb={3}>
            <a href="/">
              <img src="/images/bloomUp-my-life-logo-primary.png" alt="bloomUp my life logo" />
            </a>
          </Box>

          <Typography component="h1" variant="h5" gutterBottom>
            <span className={classes.line}>Du wirst zum Appstore weitergeleitet. Dort kannst du die App installieren.</span>
          </Typography>
        </div>
      </Container>
    );
  }

  return (
    <Container component="main" maxWidth="xs" style={{ minHeight: '75vh' }} align="center">
      <div className={classes.paper}>
        <Box mb={3}>
          <img src="/images/bloomUp-my-life-logo-primary.png" alt="bloomUp my life logo" />
        </Box>

        <Typography component="h1" variant="h5" gutterBottom>
          <b>
            <span className={classes.line}>Willkommen bei</span> <span className={classes.line}>bloomUp my life&#174;!</span>
          </b>
        </Typography>

        <Typography variant="body1">
          <span className={classes.line}>Mit welchem Namen möchtest</span>{' '}
          <span className={classes.line}>du begrüßt werden?</span>
        </Typography>

        <form onSubmit={handleSubmit} className={classes.form} noValidate>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <TextField
                autoComplete="name"
                name="name"
                variant="outlined"
                required
                fullWidth
                id="name"
                label="Mein Name"
                autoFocus
                value={values.name}
                onChange={handleChange}
                error={touched.name && !!errors.name}
                helperText={touched.name && errors.name}
              />
            </Grid>

            {/*<Grid item xs={12} sm={6}>
              <TextField
                autoComplete="fname"
                name="firstname"
                variant="outlined"
                required
                fullWidth
                id="firstname"
                label="First name"
                autoFocus
                value={values.firstname}
                onChange={handleChange}
                error={touched.firstname && !!errors.firstname}
                helperText={touched.firstname && errors.firstname}
              />
            </Grid>*/}
            {/*<Grid item xs={12} sm={6}>
              <TextField
                name="lastname"
                variant="outlined"
                required
                fullWidth
                id="lastname"
                label="Last name"
                autoComplete="lname"
                value={values.lastname}
                onChange={handleChange}
                error={touched.lastname && !!errors.lastname}
                helperText={touched.lastname && errors.lastname}
              />
            </Grid>*/}

            <Grid item xs={12}>
              <Link href="javascript:void(0);" variant="body2" onClick={handleShowAppcodeInput} hidden={showAppcodeInput}>
                <Typography variant="overline" color="primary" style={{ fontSize: '1rem' }}>
                  Ich habe einen App-Code
                </Typography>
              </Link>

              <Box hidden={!showAppcodeInput}>
                <Typography align="left" style={{ marginBottom: '10px' }}>
                  <b>Mein App-Code</b> (wenn vorhanden)
                </Typography>
                <TextField
                  autoComplete="appcode"
                  name="appcode"
                  variant="outlined"
                  fullWidth
                  id="appcode"
                  label="App-Code"
                  autoFocus
                  value={values.appcode}
                  onChange={handleChange}
                  error={touched.appcode && !!errors.appcode}
                  helperText={touched.appcode && errors.appcode}
                />
              </Box>
            </Grid>
          </Grid>
          {!!errors.api && (
            <Grid item xs={12}>
              <Alert severity="error">{errors.api}</Alert>
            </Grid>
          )}
          <Button type="submit" fullWidth variant="contained" color="primary" className={classes.submit}>
            WEITER
          </Button>
        </form>
      </div>
    </Container>
  );
};

export default SignUp;
