import { createTheme } from '@material-ui/core/styles';

export const theme = createTheme({
  typography: {
    button: {
      textTransform: 'none',
    },
  },
  palette: {
    background: {
      default: 'white',
    },
    primary: {
      light: '#981869',
      main: '#74019b',
      dark: '#9353A1',
      contrastText: '#fff',
    },
    text: {
      primary: 'rgba(50, 50, 50, 1)',
      secondary: 'rgba(100, 100, 120, 1)',
    },
  },
});

export default theme;
