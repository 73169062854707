import React, { useContext } from 'react';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import { VariantContext } from '../../hooks/VariantContext';

const Legal = () => {
  const { appVariant } = useContext(VariantContext);

  return (
    <div>
      <Grid
        container
        spacing={0}
        direction="column"
        alignItems="center"
        justifyContent="center"
        style={{ minHeight: '80vh' }}
      >
        <Grid item xs={6} style={{ maxWidth: '60vw' }}>
          <Typography variant="body1" gutterBottom style={{ padding: '10px' }}>
            <p>
              <b>
                {appVariant === 'bloomup'
                  ? 'bloomUp my life ist eine eingetragene Marke der LJS Media Group GmbH'
                  : 'LJS Media Group GmbH'}
              </b>
            </p>
            <p>Geschäftsführer: Stephan Retzlaff, Johann Garnitschnig</p>
            <br />
            <p>Service-Hotline: 0351 – 850 97 634</p>
            <p>Fax: 0351 - 850 97 632</p>
            <p>
              E-Mail: <a href="mailto:info@getbloomup.com">info@getbloomup.com</a>
            </p>
            <br />
            <p>LJS Media Group GmbH</p>
            <p>Am Trachauer Bahnhof 6b</p>
            <p>01139 Dresden</p>
            <br />
            <p>USt-ID: DE262 992 559</p>
            <p>Amtsgericht Dresden HRB 32577</p>
          </Typography>
        </Grid>
      </Grid>
    </div>
  );
};

export default Legal;
