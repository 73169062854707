import React from 'react';
import { Link } from 'react-router-dom';
import { withStyles } from '@material-ui/core/styles';
import { Grid, Box, Chip, Typography } from '@material-ui/core';

const styles = (theme) => ({
  root: {
    width: '100%',
    marginTop: '50px',
    marginBottom: '50px',
  },
  subscriptionBox: {
    minHeight: '142px',
    padding: '1rem',
    border: '1px solid #747474',
    borderRadius: '4px',
    color: 'black',
    cursor: 'pointer',

    '&:hover $infoChip': {
      backgroundColor: '#a458bd',
    },
    '&:hover': {
      backgroundColor: theme.palette.primary.main,
      color: 'white',
      '& $infoChip': {
        backgroundColor: '#a458bd',
      },
    },
  },
  infoChip: {},
});

const SubscriptionBox = (props) => {
  const { classes, content } = props;

  const Product = (props) => {
    const product = props.data;
    const { id, name, price, attributes } = product;

    const subheadline = attributes.find((attribute) => attribute.name === 'subheadline')?.options[0];
    const description = attributes.find((attribute) => attribute.name === 'description')?.options[0];
    const interval = attributes.find((attribute) => attribute.name === 'payment_interval')?.options[0];
    //const model = attributes.find((attribute) => attribute.name === 'model')?.options[0];

    /*const purchasePrice = interval === 'yearly' ? price * 12 : price;
    const intervalLabels = {
      monthly: ' / Monat',
      yearly: ' / Jahr',
    };

    const purchasePriceLabel = new Intl.NumberFormat('de-DE', { style: 'currency', currency: 'EUR' }).format(purchasePrice);*/

    const monthlyPrice = new Intl.NumberFormat('de-DE', { style: 'currency', currency: 'EUR' }).format(price);

    return (
      <Grid item lg={6} xs={12}>
        <Link to={`subscription?product=${id}`} style={{ textDecoration: 'none' }}>
          <Box className={classes.subscriptionBox}>
            <Typography variant="h6" gutterBottom>
              <small>
                <b>{name}</b>
              </small>
            </Typography>
            <Grid container spacing={2}>
              <Grid item xs={5}>
                <Typography variant="body2" gutterBottom>
                  <b>{monthlyPrice} / Monat</b>
                </Typography>
              </Grid>
              <Grid item xs={7}>
                <Chip label={subheadline} size="small" color="default" className={classes.infoChip} />
              </Grid>
            </Grid>
            <Typography variant="body2" gutterBottom>
              {description}
            </Typography>
          </Box>
        </Link>
      </Grid>
    );
  };

  return (
    <Grid container spacing={2}>
      {content && content.map((itemData) => <Product key={itemData.id} data={itemData} />)}
    </Grid>
  );
};

export default withStyles(styles)(SubscriptionBox);
