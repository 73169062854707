import React, { useContext } from 'react';
import { VariantContext } from '../hooks/VariantContext';
import { useHistory } from 'react-router-dom';
import { withStyles } from '@material-ui/core/styles';
import { Container, Box } from '@material-ui/core';
import BottomNavigation from '@material-ui/core/BottomNavigation';
import BottomNavigationAction from '@material-ui/core/BottomNavigationAction';

const styles = (theme) => ({
  root: {
    width: '100%',
  },
  footer: {
    backgroundColor: '#242424',
  },
  footerLogo: {
    float: 'left',
    position: 'relative',
    top: '14px',
  },
  navigation: {
    height: '100px',
    justifyContent: 'start',
    color: 'white',
    backgroundColor: 'transparent',
  },
  link: {
    color: 'white',
    textDecoration: 'underline',
  },
});

const AppFooter = (props) => {
  const { classes } = props;
  const history = useHistory();

  const { appVariant } = useContext(VariantContext);

  return (
    <div className={classes.root}>
      <Container maxWidth="lg" className={classes.footer}>
        <Box p={2} className={classes.footerLogo}>
          {appVariant === 'bloomup' && (
            <img src="/images/bloomUp-my-life-icon-only-white@2x.png" alt="bloomUp my life Logo" />
          )}
          {appVariant === 'ljs' && <img src="/images/login-symbol-ljs.png" alt="Ljs Logo" />}
        </Box>
        <BottomNavigation
          onChange={(event, newValue) => {
            history.push(`${newValue}`);
            //history.push('/mybloomup');
          }}
          showLabels
          className={classes.navigation}
        >
          <BottomNavigationAction label="Impressum" value="legal" className={classes.link} />
          <BottomNavigationAction label="Datenschutzerklärung" value="privacy" className={classes.link} />
        </BottomNavigation>
      </Container>
    </div>
  );
};

export default withStyles(styles)(AppFooter);
