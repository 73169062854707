import React, { useEffect, useContext, useState } from 'react';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import {
  Container,
  Grid,
  Box,
  Typography,
  Button,
  TextField,
  Select,
  Checkbox,
  MenuItem,
  InputLabel,
  FormControl,
  FormControlLabel,
  FormGroup,
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import SuccessIcon from '@material-ui/icons/CheckCircleOutline';
import StripeCheckoutModal from '../StripeCheckoutModal';
import SubscriptionBox from '../SubscriptionBox';
import { UserContext } from '../../hooks/UserContext';

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
    marginTop: '50px',
    marginBottom: '50px',
  },
}));

const Subscription = (props) => {
  const classes = useStyles();

  const url = new URL(window.location);
  const tokenParam = url.searchParams.get('token');

  // set token when coming from app evaluation lock screen
  if (tokenParam && !localStorage.getItem('token')) {
    localStorage.setItem('token', tokenParam);
    url.searchParams.delete('token');
    window.location.href = url;
  }

  const authToken = localStorage.getItem('token');

  const { user } = useContext(UserContext);
  const userId = user?.id;

  //const [hasReferredSomeone, setHasReferredSomeone] = useState(null);
  const [addressIsFilled, setAddressIsFilled] = useState(false);
  const [paymentIsComplete, setPaymentIsComplete] = useState(null);

  const [showSubscriptionOverview, setShowSubscriptionOverview] = useState(false);

  const [subscriptionCancelled, setSubscriptionCancelled] = useState(null);

  const [productsLoading, setProductsLoading] = useState(false);
  const [subscriptionProducts, setSubscriptionProducts] = useState(null);

  const productParam = url.searchParams.get('product');

  useEffect(
    // toggles overview on and off and queries referred users for the overview
    (props) => {
      if (!user || productsLoading) return;
      setProductsLoading(true);

      fetch(`${process.env.REACT_APP_API_URL}/products/for-user/${userId}`, {
        method: 'GET',
        headers: { 'Content-Type': 'application/json' },
      })
        .then((res) => {
          if (res.status === 200) {
            res.json().then((data) => {
              setSubscriptionProducts(data.results);
              setProductsLoading(false);
            });
          }
        })
        .catch((e) => {
          console.error(e);
        });

      if (!productParam) {
        setShowSubscriptionOverview(true);
      } else {
        setShowSubscriptionOverview(false);
      }
    },
    [user, productParam]
  );

  const validationSchema = Yup.object({
    firstname: Yup.string('Enter your first name').required('Firstname is required'),
    lastname: Yup.string('Enter your last name').required('Lastname is required'),
    address: Yup.string('Enter your address').required('Address is required'),
    zipcode: Yup.string('Enter your zip code').required('Zip code is required'),
    city: Yup.string('Enter your city').required('City is required'),
    country: Yup.string('Enter your country').required('Country is required'),
  });

  const selectedProduct = subscriptionProducts?.find((product) => product.id === +productParam);

  const selectedPaymentInterval = selectedProduct?.attributes.find((attribute) => attribute.name === 'payment_interval')
    ?.options[0];

  const subscriptionType = selectedProduct?.attributes.find((attribute) => attribute.name === 'model')?.options[0];

  const selectedPaymentIntervalLabel = { monthly: 'monatlich', yearly: 'jährlich' }[selectedPaymentInterval];

  const selectedPrice = +(selectedPaymentInterval === 'monthly' ? selectedProduct?.price : selectedProduct?.price * 12);
  const selectedPriceSum = selectedPrice;

  const selectedPriceLabel = new Intl.NumberFormat('de-DE', { style: 'currency', currency: 'EUR' }).format(selectedPrice);
  const selectedPriceSumLabel = new Intl.NumberFormat('de-DE', { style: 'currency', currency: 'EUR' }).format(
    selectedPriceSum
  );

  const productId = productParam;

  const { handleSubmit, handleChange, /*setFieldValue,*/ values, touched, errors } = useFormik({
    initialValues: {
      coupon_code: '',
      firstname: user?.addresses?.billing?.firstname,
      lastname: user?.addresses?.billing?.lastname,
      address: user?.addresses?.billing?.address,
      zipcode: user?.addresses?.billing?.zipcode,
      city: user?.addresses?.billing?.city,
      country: user?.addresses?.billing?.country || 'de',
      has_credit_card_number: 'new',
      creditcard_number: '',
      creditcard_cvc: '',
      interval: selectedPaymentInterval,
      referredBy: user?.referredBy,
      cancel_subscription_agree_1: false,
      cancel_subscription_agree_2: false,
      cancel_subscription_agree_3: false,
      cancel_subscription_agree_4: false,
    },
    enableReinitialize: true,
    validationSchema,
    onSubmit: (values, { setStatus, setErrors, setFieldError }) => {
      console.log('SAVE BILLING ADDRESS', values);
      const payload = {
        addresses: {
          billing: {
            firstname: values.firstname,
            lastname: values.lastname,
            address: values.address,
            zipcode: values.zipcode,
            city: values.city,
            country: values.country,
          },
        },
      };

      fetch(`${process.env.REACT_APP_API_URL}/users/${userId}`, {
        method: 'PATCH',
        body: JSON.stringify(payload),
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${authToken}`,
        },
      }).then((res) => {
        if (res.status === 200) {
          res.json().then((data) => {
            console.log('ORDER RESPONSE DATA', data);
            //showSavedMessage();
            setAddressIsFilled(true);
            console.log('SET IS FILLED', addressIsFilled);
          });
        } else {
          res.json().then((error) => {
            setAddressIsFilled(false);
            setFieldError('api', error.message);
          });
        }
      });
    },
  });

  const handleCancelSubscription = () => {
    const payload = {
      userId: user.id,
    };

    fetch(`${process.env.REACT_APP_API_URL}/payments/cancel-subscription`, {
      method: 'POST',
      body: JSON.stringify(payload),
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${authToken}`,
      },
    }).then((res) => {
      if (res.status === 204) {
        console.log('CANCEL RESPONSE', res);
        setSubscriptionCancelled(true);
      } else {
        console.log('CANCEL ERROR:', res.error);
        setSubscriptionCancelled(false);
      }
    });
  };

  if (subscriptionCancelled !== null) {
    const SuccessScreen = () => (
      <Box>
        <SuccessIcon style={{ fontSize: '60px', color: '#9ccc65' }} />
        <br />
        <br />
        <Typography variant="h5">
          <b>Abo erfolgreich pausiert</b>
        </Typography>
      </Box>
    );

    return (
      <Grid
        container
        spacing={0}
        direction="column"
        alignItems="center"
        align="center"
        justifyContent="center"
        style={{ minHeight: '80vh' }}
      >
        <Grid item xs={6} style={{ maxWidth: '70vw' }}>
          {subscriptionCancelled === true && <SuccessScreen />}
          {subscriptionCancelled === false && (
            <>
              <Typography variant="h5" gutterBottom style={{ padding: '10px' }}>
                Fehler
              </Typography>
              <Typography variant="body1"> Beim pausieren des Abos ist ein Fehler aufgetreten.</Typography>
            </>
          )}
        </Grid>
      </Grid>
    );
  }

  if (user?.subscribed && showSubscriptionOverview) {
    return (
      <div className={classes.root}>
        <Container maxWidth="lg">
          <Typography variant="h4" gutterBottom>
            <b>Ich bin bereits Bloomer und will den Tarif wechseln. Welches ist das passende Abo für mich?</b>
          </Typography>
          <Typography variant="body1" gutterBottom>
            Ich möchte auf die jährliche Zahlung oder den Family-Tarif wechseln, um mit bis zu 4 weiteren Personen aus meiner
            Familie mit bloomUp gemeinsam zu bloomen. Der Tarifwechsel findet mit neuerlichem Beginn der Laufzeit zum Tag der
            Wechselbestätigung statt. Ich erhalte eine Gutschrift über den bereits gezahlten Betrag meines aktuellen
            Bestandstarifes zum Stichtag der Wechselbestätigung abzgl. einer einmaligen Wechselgebühr i.H.v. 0,50 €. Dies
            gilt nicht bei einem Wechsel aus einem Familientarif.
          </Typography>

          <br />

          <Grid container spacing={2}>
            <Grid item xs={5}>
              <Typography variant="h6" gutterBottom>
                <b>Ich habe einen Gutschein*</b>
              </Typography>
              <Typography variant="body1" gutterBottom>
                Die Gutschein-Zeit wird an die jeweilige Abo-LZ angehangen.
              </Typography>
            </Grid>
            <Grid item xs={2}>
              <TextField
                variant="outlined"
                id="coupon_code"
                name="coupon_code"
                autoComplete="coupon_code"
                value={values.coupon_code}
                onChange={handleChange}
                error={touched.coupon_code && Boolean(errors.coupon_code)}
                helperText={touched.coupon_code && errors.coupon_code}
              />
            </Grid>
          </Grid>

          <br />
          <br />

          <SubscriptionBox content={subscriptionProducts} showOnlyChangeOptionsForUser={user} />

          <br />
          <br />

          <Typography variant="caption">
            *Mein Abo erneuert sich automatisch um den jeweiligen Zeitraum der gewählten Zahlungsweise. Jederzeit
            stornierbar. Das Abo wird innerhalb von 24 Stunden vor Ende des aktuellen Plans automatisch verlängert.
          </Typography>

          <br />
          <br />
        </Container>

        {!user?.familyMemberOf && (
          <Container maxWidth="lg">
            <Typography variant="body1" gutterBottom>
              <b>Abo pausieren / kündigen</b>
            </Typography>

            <FormGroup>
              <FormControlLabel
                control={
                  <Checkbox
                    name="cancel_subscription_agree_1"
                    checked={values.cancel_subscription_agree_1}
                    onChange={handleChange}
                  />
                }
                label="Mir ist bekannt, das die Abo Kündigung zu einer Pause/ Inaktivität meiner bloomUp-App zum nächsten regulären Zahlungslauf führt und ab diesem Zeitpunkt ein Öffnen der bloomUp-
              App auf meinem Device nicht mehr möglich ist . Daher empfiehlt bloomUp mir, meine Einträge per bloomUp-Print vor der Abo-Kündigung zu sichern. Der Zeitpunkt wird mir per Mail mit
              der Pausierungs-/Kündigungsbestätigung mitgeteilt."
              />
            </FormGroup>
            <br />
            <FormGroup>
              <FormControlLabel
                control={
                  <Checkbox
                    name="cancel_subscription_agree_2"
                    checked={values.cancel_subscription_agree_2}
                    onChange={handleChange}
                  />
                }
                label="Mir ist bekannt, das die Daten und Einträge innerhalb der bloomUp-App solange vorhanden sind, wir die App auf meinem Device installiert ist. Mit Der Kündigung bleibt
              der Web-Account bestehen, so ich jederzeit über eine Verlängerung meines Abo`s die Pause jederzeit beenden und somit meine bloomUp-App wieder zum vollen Zugriff
              freischalten kann."
              />
            </FormGroup>
            <br />
            <FormGroup>
              <FormControlLabel
                control={
                  <Checkbox
                    name="cancel_subscription_agree_3"
                    checked={values.cancel_subscription_agree_3}
                    onChange={handleChange}
                  />
                }
                label="Mir ist bekannt, das eine Deinstallation der bloomUp- App von meinem Device die vollständige Entfernung ALLER meiner Daten und Einträge einschließt und eine Wiederherstellung
              meiner Daten und Einträge nach der Deinstallation nicht mehr möglich ist."
              />
            </FormGroup>
            <br />
            <FormGroup>
              <FormControlLabel
                control={
                  <Checkbox
                    name="cancel_subscription_agree_4"
                    checked={values.cancel_subscription_agree_4}
                    onChange={handleChange}
                  />
                }
                label="Mir ist bekannt, das ich für eine vollständige Löschung meines Accounts aus dem bloomUp-System ich über support@getbloomup.com Kontakt mit der LJS Media GmbH aufnehmen kann
              und diesen Löschungswunsch explizit anzeigen muss."
              />
            </FormGroup>
            <br />
            <Button
              disabled={
                !values.cancel_subscription_agree_1 ||
                !values.cancel_subscription_agree_2 ||
                !values.cancel_subscription_agree_3 ||
                !values.cancel_subscription_agree_4
              }
              onClick={() => handleCancelSubscription()}
              name="cancel_subscription"
              value={true}
              size="medium"
              variant="contained"
              color="primary"
            >
              Abo pausieren / kündigen
            </Button>
          </Container>
        )}
      </div>
    );
  }

  if (user && showSubscriptionOverview) {
    return (
      <div className={classes.root}>
        <Container maxWidth="lg">
          <Typography variant="h4" gutterBottom>
            <b>Ich habe bloomUp kennen gelernt und will Bloomer bleiben! Welches ist das passende Abo für mich?</b>
          </Typography>
          <Typography variant="body1" gutterBottom>
            Ich möchte maximale Flexibilität, dann wähle ich das Monats-Abo mit der monatlichen Zahlweise. Für eine optimale
            Entwicklung meines Lebens durch langfristige Begleitung und Unterstützung wähle ich die günstigere, jährliche
            Zahlweise für mein bloomUp. Die Order meiner Wahl tätige ich im Login-Bereich.
          </Typography>

          <br />

          <Grid container spacing={2}>
            <Grid item xs={5}>
              <Typography variant="h6" gutterBottom>
                <b>Ich habe einen Gutschein*</b>
              </Typography>
              <Typography variant="body1" gutterBottom>
                Die Gutschein-Zeit wird an die jeweilige Abo-LZ angehangen.
              </Typography>
            </Grid>
            <Grid item xs={2}>
              <TextField
                variant="outlined"
                id="coupon_code"
                name="coupon_code"
                autoComplete="coupon_code"
                value={values.coupon_code}
                onChange={handleChange}
                error={touched.coupon_code && Boolean(errors.coupon_code)}
                helperText={touched.coupon_code && errors.coupon_code}
              />
            </Grid>
          </Grid>

          <br />
          <br />

          <SubscriptionBox content={subscriptionProducts} />

          <br />
          <br />

          <Typography variant="caption">
            *Mein Abo erneuert sich automatisch um den jeweiligen Zeitraum der gewählten Zahlungsweise. Jederzeit
            stornierbar. Das Abo wird innerhalb von 24 Stunden vor Ende des aktuellen Plans automatisch verlängert.
          </Typography>
        </Container>
      </div>
    );
  }

  if (!user) {
    return <div className={classes.root} />;
  }

  if (paymentIsComplete !== null) {
    const SuccessScreen = () => (
      <Box>
        <SuccessIcon style={{ fontSize: '60px', color: '#9ccc65' }} />
        <br />
        <br />
        <Typography variant="h5">
          <b>App erfolgreich freigeschaltet!</b>
        </Typography>
        <br />
        <Typography variant="h6">Du kannst nun fortfahren und Deine App wie gewohnt nutzen.</Typography>
        <br />
        <Typography variant="h6">Vielen Dank, dass Du bloomUp my life benutzt.</Typography>
        <br />
        <br />
        <Button
          href="bloomup://awaitunlock"
          fullWidth
          size="large"
          variant="contained"
          style={{ backgroundColor: '#9ccc65', color: 'white' }}
        >
          <nobr>APP STARTEN</nobr>
        </Button>
      </Box>
    );

    return (
      <Grid
        container
        spacing={0}
        direction="column"
        alignItems="center"
        align="center"
        justifyContent="center"
        style={{ minHeight: '80vh' }}
      >
        <Grid item xs={6}>
          <Typography variant="body1">{!paymentIsComplete && user?.subscribed === true && <SuccessScreen />}</Typography>
        </Grid>
        <Grid item xs={6} style={{ maxWidth: '70vw' }}>
          <Typography variant="h5" gutterBottom style={{ padding: '10px' }}>
            {paymentIsComplete === true && <SuccessScreen />}
            {paymentIsComplete === false && 'Fehler'}
          </Typography>
        </Grid>
        <Grid item xs={6} style={{ maxWidth: '70vw' }}>
          <Typography variant="body1">
            {paymentIsComplete === true && 'Du erhältst in Kürze eine Bestellbestätigung.'}
            {paymentIsComplete === false && 'Bei der Zahlung ist ein Fehler aufgetreten.'}
          </Typography>
        </Grid>
      </Grid>
    );
  }

  return (
    <div className={classes.root}>
      <Container maxWidth="lg">
        <form onSubmit={handleSubmit} className={classes.form} noValidate>
          <Grid container justifyContent="center" style={{ minHeight: '70vh' }}>
            <Grid item xs={6}>
              <Typography variant="h3">
                {'Gleich bist Du'}
                <br />
                {'ein Bloomer!'}
              </Typography>
              <br />
              <Typography>
                {'Gleich hast Du es geschafft! Bitte trage noch Deine Daten ein und wähle eine Zahlungsmethode.'}
              </Typography>
              <br />
              <Typography>
                <b>{'Persönliche Daten:'}</b>
              </Typography>
              <Grid container spacing={2}>
                <Grid item xs={6}>
                  <TextField
                    variant="outlined"
                    margin="normal"
                    required
                    fullWidth
                    id="firstname"
                    label="Vorname"
                    name="firstname"
                    autoComplete="firstname"
                    autoFocus
                    value={values.firstname}
                    onChange={handleChange}
                    error={touched.firstname && Boolean(errors.firstname)}
                    helperText={touched.firstname && errors.firstname}
                  />
                </Grid>
                <Grid item xs={6}>
                  <TextField
                    variant="outlined"
                    margin="normal"
                    required
                    fullWidth
                    id="lastname"
                    label="Nachname"
                    name="lastname"
                    autoComplete="lastname"
                    autoFocus
                    value={values.lastname}
                    onChange={handleChange}
                    error={touched.lastname && Boolean(errors.lastname)}
                    helperText={touched.lastname && errors.lastname}
                  />
                </Grid>
                <Grid item xs={12}>
                  <TextField
                    variant="outlined"
                    margin="normal"
                    required
                    fullWidth
                    id="adress"
                    label="Adresse"
                    name="address"
                    autoComplete="address"
                    autoFocus
                    value={values.address}
                    onChange={handleChange}
                    error={touched.address && Boolean(errors.address)}
                    helperText={touched.address && errors.address}
                  />
                </Grid>

                <Grid item xs={6}>
                  <TextField
                    variant="outlined"
                    margin="normal"
                    required
                    fullWidth
                    id="zipcode"
                    label="Postleitzahl"
                    name="zipcode"
                    autoComplete="zipcode"
                    autoFocus
                    value={values.zipcode}
                    onChange={handleChange}
                    error={touched.zipcode && Boolean(errors.zipcode)}
                    helperText={touched.zipcode && errors.zipcode}
                  />
                </Grid>
                <Grid item xs={6}>
                  <TextField
                    variant="outlined"
                    margin="normal"
                    required
                    fullWidth
                    id="city"
                    label="Stadt"
                    name="city"
                    autoComplete="city"
                    autoFocus
                    value={values.city}
                    onChange={handleChange}
                    error={touched.city && Boolean(errors.city)}
                    helperText={touched.city && errors.city}
                  />
                </Grid>

                <Grid item xs={6}>
                  <FormControl fullWidth variant="outlined" margin="normal">
                    <InputLabel id="country-select-label">Land</InputLabel>
                    <Select id="country" name="country" value={values.country} label="Land" onChange={handleChange}>
                      <MenuItem value="de">Deutschland</MenuItem>
                      <MenuItem value="at">Österreich</MenuItem>
                      <MenuItem value="ch">Schweiz</MenuItem>
                    </Select>
                  </FormControl>
                </Grid>

                <Grid item xs={6}>
                  <TextField
                    disabled
                    type="hidden"
                    hiddenLabel
                    id="referredBy"
                    name="referredBy"
                    value={values.referredBy}
                  />
                  &nbsp;
                </Grid>

                <Grid item xs={12}>
                  &nbsp;
                </Grid>

                {/*<Grid item xs={12}>
                  <FormControl component="fieldset">
                    <FormLabel component="legend">Zahlungsdaten</FormLabel>
                    <RadioGroup
                      aria-label="payment"
                      name="has_credit_card_number"
                      value={values.has_credit_card_number}
                      onChange={handleChange}
                    >
                      <FormControlLabel value={'saved'} control={<Radio />} label="Hinterlegte Zahlungsmethode verwenden" />
                      <small>Die Kreditkarte, die auf ****1234 endet verwenden.</small>
                      <FormControlLabel value={'new'} control={<Radio />} label="Kreditkarte" />
                      <small>Kauf via Stripe; sichere Bezahlung via SSL Verbindung und AES-256 Verschlüsselung.</small>
                    </RadioGroup>
                  </FormControl>
                </Grid>

                <Grid item xs={12}>
                  &nbsp;
                </Grid>*/}

                {/*values.has_credit_card_number == 'new' && (
                  <>
                    <Grid item xs={7}>
                      <InputLabel id="creditcard-number-input">Kreditkarteninformationen</InputLabel>
                      <TextField
                        variant="outlined"
                        margin="normal"
                        required
                        fullWidth
                        id="creditcard_number"
                        label="Kreditkartennummer"
                        name="creditcard_number"
                        autoComplete="creditcard_number"
                        autoFocus
                        value={values.creditcard_number}
                        onChange={handleChange}
                        error={touched.creditcard_number && Boolean(errors.creditcard_number)}
                        helperText={touched.creditcard_number && errors.creditcard_number}
                      />
                    </Grid>
                    <Grid item xs={5}>
                      <InputLabel id="creditcard-cvc-input">&nbsp;</InputLabel>
                      <TextField
                        variant="outlined"
                        margin="normal"
                        required
                        fullWidth
                        id="creditcard_cvc"
                        label="Sicherheitscode/CVC"
                        name="creditcard_cvc"
                        autoComplete="creditcard_cvc"
                        autoFocus
                        value={values.creditcard_cvc}
                        onChange={handleChange}
                        error={touched.creditcard_cvc && Boolean(errors.creditcard_cvc)}
                        helperText={touched.creditcard_cvc && errors.creditcard_cvc}
                      />
                    </Grid>
                  </>
                )*/}
              </Grid>
            </Grid>
            <Grid item xs={6}>
              <Grid container justifyContent="center">
                <Grid item xs={8}>
                  <Typography variant="h6">{'Zugeordneter Account'}</Typography>
                  <Typography>{user?.email}&nbsp;</Typography>
                  <br />
                </Grid>

                <Grid item xs={8}>
                  <Typography variant="h6">{'Dein Warenkorb'}</Typography>
                </Grid>

                <Grid item xs={8}>
                  <Grid container justifyContent="center">
                    <Grid item xs={6}>
                      <Typography>{`Bloomup-Abo (${selectedPaymentIntervalLabel})`}</Typography>
                    </Grid>
                    <Grid item xs={6}>
                      <Typography align="right">{selectedPriceLabel}</Typography>
                      <Typography align="right">
                        <small>{`Preis ${
                          selectedPaymentInterval === 'monthly' ? 'je Monat' : 'pro Jahr'
                        } inkl. 19% MwSt.`}</small>
                      </Typography>
                    </Grid>
                  </Grid>
                </Grid>

                <Grid item xs={8}>
                  <Grid container justifyContent="center">
                    <Grid item xs={6}>
                      <Typography>{'Summe'}</Typography>
                    </Grid>
                    <Grid item xs={6}>
                      <Typography align="right">{selectedPriceSumLabel}</Typography>
                    </Grid>
                  </Grid>
                </Grid>

                <Grid item xs={8}>
                  <br />
                  <Button onClick={handleSubmit} fullWidth variant="contained" color="primary" size="large">
                    Bestellung abschließen
                  </Button>
                  <br />
                  <br />
                  <Typography>
                    <small>
                      {
                        'Mein Abo erneuert sich automatisch um den jeweiligen Zeitraum der gewählten Zahlungsweise. Jederzeit stornierbar. Das Abo wird innerhalb von 24 Stunden vor Ende des aktuellen Plans automatisch verlängert.'
                      }
                    </small>
                  </Typography>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </form>
      </Container>
      <StripeCheckoutModal
        addressData={values}
        modalOpen={addressIsFilled}
        onComplete={() => setPaymentIsComplete(true)}
        onCancel={() => {
          setAddressIsFilled(false);
          setPaymentIsComplete(null);
        }}
        product="subscription"
        paymentMethods={['sepa', 'card']}
        params={{ type: subscriptionType, interval: selectedPaymentInterval, product: productId }}
        amount={selectedPriceSum}
      />
    </div>
  );
};

export default Subscription;
