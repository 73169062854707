import React, { useContext, useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import {
  Container,
  Grid,
  Box,
  Card,
  CardContent,
  List,
  ListItem,
  ListItemText,
  ButtonGroup,
  Button,
  Typography,
  CircularProgress,
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import OrderLinkBox from '../OrderLinkBox';
import ShopProductBox from '../ShopProductBox';
import Cart from '../Cart';
//import StripeCheckoutModal from '../StripeCheckoutModal';
import { VariantContext } from '../../hooks/VariantContext';
import { UserContext } from '../../hooks/UserContext';
import { publish } from '../../events';
import PageNotFound from '../../components/pages/PageNotFound';

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
    marginTop: '50px',
  },
  toCartButton: {
    float: 'right',
    borderRadius: 25,
    padding: '10px 30px',
    backgroundColor: 'black',
    color: 'white',
  },
}));

const Shop = () => {
  const classes = useStyles();

  const history = useHistory();

  const { user } = useContext(UserContext);
  const { appVariant } = useContext(VariantContext);

  const [subscription, setSubscription] = useState(null);
  const [familyInviterUsername, setFamilyInviterUsername] = useState(null);
  const [gamificationLevel, setGamificationLevel] = useState(null);
  const [productsLoading, setProductsLoading] = useState(false);
  const [moduleProducts, setModuleProducts] = useState(null);
  const [merchandiseProducts, setMerchandiseProducts] = useState(null);
  const [courseProducts, setCourseProducts] = useState(null);
  const [productsInCart, setProductsInCart] = useState([]);
  const [uniqueInCart, setUniqueInCart] = useState([]);
  const [excludedCategories, setExcludedCategories] = useState([]);
  const [cartOpen, setCartOpen] = useState(false);
  const [paymentStart, setPaymentStart] = useState(false);
  const [paymentIsComplete, setPaymentIsComplete] = useState(null);

  useEffect(() => {
    try {
      const storedProducts = JSON.parse(localStorage.getItem('cart'));
      if (storedProducts) {
        setProductsInCart(storedProducts);
        publish('onAddProductToCart', storedProducts);
      }
    } catch (e) {
      console.error('CART ERROR:', e);
    }

    if (user?.familyMemberOf) {
      // if user is a family member, fetch the family subscription owner username
      fetch(`${process.env.REACT_APP_API_URL}/users/${user?.familyMemberOf}/username`, {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${localStorage.getItem('token')}`,
        },
      })
        .then((response) => response.json())
        .then((data) => {
          console.log('MEMBER', data);
          if (data.username) setFamilyInviterUsername(data.username);
        })
        .catch((error) => {
          console.error(error);
        });
    } else {
      // otherwise fetch the current payment to display subscription info
      fetch(`${process.env.REACT_APP_API_URL}/payments/get-own-payments?sortBy=createdAt:desc&limit=1`, {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${localStorage.getItem('token')}`,
        },
      })
        .then((response) => response.json())
        .then((data) => {
          if (data.results[0]) {
            setSubscription(data.results[0]);
          }
        })
        .catch((error) => {
          console.error(error);
        });
    }

    if (!user || productsLoading) return;
    setProductsLoading(true);

    fetch(`${process.env.REACT_APP_API_URL}/products`, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${localStorage.getItem('token')}`,
      },
    })
      .then((res) => {
        if (res.status === 200) {
          res.json().then((data) => {
            const moduleProducts = data.results.filter((product) => {
              return product.categories.find((category) => category.slug === 'zusatzmodule-app');
            });
            const merchandiseProducts = data.results.filter((product) => {
              return product.categories.find((category) => category.slug === 'merchandise');
            });
            const courseProducts = data.results.filter((product) => {
              return product.categories.find((category) => category.slug === 'seminare');
            });

            // products have the bloomerlevel attribute (saving request cost for getting gamification data)
            setGamificationLevel(moduleProducts[0].gamification_level);

            setModuleProducts(moduleProducts);
            setMerchandiseProducts(merchandiseProducts);
            setCourseProducts(courseProducts);

            setProductsLoading(false);
          });
        }
      })
      .catch((e) => {
        console.error(e);
      });
  }, [user, appVariant]);

  const getDisabledGroups = (productData) => {
    const isExcludedCategory = productData.categories.find((category) => excludedCategories.includes(category.slug));
    if (isExcludedCategory) return true;
    // check if cart contains a product with an software/physical product category (for disabling order buttons on load)
    const category = productData.categories[0].slug;

    const thisIsASoftwareProduct = 'zusatzmodule-app' === category;
    const thisIsAPhysicalProduct = ['merchandise', 'seminare'].includes(category);

    const softwareProductsExistInCart = productsInCart.some((cartProduct) =>
      ['zusatzmodule-app'].includes(cartProduct.category[0])
    );
    const physicalProductsExistInCart = productsInCart.some((cartProduct) =>
      ['merchandise', 'seminare'].includes(cartProduct.category[0])
    );

    if (physicalProductsExistInCart & thisIsASoftwareProduct) {
      return true;
    }
    if (softwareProductsExistInCart & thisIsAPhysicalProduct) {
      return true;
    }

    const uniquenessAttribute = productData.attributes.find((attribute) => attribute.name === 'uniqueInCart');
    if (uniquenessAttribute) {
      const uniquenessGroup = uniquenessAttribute.options[0];
      if (uniqueInCart.includes(uniquenessGroup)) return true;
      // check if cart contains a product with an uniqueness group and set it (for disabling order buttons on load)
      const isInCart = productsInCart.some((cartProduct) => cartProduct.id === productData.id);
      if (isInCart) {
        setUniqueInCart([...uniqueInCart, uniquenessGroup]);
      }
    }

    return false;
  };

  const onOrder = (productData, options = {}) => {
    const productAlreadyInCart = productsInCart.find((product) => product.id === productData.id);
    if (!productAlreadyInCart) {
      // check if only one of these product groups can be added to cart and disable order button (eg. backup subscription)
      const uniquenessAttribute = productData.attributes.find((attribute) => attribute.name === 'uniqueInCart');
      if (uniquenessAttribute) {
        const uniquenessGroup = uniquenessAttribute.options[0];
        if (uniqueInCart.includes(uniquenessGroup)) return;

        setUniqueInCart([...uniqueInCart, uniquenessGroup]);
      }

      // Exclude categories for ordering so subscriptions and physical products cannot exist within the same order
      let categoriesToExclude = [];
      if ('zusatzmodule-app' === productData.categories[0].slug) {
        categoriesToExclude = ['merchandise', 'seminare'];
      }
      if (['merchandise', 'seminare'].includes(productData.categories[0].slug)) {
        categoriesToExclude = ['zusatzmodule-app'];
      }
      setExcludedCategories([...excludedCategories, ...categoriesToExclude]);

      const productToAdd = {
        id: productData.id,
        title: productData.name,
        price: productData.price,
        quantity: 1,
        // TODO: maybe check this by woocommerce "shipping_class" later:
        category: productData.categories.map((category) => category.slug),
      };

      if (options.period) {
        productToAdd.price = productData.yearly_price;
        productToAdd.period = options.period;
      }

      const products = [...productsInCart, productToAdd];
      publish('onAddProductToCart', products);
      setProductsInCart(products);
      localStorage.setItem('cart', JSON.stringify(products));
    } else {
      productAlreadyInCart.quantity += 1;
      setProductsInCart([...productsInCart]);
      localStorage.setItem('cart', JSON.stringify([...productsInCart]));
    }
  };

  const onCheckout = (event) => {
    setCartOpen(true);
  };

  if (!['superadmin', 'admin'].includes(user?.role)) {
    //return <Redirect to={'/404'} />
    return <PageNotFound />;
  }

  if (cartOpen) {
    return <Cart items={productsInCart} />;
  }

  return (
    <div className={classes.root}>
      <Container maxWidth="lg">
        <Grid container direction="column" style={{ minHeight: '75vh', marginBottom: '75px' }}>
          <Grid item xs={12}>
            <Box m={4}>
              <Typography variant="h5" gutterBottom>
                <b>{'Hier kann ich alles rund um den bloomUp my life- Kosmos ordern'}</b>
              </Typography>
              <br />
              <Typography variant="subtitle1" gutterBottom>
                {
                  '... ob Bloomer werden oder Tarif wechseln, meine Erlebnisse als Buch drucken, ich  meiner bloomUp-App um weitere Zusatz-Module erweitern möchte oder einfach nur die attraktiven bloomUp-Merchandise-Artikel erwerben möchte: Hier bin ich richtig!'
                }
              </Typography>
              <br />
              <Typography variant="subtitle1" gutterBottom>
                <b>{'HINWEIS:'}</b>
                {
                  "Abo's (Tarife, Zusatzmodule) können nicht mit den anderen Produkten (Lebensbücher, Merchandise) zusammen geordert werden. Bitte für diese Produktgruppen jeweils einzelne Bestellungen durchführen."
                }
              </Typography>
            </Box>
          </Grid>

          {subscription && (
            <Grid item xs={12} style={{ width: '100%' }}>
              <Box m={4}>
                <Typography variant="h5" gutterBottom>
                  <b>{'Mein Tarif & meine Lebensbücher'}</b>
                </Typography>
              </Box>
              <Box m={4}>
                <Grid container justifyContent="center" spacing={1}>
                  <Grid item lg={3} md={4} xs={12}>
                    <OrderLinkBox
                      header={() => {
                        return (
                          <>
                            {!subscription && !user?.familyMemberOf && (
                              <b>
                                bloomUp-Abo
                                <br />
                                ordern
                              </b>
                            )}
                            {(subscription || user?.familyMemberOf) && <b>Mein Tarif</b>}
                          </>
                        );
                      }}
                      description={() => {
                        return (
                          <>
                            {!subscription &&
                              !user?.familyMemberOf &&
                              'Hier schalte ich meine bloomUp-App frei und genieße alle Vorteile von bloomUp my life.'}
                            {(subscription || user?.familyMemberOf) &&
                              (user?.familySubscription || user?.familyMemberOf ? 'Familien-Tarif' : 'Standard-Tarif')}
                            {subscription?.price && (
                              <>
                                <br />
                                {new Intl.NumberFormat('de-DE', { style: 'currency', currency: 'EUR' }).format(
                                  subscription.price
                                )}
                                /{subscription.paymentInterval === 'yearly' ? 'Jahr' : 'Monat'}
                              </>
                            )}
                            {familyInviterUsername && (
                              <>
                                <br />
                                Familienmitglied von {familyInviterUsername}
                              </>
                            )}
                            {subscription?.lockDate && (
                              <>
                                <br />
                                <br />
                                Wird pausiert am {subscription.lockDate}
                              </>
                            )}
                          </>
                        );
                      }}
                      linkText={() => {
                        return !subscription && !user?.familyMemberOf ? 'bloomUp-Abo ordern' : 'Tarif wechseln';
                      }}
                      linkColor={() => {
                        return !subscription && !user?.familyMemberOf ? 'primary' : 'default';
                      }}
                      linkUrl={() => {
                        return !subscription && !user?.familyMemberOf
                          ? 'subscription'
                          : 'subscription?action=changeSubscription';
                      }}
                    />
                  </Grid>

                  <Box component={Grid} item lg={3} md={4} display={{ xs: 'none', sm: 'block' }} />

                  <Grid item lg={3} md={4} xs={12}>
                    <OrderLinkBox
                      header={() => {
                        return appVariant === 'bloomup' ? (
                          <b>
                            {'bloomUp Lebensbuch'}
                            <br />
                            {'ordern'}
                          </b>
                        ) : (
                          <b>
                            {'Projekt-Print'}
                            <br />
                            {'beauftragen'}
                          </b>
                        );
                      }}
                      description={() => {
                        return appVariant === 'bloomup'
                          ? 'Ich möchte mein Lebensbuch in gedruckter Form erhalten.'
                          : 'Hier kann ich mein Projekt in gedruckter Form erhalten';
                      }}
                      linkText={() => {
                        return appVariant === 'bloomup' ? 'Lebensbuch ordern' : 'Projekt-Print beauftragen';
                      }}
                      linkUrl="bloomprint"
                    />
                  </Grid>
                </Grid>
              </Box>
            </Grid>
          )}

          {!!moduleProducts?.length && (
            <Grid item xs={12} style={{ width: '100%' }}>
              <Box m={4}>
                <Typography variant="h5" gutterBottom style={{ float: 'left' }}>
                  <b>
                    {'bloomUp-App Zusatzmodule im Monats- oder Jahres-Abo - „'}
                    {gamificationLevel}
                    {'“-Tarif'}
                  </b>
                </Typography>

                {!!productsInCart.length && (
                  <Button variant="contained" className={classes.toCartButton} onClick={onCheckout}>
                    zum Warenkorb
                  </Button>
                )}
              </Box>
              <Box m={4}>
                <Grid container spacing={10}>
                  {moduleProducts &&
                    moduleProducts.map((productData) => (
                      <Grid item lg={4} xs={12} key={productData.id}>
                        <ShopProductBox
                          width="100%"
                          height="400px"
                          handleOrder={onOrder}
                          handleCheckout={onCheckout}
                          data={productData}
                          disableAddButton={() => getDisabledGroups(productData)}
                          //style="softwareProducts"
                        />
                      </Grid>
                    ))}
                </Grid>
              </Box>
            </Grid>
          )}

          {!!merchandiseProducts?.length && (
            <Grid item xs={12} style={{ width: '100%' }}>
              <Box m={4}>
                <Typography variant="h5" gutterBottom style={{ float: 'left' }}>
                  <b>
                    {'bloomUp-Merchandise - „'}
                    {gamificationLevel}
                    {'“-Tarif'}
                  </b>
                </Typography>

                {!!productsInCart.length && (
                  <Button variant="contained" className={classes.toCartButton} onClick={onCheckout}>
                    zum Warenkorb
                  </Button>
                )}
              </Box>

              <Box m={4}>
                <Grid container spacing={10}>
                  {merchandiseProducts &&
                    merchandiseProducts.map((productData) => (
                      <Grid item lg={4} xs={12} key={productData.id}>
                        <ShopProductBox
                          width="100%"
                          height="400px"
                          handleOrder={onOrder}
                          handleCheckout={onCheckout}
                          data={productData}
                          disableAddButton={() => getDisabledGroups(productData)}
                          //style="physicalProducts"
                        />
                      </Grid>
                    ))}
                </Grid>
              </Box>
            </Grid>
          )}

          {!!courseProducts?.length && (
            <Grid item xs={12} style={{ width: '100%' }}>
              <Box m={4}>
                <Typography variant="h5" gutterBottom style={{ float: 'left' }}>
                  <b>
                    {'bloomUp-Seminare - „'}
                    {gamificationLevel}
                    {'“-Tarif'}
                  </b>
                </Typography>

                {!!productsInCart.length && (
                  <Button variant="contained" className={classes.toCartButton} onClick={onCheckout}>
                    zum Warenkorb
                  </Button>
                )}
              </Box>

              <Box m={4}>
                <Grid container spacing={10}>
                  {courseProducts &&
                    courseProducts.map((productData) => (
                      <Grid item lg={4} xs={12} key={productData.id}>
                        <ShopProductBox
                          width="100%"
                          height="400px"
                          handleOrder={onOrder}
                          handleCheckout={onCheckout}
                          data={productData}
                          disableAddButton={() => getDisabledGroups(productData)}
                          //style="physicalProducts"
                        />
                      </Grid>
                    ))}
                </Grid>
              </Box>
            </Grid>
          )}

          {productsLoading && (
            <Box style={{ display: 'flex', justifyContent: 'center' }}>
              <CircularProgress />
            </Box>
          )}
        </Grid>
      </Container>
    </div>
  );
};

export default Shop;
