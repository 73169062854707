import React, { useContext, useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import {
  Container,
  Grid,
  Box,
  Card,
  CardContent,
  List,
  ListItem,
  ListItemText,
  ButtonGroup,
  Button,
  Link,
  Typography,
  CircularProgress,
  CardActions,
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import RemoveIcon from '@material-ui/icons/RemoveCircleOutline';
import StripeCheckoutModal from './StripeCheckoutModal';
import { VariantContext } from '../hooks/VariantContext';
import { UserContext } from '../hooks/UserContext';
import { publish } from '../events';

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
    marginTop: '50px',
  },
}));

const Cart = (props) => {
  const classes = useStyles();

  const history = useHistory();

  const { items } = props;

  const { user } = useContext(UserContext);
  const { appVariant } = useContext(VariantContext);

  const [subscription, setSubscription] = useState(null);
  const [familyInviterUsername, setFamilyInviterUsername] = useState(null);
  const [productsLoading, setProductsLoading] = useState(false);
  const [moduleProducts, setModuleProducts] = useState(null);
  const [merchandiseProducts, setMerchandiseProducts] = useState(null);
  const [courseProducts, setCourseProducts] = useState(null);
  const [productsInCart, setProductsInCart] = useState(items);
  const [cartOpen, setCartOpen] = useState(false);
  const [paymentStart, setPaymentStart] = useState(false);
  const [paymentIsComplete, setPaymentIsComplete] = useState(null);

  const url = new URL(window.location);
  const payloadParam = url.searchParams.get('payload');
  const intentParam = url.searchParams.get('payment_intent');
  const statusParam = url.searchParams.get('redirect_status');

  const handleIncrement = (product) => {
    const productInCart = productsInCart.find((cartProduct) => cartProduct.id === product.id);
    productInCart.quantity++;

    setProductsInCart([...productsInCart]);

    localStorage.setItem('cart', JSON.stringify(productsInCart));
  };

  const handleDecrement = (product) => {
    const productInCart = productsInCart.find((cartProduct) => cartProduct.id === product.id);
    productInCart.quantity--;

    setProductsInCart([...productsInCart]);

    localStorage.setItem('cart', JSON.stringify(productsInCart));
  };

  const handleRemove = (product) => {
    const allOtherProductsInCart = productsInCart.reduce((accumulator, cartProduct) => {
      if (cartProduct.id !== product.id) accumulator.push(cartProduct);

      return accumulator;
    }, []);

    setProductsInCart(allOtherProductsInCart);

    localStorage.setItem('cart', JSON.stringify(allOtherProductsInCart));

    publish('onRemoveProductFromCart', allOtherProductsInCart);
  };

  const hasSoftwareProductsOnly = productsInCart.every((cartProduct) => cartProduct.category.includes('zusatzmodule-app'));

  const countryCode = user?.addresses?.shipping ? user?.addresses?.shipping.country : user?.addresses?.billing.country;
  const shippingCosts = {
    de: 7,
    at: 7,
    ch: 17.5,
  };
  const getShippingCosts = () => {
    if (getCartValue() <= 0) return 0;

    if (hasSoftwareProductsOnly) {
      return 0;
    }

    return shippingCosts[countryCode];
  };

  const getCartValue = () => {
    const totalValue = productsInCart.reduce(
      (accumulator, product) => (accumulator += product.quantity * Number(product.price)),
      0
    );

    return totalValue;
  };

  const handlePaymentComplete = (payment) => {
    setPaymentIsComplete(true);

    console.log('PAYMENT COMPLETE', payment);
  };

  if (intentParam && paymentIsComplete === null)
    return (
      <Grid
        container
        spacing={0}
        direction="column"
        alignItems="center"
        justifyContent="center"
        style={{ minHeight: '80vh' }}
      >
        <CircularProgress style={{ margin: '0 auto' }} />
      </Grid>
    );

  if (paymentIsComplete !== null) {
    return (
      <Grid
        container
        spacing={0}
        direction="column"
        alignItems="center"
        justifyContent="center"
        style={{ minHeight: '80vh' }}
      >
        <Grid item xs={6} style={{ maxWidth: '90vw' }}>
          <Typography variant="h5" gutterBottom style={{ padding: '10px' }}>
            {paymentIsComplete === true && 'Bestellung erfolgreich.'}
            {paymentIsComplete === false && 'Bei der Zahlung ist ein Fehler aufgetreten.'}
          </Typography>
        </Grid>
        <Grid item xs={6} style={{ maxWidth: '90vw' }}>
          <Typography variant="body1">
            {paymentIsComplete === true && 'Du erhältst in Kürze eine Bestätigung per E-Mail.'}
            {paymentIsComplete === false && 'Bitte versuche es später noch einmal oder wende dich an den Support.'}
          </Typography>
        </Grid>
      </Grid>
    );
  }

  return (
    <div className={classes.root}>
      <Container maxWidth="lg">
        <Typography variant="h3" gutterBottom>
          <b>Warenkorb</b>
        </Typography>
        <Grid container spacing={0} style={{ minHeight: '70vh' }}>
          <Grid item xs={12} md={8}>
            <Card>
              <CardContent>
                <Typography variant="h6">Produkte</Typography>
                {productsInCart.map((product) => (
                  <div key={product.id}>
                    <List dense={true}>
                      <ListItem>
                        <ListItemText style={{ width: '100px' }}>
                          <ButtonGroup size="small" aria-label="small outlined button group">
                            <Button disabled={product.quantity <= 1} onClick={() => handleDecrement(product)}>
                              -
                            </Button>
                            <Button disabled>{product.quantity}</Button>
                            <Button
                              disabled={product.quantity >= 100 || product.category.includes('zusatzmodule-app')}
                              onClick={() => handleIncrement(product)}
                            >
                              +
                            </Button>
                          </ButtonGroup>
                          <br />
                          {/*<Link onClick={() => handleRemove(product)}>entfernen</Link>*/}
                          <Button
                            startIcon={<RemoveIcon />}
                            variant="text"
                            size="small"
                            style={{ backgroundColor: 'transparent' }}
                            onClick={() => handleRemove(product)}
                          >
                            entfernen
                          </Button>
                        </ListItemText>
                        <ListItemText
                          style={{ width: 'auto' }}
                          primary={`${product.quantity} x ${product.title}`}
                          secondary={
                            <>
                              <Typography variant="caption">
                                {new Intl.NumberFormat('de-DE', { style: 'currency', currency: 'EUR' }).format(
                                  product.price
                                )}
                              </Typography>
                              <br />
                              {product.subtitle && <Typography variant="caption">{product.subtitle}</Typography>}
                            </>
                          }
                        />
                        <ListItemText
                          primary={new Intl.NumberFormat('de-DE', { style: 'currency', currency: 'EUR' }).format(
                            product.quantity * product.price
                          )}
                          secondary={<Typography variant="caption">inkl. 19% MwSt.</Typography>}
                        />
                      </ListItem>
                    </List>
                  </div>
                ))}
              </CardContent>
              <CardActions>
                <Box p={2}>
                  <Button variant="contained" onClick={() => history.go('/shop')}>
                    weiter einkaufen
                  </Button>
                </Box>
              </CardActions>
            </Card>
          </Grid>

          <Grid item xs={6} md={4}>
            <Grid container justifyContent="center">
              <Grid item xs={12} md={8}>
                <Typography variant="h6">{'Dein Warenkorb'}</Typography>
              </Grid>

              <Grid item xs={12} md={8}>
                <Grid container justifyContent="center">
                  <Grid item xs={6}>
                    <Typography>{`Zwischensumme`}</Typography>
                  </Grid>
                  <Grid item xs={6}>
                    <Typography align="right">
                      {new Intl.NumberFormat('de-DE', { style: 'currency', currency: 'EUR' }).format(getCartValue())}
                    </Typography>
                    <Typography align="right">
                      <small>{`Preis inkl. 19% MwSt.`}</small>
                    </Typography>
                  </Grid>
                </Grid>
              </Grid>

              <Grid item xs={12} md={8}>
                <Grid container justifyContent="center">
                  <Grid item xs={6}>
                    <Typography>{'Versand'}</Typography>
                  </Grid>
                  <Grid item xs={6}>
                    <Typography align="right">
                      {new Intl.NumberFormat('de-DE', { style: 'currency', currency: 'EUR' }).format(getShippingCosts())}
                    </Typography>
                  </Grid>
                </Grid>
              </Grid>

              {/*coupon && (
                <Grid item xs={12} md={8}>
                  <Grid container justifyContent="center">
                    <Grid item xs={6}>
                      <Typography>{'Gutschein'}</Typography>
                    </Grid>
                    <Grid item xs={6}>
                      <Typography align="right">
                        -
                        {new Intl.NumberFormat('de-DE', { style: 'currency', currency: 'EUR' }).format(
                          getCouponDiscount()
                        )}
                      </Typography>
                    </Grid>
                  </Grid>
                </Grid>
              )*/}

              <Grid item xs={12} md={8}>
                <Grid container justifyContent="center">
                  <Grid item xs={6}>
                    <Typography>
                      <b>{'Summe'}</b>
                    </Typography>
                  </Grid>
                  <Grid item xs={6}>
                    <Typography align="right">
                      <b>
                        {new Intl.NumberFormat('de-DE', { style: 'currency', currency: 'EUR' }).format(
                          getCartValue() + getShippingCosts() //- getCouponDiscount()
                        )}
                      </b>
                    </Typography>
                  </Grid>
                </Grid>
              </Grid>

              <Grid item xs={12} md={8}>
                <br />
                <Button
                  onClick={() => setPaymentStart(true)}
                  fullWidth
                  variant="contained"
                  color="primary"
                  disabled={getCartValue() <= 0}
                  size="large"
                >
                  Zur Kasse
                </Button>
                <br />
                <br />
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Container>

      <StripeCheckoutModal
        modalOpen={paymentStart}
        onComplete={(payment) => handlePaymentComplete(payment)}
        onCancel={() => {
          setPaymentStart(false);
          setPaymentIsComplete(null);
        }}
        product={hasSoftwareProductsOnly ? 'subscription' : 'physical'}
        paymentMethods={['sofort', 'card']}
        /*params={{
          printId: values.printId,
          printTitle: prints.find((print) => print.id === values.printId).title,
          output: values.output,
          format: values.format,
          type: values.type,
          pages: numPagesToPrint,
          quantity: printsInCart[0].quantity,
        }}*/
        cartContents={[...productsInCart]}
        //amount={printsInCart[0].quantity * getPriceOfSelection(numPagesToPrint)}
        //shippingCosts={getShippingCosts()}
        //couponCode={values.coupon_code}
      />
    </div>
  );
};

export default Cart;
