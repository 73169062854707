import React from 'react';
import { Link } from 'react-router-dom';
import { Container, Box, Paper, Grid, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
    marginTop: '50px',
  },
  backLink: {
    textDecoration: 'none',
  },
  cardContent: {
    padding: '30px 20px',
  },
  levelIcon: {
    width: '80%',
  },
}));

const Level = () => {
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <Container maxWidth="lg" style={{ minHeight: '140vh' }}>
        <Grid container spacing={3}>
          <Grid item xs={12}>
            <Box style={{ paddingTop: '50px', paddingBottom: '50px', width: '100%' }}>
              <Typography variant="h4">bloomUp Wissen: Bloomer-Level</Typography>
              <Typography variant="h6" color="primary" gutterBottom>
                <Link to="/" className={classes.backLink}>
                  ← zurück zur Übersicht
                </Link>
              </Typography>
            </Box>
          </Grid>

          <Grid item xs={12} md={6}>
            <Paper elevation={5} className={classes.cardContent}>
              <Grid container spacing={3}>
                <Grid item xs={12}>
                  <Typography variant="h5" gutterBottom>
                    {'Bloomer-Level Übersicht'}
                  </Typography>
                  <Typography variant="body2">
                    {
                      'Bei der Nutzung der bloomUp-App erhalte ich für meine Aktivitäten bloomyPoints. Je nach Level komme ich dann in den Genuss von Vorteilen im bloomUp-Kosmos. Eine genaue Aufstellung finde ich in der bloomUp-Wissensdatenbank unter bloomyPoints.'
                    }
                  </Typography>
                </Grid>

                <Grid item xs={4}>
                  <img className={classes.levelIcon} src="images/beginner-purple@2x.png" alt="Beginner" />
                  <Typography variant="subtitle2">
                    <b>{'bloomUp Beginner'}</b>
                  </Typography>
                  <Typography variant="body2">{'0 - 999 bloomyPoints'}</Typography>
                </Grid>

                <Grid item xs={4}>
                  <img className={classes.levelIcon} src="images/performer-purple@2x.png" alt="Performer" />
                  <Typography variant="subtitle2">
                    <b>{'bloomUp Performer'}</b>
                  </Typography>
                  <Typography variant="body2">{'1000 - 4999 bloomyPoints'}</Typography>
                </Grid>

                <Grid item xs={4}>
                  <img className={classes.levelIcon} src="images/insider-purple@2x.png" alt="Insider" />
                  <Typography variant="subtitle2">
                    <b>{'bloomUp Insider'}</b>
                  </Typography>
                  <Typography variant="body2">{'ab 5000 bloomyPoints'}</Typography>
                </Grid>
              </Grid>
            </Paper>
          </Grid>

          <Grid item xs={12}>
            <Box style={{ width: '100%' }}>
              <Paper elevation={5} className={classes.cardContent}>
                <Grid container spacing={3}>
                  <Grid item xs={12}>
                    <Typography variant="h5" gutterBottom>
                      bloomyPoints <img src="/images/bloomypoints-icon-bw.png" alt="Bloomypoints" />
                    </Typography>
                    <Typography variant="body2" gutterBottom>
                      &nbsp;
                    </Typography>
                  </Grid>
                  <Grid item xs={12}>
                    <Typography variant="h5" gutterBottom>
                      Beispiele für bloomyPoints
                    </Typography>
                  </Grid>

                  <Grid item xs={4}>
                    <Typography variant="subtitle2">
                      <b>{'bloomyPoints - Allgemein'}</b>
                    </Typography>
                    <ul style={{ paddingLeft: '20px' }}>
                      <li>
                        <Typography variant="body2">{'tägliche Nutzung der App'}</Typography>
                      </li>
                      <li>
                        <Typography variant="body2">{'Abo wird abgeschlossen'}</Typography>
                      </li>
                      <li>
                        <Typography variant="body2">{'Bearbeitung von Einträgen'}</Typography>
                      </li>
                    </ul>
                  </Grid>

                  <Grid item xs={4}>
                    <Typography variant="subtitle2">
                      <b>{'bloomyPoints für Friend Events'}</b>
                    </Typography>
                    <ul style={{ paddingLeft: '20px' }}>
                      <li>
                        <Typography variant="body2">{'Erstellen von Events'}</Typography>
                      </li>
                      <li>
                        <Typography variant="body2">{'Einladung annehmen'}</Typography>
                      </li>
                      <li>
                        <Typography variant="body2">{'Bearbeitung von Einträgen'}</Typography>
                      </li>
                    </ul>
                  </Grid>

                  <Grid item xs={4}>
                    <Typography variant="subtitle2">
                      <b>{'bloomyPoints für Print'}</b>
                    </Typography>
                    <ul style={{ paddingLeft: '20px' }}>
                      <li>
                        <Typography variant="body2">{'Erstellen eines eigenen Buches'}</Typography>
                      </li>
                      <li>
                        <Typography variant="body2">{'Bestellung abschicken'}</Typography>
                      </li>
                    </ul>
                  </Grid>
                </Grid>
              </Paper>
            </Box>
          </Grid>
        </Grid>
      </Container>
    </div>
  );
};

export default Level;
