import React, { useEffect, useState, useContext } from 'react';
import { useHistory } from 'react-router-dom';
import { VariantContext } from '../../hooks/VariantContext';
import { UserContext } from '../../hooks/UserContext';
import OrderLinkBox from '../OrderLinkBox';
import {
  Grid,
  Box,
  Container,
  Avatar,
  Divider,
  Card,
  CardContent,
  CardActions,
  Button,
  Typography,
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import Alert from '@material-ui/lab/Alert';

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
    minHeight: '1000px',
  },
  page: {
    backgroundColor: '#ebebeb',
    minHeight: '1000px',
  },
  greetingBox: {
    paddingTop: '50px',
    paddingBottom: '50px',
  },
  metricsBox: {
    paddingTop: '50px',
    paddingBottom: '50px',
  },
  contentCard: {
    display: 'flex',
    flexDirection: 'column',
    minHeight: '256px',
    padding: '15px',
  },
  contentCardAction: {
    marginTop: 'auto',
  },
  contentCardDescription: {
    minHeight: '60px',
  },
  profileImageContainer: {
    padding: '0 20px 0 0',
    float: 'left',
    [theme.breakpoints.down('sm')]: {
      width: '100%',
      height: '269px',
      padding: 0,
    },
  },
  profileImage: {
    width: '140px',
    height: '140px',
    [theme.breakpoints.down('sm')]: {
      width: '100%',
      height: '100%',
    },
  },
}));

const Dashboard = () => {
  const classes = useStyles();

  const [messages, setMessages] = useState('');
  const [rewards, setRewards] = useState({});
  const [subscription, setSubscription] = useState(null);
  const [familyInviterUsername, setFamilyInviterUsername] = useState(null);

  const history = useHistory();
  const { appVariant } = useContext(VariantContext);
  const { user } = useContext(UserContext);

  const greeting = user?.name.split(' ')[0] ? `Hey ${user?.name.split(' ')[0]},` : `Hey ${user?.name || ''},`;

  useEffect(() => {
    if (user?.familyMemberOf) {
      // if user is a family member, fetch the family subscription owner username
      fetch(`${process.env.REACT_APP_API_URL}/users/${user?.familyMemberOf}/username`, {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${localStorage.getItem('token')}`,
        },
      })
        .then((response) => response.json())
        .then((data) => {
          console.log('MEMBER', data);
          if (data.username) setFamilyInviterUsername(data.username);
        })
        .catch((error) => {
          console.error(error);
        });
    } else {
      // otherwise fetch the current payment to display subscription info
      fetch(`${process.env.REACT_APP_API_URL}/payments/get-own-payments?sortBy=createdAt:desc&limit=1`, {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${localStorage.getItem('token')}`,
        },
      })
        .then((response) => response.json())
        .then((data) => {
          if (data.results[0]) {
            setSubscription(data.results[0]);
          }
        })
        .catch((error) => {
          console.error(error);
        });
    }

    if (appVariant === 'ljs') return;
    fetch(`${process.env.REACT_APP_API_URL}/rewards`, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${localStorage.getItem('token')}`,
      },
    })
      .then((response) => response.json())
      .then((data) => {
        setRewards(data);
      })
      .catch((error) => {
        console.error(error);
      });
  }, [user, appVariant]);

  useEffect(() => {
    let apiMessages = localStorage.getItem('messages');
    if (
      apiMessages ===
      'Du hast eine Verifizierungsanfrage gesendet. Bitte klicke auf den Link in der Verifizierungsmail, um diese abzuschließen.'
    ) {
      const resendLink = 'Du kannst sie Dir in <a href="mybloomup">Deinem Profil</a> erneut zusenden lassen';
      apiMessages = `${apiMessages} ${resendLink}`;
    }
    setMessages(apiMessages);

    // Hide message after login
    history.listen((location) => {
      if (location !== '/mybloomup') {
        localStorage.removeItem('messages');
        setMessages(null);
      }
    });
  }, [history, messages]);

  if (!user) {
    return <div className={classes.root} />;
  }

  const SubscriptionOrderBox = () => {
    return (
      <Grid item lg={3} md={4} xs={12}>
        <Card elevation={5} p={4} className={classes.contentCard}>
          <CardContent>
            <Typography variant="h6" gutterBottom>
              {!subscription && !user?.familyMemberOf && (
                <b>
                  bloomUp-Abo
                  <br />
                  ordern
                </b>
              )}
              {(subscription || user?.familyMemberOf) && <b>Mein Tarif</b>}
            </Typography>
            <Typography variant="body2" gutterBottom className={classes.contentCardDescription}>
              {!subscription &&
                !user?.familyMemberOf &&
                'Hier schalte ich meine bloomUp-App frei und genieße alle Vorteile von bloomUp my life.'}
              {(subscription || user?.familyMemberOf) &&
                (user?.familySubscription || user?.familyMemberOf ? 'Familien-Tarif' : 'Standard-Tarif')}

              {subscription?.price && (
                <>
                  <br />
                  {new Intl.NumberFormat('de-DE', { style: 'currency', currency: 'EUR' }).format(subscription.price)}/
                  {subscription.paymentInterval === 'yearly' ? 'Jahr' : 'Monat'}
                </>
              )}

              {familyInviterUsername && (
                <>
                  <br />
                  Familienmitglied von {familyInviterUsername}
                </>
              )}

              {subscription?.lockDate && (
                <>
                  <br />
                  <br />
                  Wird pausiert am {subscription.lockDate}
                </>
              )}
            </Typography>
          </CardContent>
          <CardActions className={classes.contentCardAction}>
            {!subscription && !user?.familyMemberOf && (
              <Button href="subscription" fullWidth size="large" variant="contained" color="primary">
                bloomUp-Abo ordern
              </Button>
            )}
            {(subscription || user?.familyMemberOf) && (
              <Button
                href="subscription?action=changeSubscription"
                fullWidth
                size="large"
                variant="contained"
                color="secondary"
              >
                Tarif wechseln
              </Button>
            )}
          </CardActions>
        </Card>
      </Grid>
    );
  };

  return (
    <div className={classes.root}>
      <Container maxWidth="lg" className={classes.page}>
        <Box hidden={!messages} p={2}>
          <Alert severity="warning">
            <div dangerouslySetInnerHTML={{ __html: messages }}></div>
          </Alert>
        </Box>
        <Grid container justifyContent="center" spacing={1}>
          <Grid item xs={9} style={{ textAlign: 'center' }}>
            <Grid container>
              {appVariant === 'ljs' && (
                <Grid item md={2} xs={12}>
                  &nbsp;
                </Grid>
              )}

              <Grid item md={7} xs={12}>
                <Box align="left" className={classes.greetingBox}>
                  <div className={classes.profileImageContainer}>
                    {!user.profilePictureHash && appVariant === 'bloomup' && (
                      <img width="100%" className={classes.profileImage} src="/logo192.png" />
                    )}
                    {!user.profilePictureHash && appVariant === 'ljs' && (
                      <img width="100%" className={classes.profileImage} height="140" src="/logo-ljs192.png" />
                    )}
                    {user.profilePictureHash && (
                      <Avatar
                        alt="Profile image"
                        className={classes.profileImage}
                        src={`${process.env.REACT_APP_API_URL}/files/${user.profilePictureHash}`}
                      />
                    )}
                  </div>
                  <div>
                    <Typography variant="h3">
                      <b>{greeting}</b>
                    </Typography>
                    <Typography variant="h4" gutterBottom>
                      <b>es kann losgehen!</b>
                    </Typography>
                    <Typography variant="h6" gutterBottom>
                      <b>Was möchtest Du als nächstes tun?</b>
                    </Typography>
                  </div>
                </Box>
              </Grid>

              {appVariant === 'bloomup' && (
                <Grid item md={5} xs={12}>
                  <Box className={classes.greetingBox}>
                    <Card>
                      <CardContent>
                        <Grid container>
                          <Grid item xs={7}>
                            <Typography align="left">
                              <b>Status</b>
                            </Typography>
                          </Grid>
                          <Grid item xs={5}>
                            <Typography align="left">
                              <b>bloomyPoints</b>
                            </Typography>
                          </Grid>

                          <Grid item xs={7}>
                            <Typography style={{ float: 'left' }} variant="h6" color="primary">
                              <b>{rewards.level}</b>
                            </Typography>
                            &nbsp;
                            <img width="25" height="26" src="images/beginner-purple@2x.png" />
                          </Grid>
                          <Grid item xs={5}>
                            <Typography style={{ float: 'left' }} variant="h6" color="primary">
                              <b>{rewards?.points}</b>
                            </Typography>
                            &nbsp;
                            <img width="25" height="26" src="images/bloomypoints-icon-bw.png" />
                          </Grid>
                        </Grid>

                        <Divider variant="middle" />

                        <Grid container>
                          <Grid item md={6} xs={12}>
                            <Grid container>
                              <Grid item xs={10}>
                                <Typography align="left" variant="body2">
                                  Tageseinträge
                                </Typography>
                              </Grid>
                              <Grid item xs={2}>
                                <Typography variant="body2">
                                  <b>{rewards?.normalEntries}</b>
                                </Typography>
                              </Grid>

                              <Grid item xs={10}>
                                <Typography align="left" variant="body2">
                                  Best of my Day
                                </Typography>
                              </Grid>
                              <Grid item xs={2}>
                                <Typography variant="body2">
                                  <b>{rewards?.bestOfDayEntries}</b>
                                </Typography>
                              </Grid>

                              <Grid item xs={10}>
                                <Typography align="left" variant="body2">
                                  bloomUp-Fit
                                </Typography>
                              </Grid>
                              <Grid item xs={2}>
                                <Typography variant="body2">
                                  <b>{rewards?.performedFitTrainings}</b>
                                </Typography>
                              </Grid>
                            </Grid>
                          </Grid>

                          <Grid item md={6} xs={12}>
                            <Grid container>
                              <Grid item xs={10}>
                                <Typography align="left" variant="body2">
                                  bloomUp-Friend Event
                                </Typography>
                              </Grid>
                              <Grid item xs={2}>
                                <Typography variant="body2">
                                  <b>{rewards?.friends}</b>
                                </Typography>
                              </Grid>

                              <Grid item xs={10}>
                                <Typography align="left" variant="body2">
                                  bloomUp Print Order
                                </Typography>
                              </Grid>
                              <Grid item xs={2}>
                                <Typography variant="body2">
                                  <b>{rewards?.printOrders}</b>
                                </Typography>
                              </Grid>

                              <Grid item xs={10}>
                                <Typography align="left" variant="body2">
                                  Bloomer Community
                                </Typography>
                              </Grid>
                              <Grid item xs={2}>
                                <Typography variant="body2">
                                  <b>{rewards?.community}</b>
                                </Typography>
                              </Grid>
                            </Grid>
                          </Grid>
                        </Grid>
                      </CardContent>
                    </Card>
                  </Box>
                </Grid>
              )}
            </Grid>
          </Grid>

          <Grid item xs={12}>
            &nbsp;
          </Grid>

          {appVariant === 'bloomup' && (
            <Grid item lg={3} md={4} xs={12}>
              <OrderLinkBox
                header={() => {
                  return (
                    <>
                      {!subscription && !user?.familyMemberOf && (
                        <b>
                          bloomUp-Abo
                          <br />
                          ordern
                        </b>
                      )}
                      {(subscription || user?.familyMemberOf) && <b>Mein Tarif</b>}
                    </>
                  );
                }}
                description={() => {
                  return (
                    <>
                      {!subscription &&
                        !user?.familyMemberOf &&
                        'Hier schalte ich meine bloomUp-App frei und genieße alle Vorteile von bloomUp my life.'}
                      {(subscription || user?.familyMemberOf) &&
                        (user?.familySubscription || user?.familyMemberOf ? 'Familien-Tarif' : 'Standard-Tarif')}
                      {subscription?.price && (
                        <>
                          <br />
                          {new Intl.NumberFormat('de-DE', { style: 'currency', currency: 'EUR' }).format(subscription.price)}
                          /{subscription.paymentInterval === 'yearly' ? 'Jahr' : 'Monat'}
                        </>
                      )}
                      {familyInviterUsername && (
                        <>
                          <br />
                          Familienmitglied von {familyInviterUsername}
                        </>
                      )}
                      {subscription?.lockDate && (
                        <>
                          <br />
                          <br />
                          Wird pausiert am {subscription.lockDate}
                        </>
                      )}
                    </>
                  );
                }}
                linkText={() => {
                  return !subscription && !user?.familyMemberOf ? 'bloomUp-Abo ordern' : 'Tarif wechseln';
                }}
                linkColor={() => {
                  return !subscription && !user?.familyMemberOf ? 'primary' : 'default';
                }}
                linkUrl={() => {
                  return !subscription && !user?.familyMemberOf ? 'subscription' : 'subscription?action=changeSubscription';
                }}
              />
            </Grid>
          )}

          <Grid item lg={3} md={4} xs={12}>
            <OrderLinkBox
              header={
                <b>
                  {'Mein Profil'}
                  <br />
                  {'verwalten'}
                </b>
              }
              description={() => {
                return appVariant === 'bloomup'
                  ? 'Hier passe ich mein Profil an, finde meinen Empfehlungslink, etc.'
                  : 'Passe Dein Profil an';
              }}
              linkText="Daten verwalten"
              linkUrl="mybloomup"
            />
          </Grid>
          <Grid item lg={3} md={4} xs={12}>
            <OrderLinkBox
              header={() => {
                return appVariant === 'bloomup' ? (
                  <b>
                    {'bloomUp Lebensbuch'}
                    <br />
                    {'ordern'}
                  </b>
                ) : (
                  <b>
                    {'Projekt-Print'}
                    <br />
                    {'beauftragen'}
                  </b>
                );
              }}
              description={() => {
                return appVariant === 'bloomup'
                  ? 'Ich möchte mein Lebensbuch in gedruckter Form erhalten.'
                  : 'Hier kann ich mein Projekt in gedruckter Form erhalten';
              }}
              linkText={() => {
                return appVariant === 'bloomup' ? 'Lebensbuch ordern' : 'Projekt-Print beauftragen';
              }}
              linkUrl="bloomprint"
            />
          </Grid>
        </Grid>

        {appVariant === 'bloomup' && (
          <Grid container justifyContent="center" spacing={1}>
            <Grid item xs={12}>
              <Box style={{ paddingTop: '100px', paddingBottom: '50px', textAlign: 'center', margin: 'auto' }}>
                <div>
                  <Typography variant="h3" gutterBottom>
                    <b>
                      bloomUp my life
                      <sup>
                        <small>®</small>
                      </sup>
                      <br />
                      Glossar / Wissens&shy;datenbank
                    </b>
                  </Typography>
                </div>
              </Box>
            </Grid>

            <Grid item lg={3} md={4} xs={12}>
              <OrderLinkBox
                header={
                  <b>
                    {'Diese Level kann'}
                    <br />
                    {'ich erreichen'}
                  </b>
                }
                description="So kann ich zum bloomUp Insider werden."
                linkText="bloomUp Level"
                linkUrl="bloomlevel"
              />
            </Grid>

            <Grid item lg={3} md={4} xs={12}>
              <OrderLinkBox
                header={
                  <b>
                    {'Rund um die'}
                    <br />
                    {'bloomUp my Life App'}
                  </b>
                }
                description="Ich möchte die App besser kennenlernen."
                linkText={<nobr>Glossar / Wissensdatenbank</nobr>}
                linkUrl="glossar"
              />
            </Grid>

            <Grid item xs={12}>
              &nbsp;
            </Grid>
          </Grid>
        )}
      </Container>
    </div>
  );
};

export default Dashboard;
