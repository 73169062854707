import React, { useContext, useState, useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import { QRCode } from 'react-qrcode-logo';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { VariantContext } from '../../hooks/VariantContext';
import { UserContext } from '../../hooks/UserContext';
import { makeStyles } from '@material-ui/core/styles';
import {
  Typography,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Fade,
  FormControl,
  FormControlLabel,
  FormHelperText,
  FormGroup,
  InputLabel,
  Select,
  Button,
  TextField,
  Chip,
  Box,
  Container,
  Grid,
  MenuItem,
  MenuList,
  Paper,
  Checkbox,
  RadioGroup,
  Radio,
  InputAdornment,
  CircularProgress,
  Hidden,
} from '@material-ui/core';
import Alert from '@material-ui/lab/Alert';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import ChangeCircleIcon from '@material-ui/icons/Replay';

import FacebookIcon from '@material-ui/icons/Facebook';
import TwitterIcon from '@material-ui/icons/Twitter';
import InstagramIcon from '@material-ui/icons/Instagram';
//import TiktokIcon from '@material-ui/icons/Tiktok';
import YoutubeIcon from '@material-ui/icons/YouTube';

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
    marginTop: '50px',
    marginBottom: '50px',
  },
  accordionSummary: {
    padding: '30px',
  },
  column: {
    display: 'block',
  },
  profileMenu: {
    backgroundColor: '#f7f7f7',
  },
  profileContent: {
    padding: '15px',
  },
  changeInviteIdButton: {
    marginLeft: '20px',
  },
  familyStartAdornment: {
    '& .MuiInputAdornment-positionStart': {
      marginRight: 0,
    },
  },
}));

const Profile = (props) => {
  const classes = useStyles();

  const { hash } = useLocation();
  const defaultOpenMenu = hash ? hash.replace('#', '') : 'account_settings';

  const [loading, setLoading] = useState(false);
  const [rewards, setRewards] = useState({});
  const [influencerDataFilled, setInfluencerDataFilled] = useState(false);
  const [influencerBalance, setInfluencerBalance] = useState(0);
  const [usedFamilySlots, setUsedFamilySlots] = useState(0);
  const [openMenu, setOpenMenu] = useState(defaultOpenMenu);
  const [showOrder, setShowOrder] = useState(false);
  const [saved, setSaved] = useState({});

  const { appVariant } = useContext(VariantContext);
  const { user, setUser } = useContext(UserContext);
  const userId = user?.id;
  const familySubscription = user?.familySubscription;

  const params = {};
  const referrerId = user?.referrer || user?.id;
  if (referrerId) params.referrer = referrerId;

  const appRedirectUrl = `${process.env.REACT_APP_FIREBASE_APP_URL}`;

  const referrerParams = encodeURIComponent(JSON.stringify(params));
  const urlParams = encodeURIComponent(`https://app.getbloomup.com/login?referrer=${referrerParams}`);
  const linkParam = `&link=${urlParams}`;

  const fallbackUrl = `https://my.getbloomup.com/signup?referrer=${referrerId}`;
  const fallbackParams = `&afl=${fallbackUrl}&ifl=${fallbackUrl}&ofl=${fallbackUrl}`;

  const invitationUrl = `${appRedirectUrl}${linkParam}${fallbackParams}`;

  const familyId = user?.familyReferrer || `family-${user?.id}`;
  const encodedFamilyParam = encodeURIComponent(JSON.stringify({ referrerAppCode: familyId }));
  const familyUrl = `${process.env.REACT_APP_PLAYSTORE_APP_URL}&referrer=${encodedFamilyParam}`;

  const showSavedMessage = (area) => {
    setSaved({ [area]: true });
    setTimeout(() => {
      setSaved({ [area]: false });
    }, 1500);
  };

  const switchToMenu = (menuName) => {
    setShowOrder(false);
    setOpenMenu(menuName);
  };

  const displayOrder = (orderContents) => {
    setShowOrder(orderContents);
    setOpenMenu(null);
  };

  const [qrType, setQrType] = useState(1);

  const changeQrType = () => {
    if (qrType < 3) {
      setQrType(qrType + 1);
    } else {
      setQrType(1);
    }
  };

  const generateQrCode = (invitationUrl) => (
    <Box onClick={changeQrType}>
      {qrType === 1 && (
        <QRCode
          value={invitationUrl}
          size={220}
          logoImage="images/qr-logo.png"
          logoWidth={40}
          logoHeight={40}
          logoOpacity={0.75}
          fgColor="#82368c"
          qrStyle="dots"
          eyeRadius={3}
        />
      )}
      {qrType === 2 && (
        <QRCode
          value={invitationUrl}
          size={220}
          logoImage="logo192.png"
          logoWidth={106}
          logoHeight={106}
          logoOpacity={0.3}
          fgColor="#82368c"
          qrStyle="dots"
          eyeRadius={3}
        />
      )}
      {qrType === 3 && (
        <QRCode
          value={invitationUrl}
          size={220}
          logoImage="images/qr-logo.png"
          logoWidth={40}
          logoHeight={40}
          logoOpacity={0.9}
        />
      )}
    </Box>
  );

  const validationShapes = {
    account: Yup.object().shape({
      email: Yup.string('Enter your email').email('Enter a valid email').required('Email is required'),
      password: Yup.string('').required('Enter your password'),
      new_password: Yup.string('')
        .min(8, 'Password must contain at least 8 characters')
        .required('Enter a password')
        .matches(/[0-9]/g, 'The password must at least contain a number'),
      new_password_repeat: Yup.string()
        .required('Enter a password')
        .oneOf([Yup.ref('new_password'), null], 'Passwords must match'),
    }),
    address: Yup.object().shape({
      firstname: Yup.string().required('Enter your firstname'),
      lastname: Yup.string().required('Enter your lastname'),
      address: Yup.string().required('Enter your address'),
      zipcode: Yup.string().required('Enter your zipcode'),
      city: Yup.string().required('Enter your city'),
      country: Yup.string().required('Enter your country'),
    }),
    referrals: Yup.object().shape({
      registered_trade: Yup.boolean().oneOf([true], 'Du musst ein angemeldetes Gewerbe haben'),
      tax_id: Yup.string().required('Bitte gib deine Steuernummer oder Umsatzsteuer-ID ein'),
      tax_reducible: Yup.boolean().oneOf([true, false]).required('Bitte gib an, ob du vorsteuerabzugsberechtigt bist'),
      accepted_terms: Yup.boolean().oneOf([true], "Bitte bestätige, dass du die Influencer-AGB's gelesen hast"),
      communication_channels: Yup.array(Yup.string().oneOf(['verbal', 'socialmedia'])).required(
        'Bitte gib deine bevorzugten Kommunikationskanäle an'
      ),
      //channels_socialmedia: Yup.array(Yup.string()).when('communication_channels', { is: value => value.includes('socialmedia'), then: Yup.array(Yup.string()).required('Bitte gib an, welche Socialmedia Kanäle du nutzt') }),
      application_reason: Yup.string().required('Bitte gib an, warum du Bloomer-Influencer werden willst'),
      request_influencer_agreement: Yup.boolean().oneOf([true], 'Bitte bestätige deine Bewerbung'),
    }),
    bank: Yup.object().shape({
      account_owner: Yup.string().required('Bitte Kontoinhaber eingeben'),
      account_iban: Yup.string().required('Bitte IBAN eingeben').length(22, 'Die IBAN muss 22 Zeichen lang sein'),
      account_bic: Yup.string().required('Bitte BIC eingeben').length(11, 'Die BIC muss 11 Zeichen lang sein'),
    }),
  };

  let validationSchema;
  //const validationSchema = Yup.object().shape(validationShape);
  if (openMenu === 'account_settings') {
    validationSchema = validationShapes.account;
  }
  if (openMenu === 'address_settings') {
    validationSchema = validationShapes.address;
  }
  if (openMenu === 'referrals' && !user?.isInfluencer) {
    validationSchema = validationShapes.referrals;
  }
  if (openMenu === 'referrals' && user?.isInfluencer) {
    validationSchema = validationShapes.bank;
  }

  const { handleSubmit, handleChange, values, touched, errors } = useFormik(
    {
      initialValues: {
        username: user?.name ? user.name : '',
        email: user?.email ? user.email : '',
        password: '',
        new_password: '',
        new_password_repeat: '',
        firstname: user?.addresses?.billing?.firstname,
        lastname: user?.addresses?.billing?.lastname,
        address: user?.addresses?.billing?.address,
        zipcode: user?.addresses?.billing?.zipcode,
        city: user?.addresses?.billing?.city,
        country: user?.addresses?.billing?.country || 'de',
        referrer_id: user?.referrer,
        family_id: user?.familyReferrer?.replace('family-', ''),
        request_influencer_agreement: false,

        registered_trade: false,
        tax_reducible: '',
        tax_id: '',
        accepted_terms: false,
        communication_channels: '',
        channels_socialmedia: '',
        application_reason: '',

        account_owner: user?.influencerData?.account_owner,
        account_iban: user?.influencerData?.account_iban,
        account_bic: user?.influencerData?.account_bic,
      },
      enableReinitialize: true,
      validationSchema,
      onSubmit: (values, { setStatus, setErrors, setFieldError }) => {
        let payload;
        if (openMenu === 'account_settings') {
          payload = {
            email: values.email,
            password: values.password,
            newPassword: values.new_password,
          };
        }
        if (openMenu === 'address_settings') {
          payload = {
            addresses: {
              billing: {
                firstname: values.firstname,
                lastname: values.lastname,
                address: values.address,
                zipcode: values.zipcode,
                city: values.city,
                country: values.country,
              },
            },
          };
        }
        if (openMenu === 'referrals' && !user?.isInfluencer) {
          payload = {
            influencerData: {
              registered_trade: values.registered_trade,
              tax_reducible: values.tax_reducible === 'true',
              tax_id: values.tax_id,
              accepted_terms: values.accepted_terms,
              communication_channels: values.communication_channels,
              channels_socialmedia: values.channels_socialmedia,
              application_reason: values.application_reason,
            },
          };
        }
        if (openMenu === 'referrals' && user?.isInfluencer) {
          payload = {
            bankDetails: {
              account_owner: values.account_owner,
              account_iban: values.account_iban,
              account_bic: values.account_bic,
            },
          };
        }

        if (openMenu === 'referrals') {
          fetch(`${process.env.REACT_APP_API_URL}/users/${userId}/request-influencer-agreement`, {
            method: 'POST',
            body: JSON.stringify(payload),
            headers: {
              'Content-Type': 'application/json',
              Authorization: `Bearer ${localStorage.getItem('token')}`,
            },
          }).then((res) => {
            console.log('RES', res);
            if (res.status === 200) {
              res.json().then((data) => {
                showSavedMessage('bankDetails');
                setLoading(false);
                setInfluencerDataFilled(true);
              });
            } else {
              res.json().then((error) => {
                console.log('ERROR:', error);
                touched['request_influencer_agreement'] = true;
                errors['request_influencer_agreement'] = error.message;
                setLoading(false);
              });
            }
          });
        } else {
          fetch(`${process.env.REACT_APP_API_URL}/users/${userId}`, {
            method: 'PATCH',
            body: JSON.stringify(payload),
            headers: {
              'Content-Type': 'application/json',
              Authorization: `Bearer ${localStorage.getItem('token')}`,
            },
          }).then((res) => {
            if (res.status === 200) {
              res.json().then((data) => {
                showSavedMessage('address');
                // repopulate user context
                setUser(data);
              });
            } else {
              res.json().then((error) => {
                if (error.code === 401) {
                  setFieldError('password', error.message);
                } else {
                  setFieldError('api', error.message);
                }
              });
            }
          });
        }
      },
    },
    [user]
  );

  const handleResendVerificationClick = () => {
    console.log('RESEND VERIFICATION');

    const payload = {
      uuid: user.uuid,
      email: user.email,
      password: 'KEEPVALUE#1',
      forceResend: true,
    };

    fetch(`${process.env.REACT_APP_API_URL}/auth/register-complete`, {
      method: 'POST',
      body: JSON.stringify(payload),
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${localStorage.getItem('token')}`,
      },
    }).then((res) => {
      console.log('RES', res);
      if (res.status === 200) {
        res.json().then((data) => {
          console.log('DATA', data);
          showSavedMessage('register');
        });
      } else {
        res.json().then((error) => {
          console.log('ERROR:', error);
          touched['invite_email'] = true;
          errors['invite_email'] = error.message;
          console.log(errors, touched);
        });
      }
    });
  };

  /*const removeCreditCard = (event) => {
    console.log('REMOVE CREDIT CARD');
  };*/

  const handleInvite = (type = 'default', inputIndex = null) => {
    console.log(inputIndex, 'invite_email' + (inputIndex ? `-${inputIndex}` : ''));

    const payload = {
      invitation_recipient: values['invite_email' + (inputIndex ? `-${inputIndex}` : '')],
      invitation_url: type === 'default' ? invitationUrl : familyUrl,
      invitation_type: type,
    };

    console.log('SEND INVITE', payload);

    fetch(`${process.env.REACT_APP_API_URL}/users/invite`, {
      method: 'POST',
      body: JSON.stringify(payload),
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${localStorage.getItem('token')}`,
      },
    }).then((res) => {
      console.log('RES', res);
      if (res.status === 200) {
        res.json().then((data) => {
          console.log('DATA', data);
          showSavedMessage('invite');
        });
      } else {
        res.json().then((error) => {
          console.log('ERROR:', error);
          touched['invite_email' + (inputIndex ? `-${inputIndex}` : '')] = true;
          errors['invite_email' + (inputIndex ? `-${inputIndex}` : '')] = error.message;
          console.log(errors, touched);
        });
      }
    });
  };

  const handleChangeRefererId = (type = 'default') => {
    setLoading(true);

    const payload = {
      referrer: values.referrer_id,
      familyReferrer: `family-${values.family_id}`,
    };

    fetch(`${process.env.REACT_APP_API_URL}/users/${userId}`, {
      method: 'PATCH',
      body: JSON.stringify(payload),
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${localStorage.getItem('token')}`,
      },
    }).then((res) => {
      if (res.status === 200) {
        res.json().then((data) => {
          showSavedMessage('referrer');
          if (type === 'default') {
            user.referrer = data.referrer;
          } else {
            user.familyReferrer = data.familyReferrer;
          }

          setLoading(false);
        });
      } else {
        res.json().then((error) => {
          console.log('ERROR:', error);
          touched['referrer_id'] = true;
          errors['referrer_id'] = error.message;
          setLoading(false);
        });
      }
    });
  };

  const payoutCaluclationData = {
    purchaseBasePrice: 3.39,
  };

  const redeemTreshold = 0.5;

  const redeemInfluencerBalance = () => {
    console.log('PAY OUT BALANCE');
  };

  const exampleInvoices = [
    /*{
      date: '01.09.2021',
      number: 12012342,
      amount: 0.49,
      url: 'https://my.getbloomup.com/invoice/12012342',
    },
    {
      date: '01.10.2021',
      number: 12054732,
      amount: 0.49,
      url: 'https://my.getbloomup.com/invoice/12054732',
    },*/
  ];
  const [invoices, setInvoices] = useState(exampleInvoices);

  useEffect(() => {
    if (!userId) return;

    fetch(`${process.env.REACT_APP_API_URL}/users/${userId}/invoices`, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${localStorage.getItem('token')}`,
      },
    })
      .then((response) => response.json())
      .then((data) => {
        setInvoices(data.results);
      })
      .catch((error) => {
        console.error(error);
      });

    if (appVariant === 'ljs') return;

    fetch(`${process.env.REACT_APP_API_URL}/rewards`, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${localStorage.getItem('token')}`,
      },
    })
      .then((response) => response.json())
      .then((data) => {
        setRewards(data);
      })
      .catch((error) => {
        console.error(error);
      });

    fetch(`${process.env.REACT_APP_API_URL}/payments/referral-payback/${userId}`, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${localStorage.getItem('token')}`,
      },
    })
      .then((response) => response.json())
      .then((data) => {
        setInfluencerBalance(data.balance);
      })
      .catch((error) => {
        console.error(error);
      });
  }, [userId, appVariant, user]);

  useEffect(() => {
    if (!familySubscription) return;
    fetch(`${process.env.REACT_APP_API_URL}/users/referred-with/${user.familyReferrer}`, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${localStorage.getItem('token')}`,
      },
    })
      .then((response) => response.json())
      .then((data) => {
        if (data.count) {
          setUsedFamilySlots(data.referredUsers);
        }
      })
      .catch((error) => {
        console.error(error);
      });
  }, [user]);

  const familyMemberInputRow = (index, label) => {
    if (index < usedFamilySlots.length) {
      return (
        <>
          <Grid item xs={3} key={`family-member-row-${index}`}>
            <Typography>
              <nobr>{label}</nobr>
            </Typography>
          </Grid>
          <Grid item xs={9}>
            <Typography>
              {usedFamilySlots[index]?.name}&nbsp;
              <Chip
                icon={<CheckCircleIcon color="primary" fontSize="medium" />}
                variant="outlined"
                color="inactive"
                label="angenommen"
              />
            </Typography>
          </Grid>
        </>
      );
    }
    return (
      <>
        <Grid item xs={3}>
          <Typography>
            <nobr>{label}</nobr>
          </Typography>
        </Grid>
        <Grid item xs={6}>
          <TextField
            variant="outlined"
            size="small"
            required
            fullWidth
            id={`invite_email-${index + 1}`}
            name={`invite_email-${index + 1}`}
            autoComplete={`invite_email-${index + 1}`}
            autoFocus
            value={values[`invite_email-${index + 1}`]}
            onChange={handleChange}
            error={touched[`invite_email-${index + 1}`] && Boolean(errors[`invite_email-${index + 1}`])}
            helperText={touched[`invite_email-${index + 1}`] && errors[`invite_email-${index + 1}`]}
          />
        </Grid>
        <Grid item xs={3}>
          <Button
            disabled={!values[`invite_email-${index + 1}`]}
            onClick={() => handleInvite('family', index + 1)}
            size="medium"
            variant="contained"
            color="primary"
          >
            Einladung versenden
          </Button>
        </Grid>
      </>
    );
  };

  return (
    <div className={classes.root}>
      <Container maxWidth="lg">
        <Grid container spacing={2} justifyContent="center" style={{ minHeight: '75vh' }}>
          <Grid item md={4} xs={12} style={{ marginBottom: '20px' }}>
            <Box m="2">
              <Paper elevation={5} className={classes.profileMenu}>
                <MenuList>
                  <MenuItem onClick={() => switchToMenu('account_settings')}>Accounteinstellungen</MenuItem>
                  {/*<MenuItem onClick={() => switchToMenu('payment_settings')}>Zahlungsdaten</MenuItem>*/}
                  <MenuItem onClick={() => switchToMenu('address_settings')}>Beleg- und Lieferadresse</MenuItem>
                  <MenuItem onClick={() => switchToMenu('invoices')}>Belege</MenuItem>
                  {/*<MenuItem onClick={() => switchToMenu('posthum_code')}>Posthum-Code</MenuItem>*/}
                  {appVariant === 'bloomup' && (
                    <MenuItem onClick={() => switchToMenu('referrals')}>Ich bin Bloomer-Influencer</MenuItem>
                  )}
                  {familySubscription && <MenuItem onClick={() => switchToMenu('family')}>Mein Family-Tarif</MenuItem>}
                </MenuList>
              </Paper>
            </Box>
          </Grid>
          <Grid item md={8} xs={12}>
            <Box hidden={openMenu !== 'account_settings'}>
              <Paper elevation={5} className={classes.profileContent}>
                <Grid item xs={12}>
                  <Typography variant="h6">{'Persönliche Daten'}</Typography>
                </Grid>
                <form onSubmit={handleSubmit} className={classes.form} noValidate>
                  <Grid container spacing={3}>
                    <Grid item md={6} xs={12}>
                      <TextField
                        variant="filled"
                        margin="normal"
                        fullWidth
                        id="username"
                        label="Benutzername"
                        name="username"
                        disabled
                        value={values.username}
                      />
                    </Grid>
                    <Hidden xsDown>
                      <Grid item xs={6}>
                        &nbsp;
                      </Grid>
                    </Hidden>
                    <Grid item md={6} xs={12}>
                      <TextField
                        variant="filled"
                        margin="normal"
                        fullWidth
                        id="email"
                        label="Email Addresse"
                        name="email"
                        disabled
                        value={values.email}
                      />
                    </Grid>
                    <Grid item md={6} xs={12}>
                      {!user?.isEmailVerified && (
                        <Box m={3.3}>
                          <Chip
                            icon={<ChangeCircleIcon color="primary" fontSize="medium" />}
                            style={{ width: '100%' }}
                            variant="outlined"
                            color="primary"
                            label="Verifizierungsmail erneut anfordern"
                            onClick={handleResendVerificationClick}
                          />
                        </Box>
                      )}
                      {user?.isEmailVerified && (
                        <Box m={3.3}>
                          <Chip
                            icon={<CheckCircleIcon color="primary" fontSize="medium" />}
                            variant="outlined"
                            color="primary"
                            label="Verifiziert"
                          />
                        </Box>
                      )}
                    </Grid>
                    <Grid item xs={12}>
                      <Typography variant="h6">{'Password'}</Typography>
                    </Grid>
                    <Grid item md={6} xs={12}>
                      <TextField
                        variant="outlined"
                        margin="normal"
                        required
                        fullWidth
                        name="password"
                        label="Aktuelles Password"
                        type="password"
                        id="password"
                        autoComplete="current-password"
                        value={values.password}
                        onChange={handleChange}
                        error={touched.password && Boolean(errors.password)}
                        helperText={touched.password && errors.password}
                      />
                    </Grid>
                    <Hidden xsDown>
                      <Grid item xs={6}>
                        &nbsp;
                      </Grid>
                    </Hidden>
                    <Grid item md={6} xs={12}>
                      <TextField
                        variant="outlined"
                        margin="normal"
                        required
                        fullWidth
                        name="new_password"
                        label="Neues Password"
                        type="password"
                        id="new_password"
                        autoComplete="new-password"
                        value={values.new_password}
                        onChange={handleChange}
                        error={touched.new_password && Boolean(errors.new_password)}
                        helperText={touched.new_password && errors.new_password}
                      />
                    </Grid>
                    <Grid item md={6} xs={12}>
                      <TextField
                        variant="outlined"
                        margin="normal"
                        required
                        fullWidth
                        name="new_password_repeat"
                        label="Neues Password wiederholen"
                        type="password"
                        id="new_password_repeat"
                        autoComplete="new-password-repeat"
                        value={values.new_password_repeat}
                        onChange={handleChange}
                        error={touched.new_password_repeat && Boolean(errors.new_password_repeat)}
                        helperText={touched.new_password_repeat && errors.new_password_repeat}
                      />
                    </Grid>

                    <Grid item md={10} xs={8}>
                      <Fade in={saved.address} timeout={{ appear: 500, exit: 1000 }}>
                        <Alert float="left">Gespeichert</Alert>
                      </Fade>
                      {!!errors.api && <Alert severity="error">{errors.api}</Alert>}
                    </Grid>
                    <Grid item md={2} xs={4}>
                      <Button onClick={handleSubmit} fullWidth size="large" variant="contained" color="primary">
                        Speichern
                      </Button>
                    </Grid>
                  </Grid>
                </form>
              </Paper>
            </Box>

            {/*<Box hidden={openMenu !== 'payment_settings'}>
              <Paper elevation={5} className={classes.profileContent}>
                <Grid container spacing={3}>
                  <Grid item xs={12}>
                    <Typography variant="h5">{'Zahlungsdaten'}</Typography>
                    <Typography>
                      {'Die benötigen wir für die Zahlungsabwicklung der bloomUp Nutzungsgebühr, sobald Du Bloomer wirst.'}
                    </Typography>
                  </Grid>
                  <Grid item xs={12}>
                    <Typography variant="h5">{'Hinterlegte Zahlungsdaten'}</Typography>
                    <Typography>
                      {'Sicherheitshinweis: Es werden KEINE ZAHLUNGSINFORMATIONEN auf unserem System gespeichert.'}
                    </Typography>
                  </Grid>
                  <Grid item xs={12}>
                    {values.has_credit_card_number && (
                      <TextField
                        variant="outlined"
                        margin="normal"
                        required
                        fullWidth
                        name="credit_card_number"
                        label="Kreditkartennummer"
                        id="credit_card_number"
                        autoComplete="credit-card-number"
                        value={values.credit_card_number}
                        onChange={handleChange}
                        error={touched.credit_card_number && Boolean(errors.credit_card_number)}
                        helperText={touched.credit_card_number && errors.credit_card_number}
                      />
                    )}
                  </Grid>
                  <Grid item xs={4}>
                    <Typography>
                      <b>{'Kreditkarte'}</b>
                      <br />
                      <small>Endet auf *** </small>
                    </Typography>
                  </Grid>
                  <Grid item xs={4}>
                    {!values.credit_card_number && (
                      <Button onClick={removeCreditCard} size="large" variant="contained" color="secondary">
                        Entfernen
                      </Button>
                    )}
                  </Grid>
                </Grid>

                <Box align="right">
                  <Fade in={saved} timeout={{ appear: 500, exit: 1000 }}>
                    <Alert float="left">Gespeichert</Alert>
                  </Fade>
                  {!!errors.api && <Alert severity="error">{errors.api}</Alert>}
                  <Button onClick={handleSubmit} disabled={true} size="small" variant="contained" color="primary">
                    Speichern
                  </Button>
                </Box>
              </Paper>
            </Box>*/}

            <Box hidden={openMenu !== 'address_settings'}>
              <Paper elevation={5} className={classes.profileContent}>
                <Grid container spacing={3}>
                  <Grid item xs={12}>
                    <Typography variant="h6">{'Belegadresse'}</Typography>
                  </Grid>

                  <Grid item xs={12}>
                    <form onSubmit={handleSubmit} className={classes.form} noValidate>
                      <Grid container spacing={3}>
                        <Grid item xs={6}>
                          <TextField
                            variant="outlined"
                            margin="normal"
                            required
                            fullWidth
                            id="firstname"
                            label="Vorname"
                            name="firstname"
                            autoComplete="firstname"
                            autoFocus
                            value={values.firstname}
                            onChange={handleChange}
                            error={touched.firstname && Boolean(errors.firstname)}
                            helperText={touched.firstname && errors.firstname}
                          />
                        </Grid>
                        <Grid item xs={6}>
                          <TextField
                            variant="outlined"
                            margin="normal"
                            required
                            fullWidth
                            id="lastname"
                            label="Nachname"
                            name="lastname"
                            autoComplete="lastname"
                            value={values.lastname}
                            onChange={handleChange}
                            error={touched.lastname && Boolean(errors.lastname)}
                            helperText={touched.lastname && errors.lastname}
                          />
                        </Grid>

                        <Grid item xs={12}>
                          <TextField
                            variant="outlined"
                            margin="normal"
                            required
                            fullWidth
                            id="adress"
                            label="Adresse"
                            name="address"
                            autoComplete="address"
                            value={values.address}
                            onChange={handleChange}
                            error={touched.address && Boolean(errors.address)}
                            helperText={touched.address && errors.address}
                          />
                        </Grid>

                        <Grid item xs={6}>
                          <TextField
                            variant="outlined"
                            margin="normal"
                            required
                            fullWidth
                            id="zipcode"
                            label="Postleitzahl"
                            name="zipcode"
                            autoComplete="zipcode"
                            value={values.zipcode}
                            onChange={handleChange}
                            error={touched.zipcode && Boolean(errors.zipcode)}
                            helperText={touched.zipcode && errors.zipcode}
                          />
                        </Grid>
                        <Grid item xs={6}>
                          <TextField
                            variant="outlined"
                            margin="normal"
                            required
                            fullWidth
                            id="city"
                            label="Stadt"
                            name="city"
                            autoComplete="city"
                            value={values.city}
                            onChange={handleChange}
                            error={touched.city && Boolean(errors.city)}
                            helperText={touched.city && errors.city}
                          />
                        </Grid>

                        <Grid item md={6} xs={12}>
                          <FormControl fullWidth variant="outlined" margin="normal">
                            <InputLabel id="country-select-label">Land</InputLabel>
                            <Select
                              labelId="country-select-label"
                              id="country-select"
                              value={values.country}
                              label="Land"
                              onChange={handleChange}
                              error={touched.country && Boolean(errors.country)}
                            >
                              <MenuItem value="de">Deutschland</MenuItem>
                              <MenuItem value="at">Österreich</MenuItem>
                              <MenuItem value="ch">Schweiz</MenuItem>
                            </Select>
                            {touched.country && errors.country && (
                              <FormHelperText htmlFor="render-select" error>
                                {errors.country}
                              </FormHelperText>
                            )}
                          </FormControl>
                        </Grid>

                        <Grid item xs={12}>
                          <Typography variant="h6">{'Lieferadresse'}</Typography>
                          <FormGroup>
                            <FormControlLabel
                              control={<Checkbox name="shipping_same_as_invoice_address" defaultChecked />}
                              label="Lieferadresse enspricht der Belegadresse"
                            />
                          </FormGroup>
                        </Grid>

                        <Grid item md={10} xs={8}>
                          <Fade in={saved.address} timeout={{ appear: 500, exit: 1000 }}>
                            <Alert float="left">Gespeichert</Alert>
                          </Fade>
                          {!!errors.api && <Alert severity="error">{errors.api}</Alert>}
                        </Grid>
                        <Grid item md={2} xs={4}>
                          <Button
                            onClick={handleSubmit}
                            disabled={!touched}
                            fullWidth
                            size="large"
                            variant="contained"
                            color="primary"
                          >
                            Speichern
                          </Button>
                        </Grid>
                      </Grid>
                    </form>
                  </Grid>
                </Grid>
              </Paper>
            </Box>

            <Box hidden={openMenu !== 'invoices'}>
              <Paper elevation={5} className={classes.profileContent}>
                <Grid container spacing={3}>
                  <Grid item xs={12}>
                    <Typography variant="h6">{'Belegübersicht'}</Typography>
                  </Grid>

                  <Grid item xs={12}>
                    <TableContainer>
                      <Table size="small" aria-label="invoices">
                        <TableHead>
                          <TableRow>
                            <TableCell>Datum</TableCell>
                            <TableCell>Typ</TableCell>
                            <TableCell>Belegnummer</TableCell>
                            <TableCell>Bestellung</TableCell>
                            <TableCell>Beleg</TableCell>
                            <TableCell>Download-Link</TableCell>
                          </TableRow>
                        </TableHead>
                        <TableBody>
                          {invoices?.map((invoice) => (
                            <TableRow key={invoice.number} sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                              <TableCell component="th" scope="row">
                                {new Intl.DateTimeFormat('de-DE', {
                                  year: 'numeric',
                                  month: '2-digit',
                                  day: '2-digit',
                                }).format(new Date(invoice.date))}
                              </TableCell>
                              <TableCell>{invoice.type}</TableCell>
                              <TableCell>{invoice.order}</TableCell>
                              <TableCell>
                                <a href="#" onClick={() => displayOrder(invoice.overview)}>
                                  anzeigen
                                </a>
                              </TableCell>
                              <TableCell>
                                <a href={invoice.invoiceUrl}>Download</a>
                              </TableCell>
                              <TableCell>{invoice.downloadUrl && <a href={invoice.downloadUrl}>Download</a>}</TableCell>
                            </TableRow>
                          ))}
                        </TableBody>
                      </Table>
                    </TableContainer>
                    {!invoices?.length && (
                      <Box justifyContent="center" display="flex" m={5}>
                        <Typography variant="body2">Noch keine Belege vorhanden.</Typography>
                      </Box>
                    )}
                  </Grid>

                  <Grid item xs={12}>
                    &nbsp;
                  </Grid>
                </Grid>
              </Paper>
            </Box>

            <Box hidden={!showOrder}>
              <Paper elevation={5} className={classes.profileContent}>
                <Grid container spacing={3}>
                  <Grid item xs={12}>
                    <Typography variant="h6">{`Bestellung ${showOrder.orderId}`}</Typography>
                  </Grid>

                  <Grid item xs={12}>
                    <TableContainer>
                      <Table size="small" aria-label="invoices">
                        <TableHead>
                          <TableRow>
                            <TableCell>Produkt</TableCell>
                            <TableCell>Preis</TableCell>
                            <TableCell>Versandkosten</TableCell>
                          </TableRow>
                        </TableHead>
                        <TableBody>
                          {showOrder.cartContents?.[0].format &&
                            showOrder.cartContents?.map((product) => (
                              <TableRow key={showOrder.id} sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                                <TableCell component="th" scope="row">
                                  <Typography variant="body2">
                                    {product.quantity} x Lebensbuch ({product.output} {product.format})
                                  </Typography>
                                  <Typography variant="caption">
                                    {decodeURIComponent(product.title)} ({product.pages} Seiten)
                                  </Typography>
                                </TableCell>
                                <TableCell>
                                  {new Intl.NumberFormat('de-DE', { style: 'currency', currency: 'EUR' }).format(
                                    showOrder.amount
                                  )}
                                </TableCell>
                                <TableCell>
                                  {new Intl.NumberFormat('de-DE', { style: 'currency', currency: 'EUR' }).format(
                                    showOrder.shipping
                                  )}
                                </TableCell>
                              </TableRow>
                            ))}
                          {/* TODO: generalize from cart system */}
                          {!showOrder.cartContents?.[0].format &&
                            showOrder.cartContents?.map((product) => (
                              <TableRow key={showOrder.id} sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                                <TableCell component="th" scope="row">
                                  {console.log(showOrder)}
                                  <Typography variant="body2">
                                    {product.quantity} x {product.title}
                                  </Typography>
                                </TableCell>
                                <TableCell>
                                  {new Intl.NumberFormat('de-DE', { style: 'currency', currency: 'EUR' }).format(
                                    product.quantity * product.price
                                  )}
                                </TableCell>
                                <TableCell>&nbsp;</TableCell>
                              </TableRow>
                            ))}

                          {!showOrder.cartContents?.[0].format && (
                            <TableRow key={showOrder.id} sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                              <TableCell component="th" scope="row">
                                <Typography variant="body2">&nbsp;</Typography>
                              </TableCell>
                              <TableCell>&nbsp;</TableCell>
                              <TableCell>
                                {new Intl.NumberFormat('de-DE', { style: 'currency', currency: 'EUR' }).format(
                                  showOrder.shipping
                                )}
                              </TableCell>
                            </TableRow>
                          )}
                        </TableBody>
                      </Table>
                    </TableContainer>
                  </Grid>

                  <Grid item xs={12}>
                    <a href="#" onClick={() => switchToMenu('invoices')}>
                      zurück
                    </a>
                  </Grid>
                </Grid>
              </Paper>
            </Box>

            {/*<Box hidden={openMenu !== 'posthum_code'}>
              <Paper elevation={5} className={classes.profileContent}>
                <Grid container spacing={3}>
                  <Grid item xs={12}>
                    <Typography variant="h6">{'Mein Posthum-Code'}</Typography>
                  </Grid>

                  <Grid item xs={12}>
                    <Typography>
                      {
                        'Den Posthum-Code gebe ich meinen liebsten und wichtigsten Personen. So können sie mittels der mit dem Vogel markierten Einträge meine Gedanken und Erlebnisse erfahren und sich an die gemeinsame Zeit unter Hinzunahme meiner Sichtweise erinnern.'
                      }
                    </Typography>
                    <br />
                    <Typography>
                      {
                        'Die gekennzeichneten Einträge werden regelmäßig - natürlich nur mit Deiner Einwilligung - auf unsere Server übertragen und gespeichert. Daher wird Dein Smartphone nicht benötigt. Über die Login-Seite wird der Posthum-Code eingegeben und schon sind die Einträge verfügbar.'
                      }
                    </Typography>
                  </Grid>

                  <Grid item xs={8}>
                    <Typography>
                      <b>{'Dein persönlicher Posthum-Code'}</b>
                    </Typography>
                    <Typography>
                      <small>Verwende diesen um private Informationen zu teilen</small>
                    </Typography>
                  </Grid>
                  <Grid item xs={4}>
                    <Typography>
                      <b>{'Love1234'}</b>
                    </Typography>
                  </Grid>
                </Grid>
              </Paper>
            </Box>*/}

            {appVariant === 'bloomup' && (
              <Box hidden={openMenu !== 'referrals'}>
                <Paper elevation={5} className={classes.profileContent}>
                  <Grid container spacing={3}>
                    <Grid item xs={12}>
                      <Typography variant="h6">{'So empfehle ich bloomUp weiter'}</Typography>
                    </Grid>

                    <Grid item xs={12}>
                      <Typography>
                        {
                          'Mit dem QR-Code oder dem Ref-Link lade ich Freunde, Bekannte oder Partner ein, gemeinsam zu bloomen oder selber ein Bloomer zu werden.'
                        }
                      </Typography>
                      <br />
                      <Typography>
                        {
                          'Das Beste für uns ist dabei, sobald bloomUp von meinem Gast genutzt wird, erhalten wir beide die Berechtigung, den exklusiven bloomUp-Friends-Sondertarif von nur 3,89 €/Monat im Jahresabo (46,68 € p.a.) zu nutzen ... für immer!'
                        }
                      </Typography>
                    </Grid>

                    <Grid item md={8} xs={12}>
                      <Typography>
                        <b>{'Mein persönlicher Referer-Link:'}</b>
                      </Typography>
                      <br />
                      <Typography variant="body2" style={{ wordWrap: 'anywhere' }}>
                        <a href={invitationUrl}>{invitationUrl}</a>
                      </Typography>
                      <br />

                      <Typography>
                        {'Hier lege ich meinen persönlichen Empfehlungs-/Influencercode (Referrer-ID) fest:'}
                      </Typography>
                      <nobr>
                        <TextField
                          variant="outlined"
                          size="small"
                          required
                          id="referrer_id"
                          name="referrer_id"
                          autoComplete="referrer_id"
                          autoFocus
                          disabled={!!user?.referrer}
                          value={values.referrer_id}
                          onChange={handleChange}
                          error={touched.referrer_id && Boolean(errors.referrer_id)}
                          helperText={touched.referrer_id && errors.referrer_id}
                        />
                        <Button
                          disabled={!values.referrer_id || !!user?.referrer}
                          onClick={() => {
                            window.confirm('Die Referrer-ID kann danach nicht mehr geändert werden. Fortfahren?') &&
                              handleChangeRefererId();
                          }}
                          className={classes.changeInviteIdButton}
                          size="medium"
                          variant="contained"
                          color="primary"
                        >
                          ändern
                        </Button>
                      </nobr>
                    </Grid>

                    <Grid item md={4} xs={12}>
                      {loading && (
                        <Box m={8}>
                          <CircularProgress color="primary" />
                        </Box>
                      )}
                      {!loading && generateQrCode(invitationUrl)}
                    </Grid>

                    <Grid item xs={12}>
                      <Typography>
                        <b>
                          {'Freunde einladen zu bloomUp my life'}
                          <sup>
                            <small>®</small>
                          </sup>
                        </b>
                      </Typography>
                      <Typography>
                        {'Hier trage ich die Emailadressen ein, von den Menschen die ich gerne einladen möchte.'}
                        <br />
                        {'bloomUp versendet dann die E-Mail für mich.'}
                      </Typography>
                    </Grid>

                    <Grid item xs={8}>
                      <TextField
                        variant="outlined"
                        size="small"
                        required
                        fullWidth
                        id="invite_email"
                        name="invite_email"
                        autoComplete="invite_email"
                        autoFocus
                        value={values.invite_email}
                        onChange={handleChange}
                        error={touched.invite_email && Boolean(errors.invite_email)}
                        helperText={touched.invite_email && errors.invite_email}
                      />
                    </Grid>
                    <Grid item xs={2}>
                      <Button
                        disabled={!values.invite_email}
                        onClick={() => handleInvite()}
                        size="medium"
                        variant="contained"
                        color="primary"
                      >
                        einladen
                      </Button>
                    </Grid>
                    <Grid item md={2} xs={12}>
                      <Fade in={saved.invite} timeout={{ appear: 500, exit: 1000 }}>
                        <Alert float="left">Versendet</Alert>
                      </Fade>
                    </Grid>
                    {!influencerDataFilled && !user?.influencerData && !user?.isInfluencer && (
                      <Grid item xs={12}>
                        <Typography>
                          <b>JA, ich möchte professioneller Bloomer-Influencer werden!</b>
                          <br />
                          Als aktiver Bloomer-Influencer trage ich dazu bei, dass die Bloomer-Community immer größer wird.
                          Als Dankeschön bekomme ich dafür monatlich ein bloomUp-Dankeschön auf mein Konto gutgeschrieben.
                          Hierbei erhalte ich als Dankeschön bis zu 0,40 € je des monatlichen Bloomer-Abo Betrag der von mir
                          direkt begeisterten Bloomer...und das <b>Monat für Monat !</b>
                        </Typography>

                        <br />

                        <Typography>
                          Um Influencer zu werden bitte Deinen Namen und Adresse{' '}
                          <a href="#" onClick={() => switchToMenu('address_settings')}>
                            eingehen
                          </a>{' '}
                          (NUR wenn diese noch nicht im System hinterlegt sind)
                        </Typography>

                        <FormControlLabel
                          control={
                            <div style={{ alignSelf: 'start' }}>
                              <Checkbox
                                name="registered_trade"
                                checked={values.registered_trade}
                                required
                                onChange={handleChange}
                              />
                            </div>
                          }
                          label={
                            <Typography variant="caption">
                              Ich habe ein angemeldetes Gewerbe / ich bin Freiberufler. Meine Steuernummer/Umsatzsteuer-ID
                              lautet:
                            </Typography>
                          }
                        />
                        {touched.registered_trade && errors.registered_trade && (
                          <FormHelperText htmlFor="registered_trade" error>
                            {errors.registered_trade}
                          </FormHelperText>
                        )}

                        <Box p={4} pt={0} pb={1} hidden={!values.registered_trade}>
                          <TextField
                            variant="outlined"
                            size="small"
                            id="tax_id"
                            name="tax_id"
                            autoComplete="tax_id"
                            autoFocus
                            value={values.tax_id}
                            onChange={handleChange}
                            disabled={!values.registered_trade}
                            error={touched.tax_id && Boolean(errors.tax_id)}
                            helperText={touched.tax_id && errors.tax_id}
                          />
                        </Box>

                        <FormControl component="fieldset">
                          <RadioGroup
                            row
                            aria-label="tax_reducible"
                            name="tax_reducible"
                            value={values.tax_reducible}
                            onChange={handleChange}
                          >
                            <FormControlLabel
                              value={true}
                              checked={values.tax_reducible == true}
                              control={<Radio checked={values.tax_reducible === 'true'} />}
                              label={<small>Ich habe ein regelbesteuertes Unternehmen (vorsteuerabzugsberechtigt)</small>}
                            />

                            <FormControlLabel
                              value={false}
                              checked={values.tax_reducible == false}
                              control={<Radio checked={values.tax_reducible === 'false'} />}
                              label={<small>Ich bin Kleinunternehmer (ohne Vorsteuerabzugsberechtigung)</small>}
                            />
                          </RadioGroup>
                          {touched.tax_reducible && errors.tax_reducible && (
                            <FormHelperText htmlFor="tax_reducible" error>
                              {errors.tax_reducible}
                            </FormHelperText>
                          )}
                        </FormControl>

                        <FormControlLabel
                          control={
                            <div style={{ alignSelf: 'start' }}>
                              <Checkbox name="accepted_terms" checked={values.accepted_terms} onChange={handleChange} />
                            </div>
                          }
                          label={
                            <Typography variant="caption">
                              Ich habe die{' '}
                              <a href="#" onClick={() => switchToMenu('influencer_terms')}>
                                Influencer-AGB's
                              </a>{' '}
                              gelesen und bestätige sie hiermit.
                            </Typography>
                          }
                        />
                        {touched.accepted_terms && errors.accepted_terms && (
                          <FormHelperText htmlFor="accepted_terms" error>
                            {errors.accepted_terms}
                          </FormHelperText>
                        )}

                        <Typography>Wie kommuniziere ich mit meinem Umfeld</Typography>

                        <FormControlLabel
                          control={
                            <div style={{ alignSelf: 'start' }}>
                              <Checkbox
                                name="communication_channels"
                                value="verbal"
                                checked={values.communication_channels.includes('verbal')}
                                onChange={handleChange}
                              />
                            </div>
                          }
                          label={<Typography variant="caption">Klassisch, also ich rede (viel) mit Menschen</Typography>}
                        />

                        <br />

                        <FormControlLabel
                          control={
                            <div style={{ alignSelf: 'start' }}>
                              <Checkbox
                                name="communication_channels"
                                value="socialmedia"
                                checked={values.communication_channels.includes('socialmedia')}
                                onChange={handleChange}
                              />
                            </div>
                          }
                          label={<Typography variant="caption">Social media, diese Kanäle bevorzuge ich:</Typography>}
                        />
                        {touched.communication_channels && errors.communication_channels && (
                          <FormHelperText htmlFor="communication_channels" error>
                            {errors.communication_channels}
                          </FormHelperText>
                        )}

                        <Box p={4} pt={0} pb={1}>
                          <FormControlLabel
                            control={
                              <Checkbox
                                name="channels_socialmedia"
                                value="facebook"
                                checked={values.channels_socialmedia.includes('facebook')}
                                onChange={handleChange}
                              />
                            }
                            disabled={!values.communication_channels.includes('socialmedia')}
                            label={<FacebookIcon style={{ marginTop: '6px' }} />}
                          />
                          <FormControlLabel
                            control={
                              <Checkbox
                                name="channels_socialmedia"
                                value="twitter"
                                checked={values.channels_socialmedia.includes('twitter')}
                                onChange={handleChange}
                              />
                            }
                            disabled={!values.communication_channels.includes('socialmedia')}
                            label={<TwitterIcon style={{ marginTop: '6px' }} />}
                          />
                          <FormControlLabel
                            control={
                              <Checkbox
                                name="channels_socialmedia"
                                value="instagram"
                                checked={values.channels_socialmedia.includes('instagram')}
                                onChange={handleChange}
                              />
                            }
                            disabled={!values.communication_channels.includes('socialmedia')}
                            label={<InstagramIcon style={{ marginTop: '6px' }} />}
                          />
                          <FormControlLabel
                            control={
                              <Checkbox
                                name="channels_socialmedia"
                                value="tiktok"
                                checked={values.channels_socialmedia.includes('tiktok')}
                                onChange={handleChange}
                              />
                            }
                            disabled={!values.communication_channels.includes('socialmedia')}
                            label={
                              <img
                                src="images/social/icons8-tiktok.svg"
                                style={
                                  !values.communication_channels.includes('socialmedia')
                                    ? { marginTop: '6px', filter: 'invert(0.6)' }
                                    : { marginTop: '6px' }
                                }
                              />
                            }
                          />
                          <FormControlLabel
                            control={
                              <Checkbox
                                name="channels_socialmedia"
                                value="youtube"
                                checked={values.channels_socialmedia.includes('youtube')}
                                onChange={handleChange}
                              />
                            }
                            disabled={!values.communication_channels.includes('socialmedia')}
                            label={<YoutubeIcon style={{ marginTop: '6px' }} />}
                          />
                        </Box>
                        {touched.channels_socialmedia && errors.channels_socialmedia && (
                          <FormHelperText htmlFor="channels_socialmedia" error>
                            {errors.channels_socialmedia}
                          </FormHelperText>
                        )}

                        <Typography>
                          <small>Darum will ich Bloomer Influencer werden:</small>
                        </Typography>

                        <TextField
                          variant="outlined"
                          size="small"
                          required
                          fullWidth
                          id="application_reason"
                          name="application_reason"
                          autoComplete="application_reason"
                          autoFocus
                          value={values.application_reason}
                          onChange={handleChange}
                          error={touched.application_reason && Boolean(errors.application_reason)}
                          helperText={touched.application_reason && errors.application_reason}
                        />

                        <br />
                        <br />

                        <FormControlLabel
                          control={
                            <div style={{ alignSelf: 'start' }}>
                              <Checkbox
                                name="request_influencer_agreement"
                                checked={values.request_influencer_agreement}
                                onChange={handleChange}
                              />
                            </div>
                          }
                          label={
                            <Typography variant="caption">
                              <b>Hiermit bewerbe ich mich als Bloomer-Influencer.</b> Nach erfolgreicher Prüfung aller Daten
                              und Eignung kann ich als Bloomer-Influencer freigeschaltet werden.
                            </Typography>
                          }
                        />
                        {touched.request_influencer_agreement && errors.request_influencer_agreement && (
                          <FormHelperText htmlFor="request_influencer_agreement" error>
                            {errors.request_influencer_agreement}
                          </FormHelperText>
                        )}

                        <br />
                        <br />

                        <Button onClick={handleSubmit} size="small" variant="contained" color="primary">
                          Jetzt anfordern
                        </Button>
                      </Grid>
                    )}

                    <Grid item xs={12}>
                      <Typography>
                        In Summe habe ich <b>{rewards?.referrerDownloaded}</b> Empfehlungen herausgegeben und davon sind{' '}
                        <b>{rewards?.referrerSubscribed}</b> dauerhafte Bloomer in meiner Community.
                      </Typography>
                    </Grid>

                    {(influencerDataFilled || user?.influencerData) && !user?.isInfluencer && (
                      <Grid item xs={12}>
                        <Typography>Deine Bewerbung als Bloomer Influencer wird momentan geprüft.</Typography>
                      </Grid>
                    )}

                    {user?.isInfluencer && (
                      <Grid item xs={12}>
                        {!user?.isSuperInfluencer && (
                          <Box style={{ overflowX: 'auto' }}>
                            <Table>
                              <TableRow>
                                <TableCell variant="head">Anzahl integrierter Bloomer</TableCell>
                                <TableCell>
                                  <nobr>1 - 50</nobr>
                                </TableCell>
                                <TableCell>
                                  <nobr>51 - 99</nobr>
                                </TableCell>
                                <TableCell>
                                  <nobr>100 - 149</nobr>
                                </TableCell>
                                <TableCell>
                                  <nobr>150 - ...</nobr>
                                </TableCell>
                              </TableRow>
                              <TableRow>
                                <TableCell variant="head">Dankeschön je zahlungspflichtiger Bloomer</TableCell>
                                <TableCell>
                                  <nobr>0,25 €</nobr>
                                </TableCell>
                                <TableCell>
                                  <nobr>0,33 €</nobr>
                                </TableCell>
                                <TableCell>
                                  <nobr>0,37 €</nobr>
                                </TableCell>
                                <TableCell>
                                  <nobr>0,40 €</nobr>
                                </TableCell>
                              </TableRow>
                            </Table>
                          </Box>
                        )}

                        {user?.isSuperInfluencer && (
                          <Grid container component="dl">
                            <Grid item xs={12}>
                              <Typography component="dt" style={{ width: '70%', float: 'left' }}>
                                <b>Der Super-Influencer-Tarif für mein Bloomer-Netzwerk*</b>
                              </Typography>
                              <Typography component="dd" style={{ width: '30%', float: 'left' }}>
                                <b>
                                  {new Intl.NumberFormat('de-DE', { style: 'currency', currency: 'EUR' }).format(
                                    user.superInfluencerRate
                                  )}
                                </b>
                              </Typography>
                            </Grid>
                            <Grid item xs={12}>
                              <Typography component="dt" style={{ width: '70%', float: 'left' }}>
                                <b>Mein Super-Influencer-EK je Bloomer*</b>
                              </Typography>
                              <Typography component="dd" style={{ width: '30%', float: 'left' }}>
                                <b>
                                  <u>
                                    {new Intl.NumberFormat('de-DE', { style: 'currency', currency: 'EUR' }).format(
                                      payoutCaluclationData.purchaseBasePrice
                                    )}
                                  </u>
                                </b>
                              </Typography>
                            </Grid>
                            <Grid item xs={12}>
                              <Typography component="dt" style={{ width: '70%', float: 'left' }}>
                                <b>Mein Dankeschön je Bloomer*</b>
                              </Typography>
                              <Typography component="dd" style={{ width: '30%', float: 'left' }}>
                                <b>
                                  {new Intl.NumberFormat('de-DE', { style: 'currency', currency: 'EUR' }).format(
                                    user.superInfluencerRate - payoutCaluclationData.purchaseBasePrice
                                  )}
                                </b>
                              </Typography>
                            </Grid>

                            <Grid item xs={12}>
                              &nbsp;
                            </Grid>

                            <Grid item xs={12}>
                              <Typography variant="subtitle2">* mtl. bei jährlicher Zahlweise</Typography>
                            </Grid>
                          </Grid>
                        )}

                        <Box display="flex">
                          <Box flexGrow={1} />
                          <Box>
                            <Button
                              onClick={() => switchToMenu('influencer_terms')}
                              size="small"
                              variant="text"
                              color="primary"
                            >
                              Influencer-AGB's
                            </Button>
                          </Box>
                        </Box>

                        <Box display="flex">
                          <Box flexGrow={1}>
                            <Typography>
                              Mein aktueller Dankeschönbetrag: <b>{influencerBalance?.toFixed(2)}</b> €
                            </Typography>
                          </Box>
                          <Box>
                            <Button onClick={() => switchToMenu('invoices')} size="small" variant="text" color="primary">
                              Zu den Dankeschön-Gutschriften
                            </Button>
                          </Box>
                        </Box>

                        {/*<FormControlLabel
                          control={
                            <div style={{ alignSelf: 'start' }}>
                              <Checkbox name="accepted_payout_conditions" disabled={influencerBalance < redeemTreshold} />
                            </div>
                          }
                          label={
                            <Typography variant="caption">
                              * Mir ist bekannt, dass das Dankeschön eine freiwillige Maßnahme der LJS Media Group GmbH ist und
                              ich keinen Rechtsanspruch auf das Dankeschön habe. Der minimal auszahlungsfähige
                              Dankeschön-Betrag beträgt ist {redeemTreshold.toFixed(2)} €. Die Auszahlung des Dankeschöns
                              erfolgt jeweils zum 15. des Folgemonats. Mir ist bekannt, das je Auszahlung/ Transaktion vom
                              Zahlungsanbieter eine Gebühr i.H.v. 0,25 € zzgl. 1,4% des Auszahlungsbetrag erhoben wird. Nur mit
                              Bestätigung dieses Inhaltes kann ich das Dankeschön erhalten. Es gelten die AGB`s der LJS Media
                              Group GmbH. Die Auszahlung erfolgt über stripe connect, deren{' '}
                              <a href="https://stripe.com/de/connect-account/legal#vereinbarung-%C3%BCber-durch-connect-verbundene-konten-von-stripe">
                                Nutzungsvereinbarung ich hiermit annehme.
                              </a>
                            </Typography>
                          }
                        />

                        <br />
                        <br />

                        <Button
                          disabled={influencerBalance < redeemTreshold}
                          onClick={redeemInfluencerBalance}
                          size="small"
                          variant="contained"
                          color="primary"
                        >
                          Dankeschönbetrag auszahlen
                        </Button>*/}

                        <br />

                        <Grid container spacing={2}>
                          <Grid item xs={12}>
                            <Typography>Meine Kontonummer zur Überweisung von Gutschriften:</Typography>
                          </Grid>

                          <Grid item md={6} xs={12}>
                            <Grid container spacing={2}>
                              <Grid item xs={12}>
                                <TextField
                                  variant="outlined"
                                  size="small"
                                  id="account_owner"
                                  name="account_owner"
                                  label="Kontoinhaber"
                                  autoComplete="account_owner"
                                  fullWidth
                                  autoFocus
                                  value={values.account_owner}
                                  onChange={handleChange}
                                  error={touched.account_owner && Boolean(errors.account_owner)}
                                  helperText={touched.account_owner && errors.account_owner}
                                />
                              </Grid>
                              <Grid item xs={12}>
                                <TextField
                                  variant="outlined"
                                  size="small"
                                  id="account_iban"
                                  name="account_iban"
                                  label="IBAN"
                                  autoComplete="account_iban"
                                  fullWidth
                                  autoFocus
                                  value={values.account_iban}
                                  onChange={handleChange}
                                  error={touched.account_iban && Boolean(errors.account_iban)}
                                  helperText={touched.account_iban && errors.account_iban}
                                />
                              </Grid>
                              <Grid item xs={12}>
                                <TextField
                                  variant="outlined"
                                  size="small"
                                  id="account_bic"
                                  name="account_bic"
                                  label="BIC"
                                  autoComplete="account_bic"
                                  fullWidth
                                  autoFocus
                                  value={values.account_bic}
                                  onChange={handleChange}
                                  error={touched.account_bic && Boolean(errors.account_bic)}
                                  helperText={touched.account_bic && errors.account_bic}
                                />
                              </Grid>
                            </Grid>
                          </Grid>

                          <Grid item md={10} xs={7}>
                            <Fade in={saved.bankDetails} timeout={{ appear: 500, exit: 1000 }}>
                              <Alert float="left">Gespeichert</Alert>
                            </Fade>
                            {!!errors.api && <Alert severity="error">{errors.api}</Alert>}
                          </Grid>
                          <Grid item md={2} xs={5}>
                            <Button
                              onClick={handleSubmit}
                              disabled={!touched}
                              size="large"
                              variant="contained"
                              color="primary"
                            >
                              Speichern
                            </Button>
                          </Grid>
                        </Grid>
                      </Grid>
                    )}
                  </Grid>
                </Paper>
              </Box>
            )}

            <Box hidden={openMenu !== 'influencer_terms'}>
              <Paper elevation={5} className={classes.profileContent}>
                <Grid container spacing={3}>
                  <Grid item xs={12}>
                    <Typography variant="h6">{"Influencer-AGB's"}</Typography>
                    <a href="#" onClick={() => switchToMenu('referrals')}>
                      zurück
                    </a>
                  </Grid>

                  <Grid item xs={12}>
                    {user && process.env.REACT_APP_API_URL.includes('my.getbloomup.com') && (
                      <embed
                        src={`https://drive.google.com/viewerng/viewer?embedded=true&url=documents/bloomup_AGB_Influencer_03-2023.pdf`}
                        width="100%"
                        height="558"
                      />
                    )}
                    {user && !process.env.REACT_APP_API_URL.includes('my.getbloomup.com') && (
                      <>
                        <div style={{ color: 'white' }}>(local viewer only)</div>
                        <embed
                          src={`documents/bloomup_AGB_Influencer_03-2023.pdf`}
                          width="100%"
                          height="858"
                          type="application/pdf"
                        />
                      </>
                    )}
                  </Grid>

                  <Grid item xs={12}>
                    <a href="#" onClick={() => switchToMenu('referrals')}>
                      zurück
                    </a>
                  </Grid>
                </Grid>
              </Paper>
            </Box>

            {familySubscription && (
              <Box hidden={openMenu !== 'family'}>
                <Paper elevation={5} className={classes.profileContent}>
                  <Grid container spacing={3}>
                    <Grid item xs={12}>
                      <Typography variant="h6">{'Mein bloomUp-Familien-Tarif'}</Typography>
                    </Grid>

                    <Grid item xs={12}>
                      <Typography>
                        {
                          'Das Family-Abo ist das Beste für meine Familie und mich. Mit dem Family-Abo nutzen zwei Erwachsene und bis zu 3 Kinder (bis 18 Jahre) jeweils ihre eigene bloomUp-App.'
                        }
                      </Typography>
                    </Grid>

                    <Grid item xs={5}>
                      <Typography>{'Hier lege ich unseren persönlichen Family-App-Code fest: '}</Typography>
                    </Grid>
                    <Grid item xs={7}>
                      <TextField
                        variant="outlined"
                        size="small"
                        required
                        id="family_id"
                        name="family_id"
                        autoComplete="family_id"
                        autoFocus
                        disabled={!!user?.familyReferrer}
                        InputProps={{
                          startAdornment: <InputAdornment position="start">family-</InputAdornment>,
                          classes: {
                            adornedStart: classes.familyStartAdornment,
                          },
                        }}
                        value={values.family_id}
                        onChange={handleChange}
                        error={touched.family_id && Boolean(errors.family_id)}
                        helperText={touched.family_id && errors.family_id}
                      />
                      <Button
                        disabled={!values.family_id || !!user?.familyReferrer}
                        onClick={() => {
                          window.confirm('Die Family Referer-ID kann danach nicht mehr geändert werden. Fortfahren?') &&
                            handleChangeRefererId('family');
                        }}
                        className={classes.changeInviteIdButton}
                        size="medium"
                        variant="contained"
                        color="primary"
                      >
                        ändern
                      </Button>
                    </Grid>

                    <Grid item xs={12}>
                      <Typography>
                        {
                          'Über die von mir eingebende E-Mail-Adresse erhält der Empfänger einen Link, mit welcher die bloomUp-App heruntergeladen werden kann. Unseren Family-App-Code geben wir bei der Registrierung in der App ein. Somit ist die Zuordnung zu unserem bloomUp-Family-Tarif erfolgt. Der App-Code kann nur 4 Mal zur Registrierung verwendet werden.'
                        }
                      </Typography>
                    </Grid>

                    <Grid item xs={12}>
                      <Typography>
                        <b>{'Meine Familie:'}</b>
                      </Typography>
                      <br />
                    </Grid>

                    {[
                      '1. Einladung (E-mail)',
                      '2. Einladung (E-mail)',
                      '3. Einladung (E-mail)',
                      '4. Einladung (E-mail)',
                    ].map((label, index) => familyMemberInputRow(index, label))}

                    <Grid item xs={12}>
                      <Fade in={saved.invite} timeout={{ appear: 500, exit: 1000 }}>
                        <Alert float="left">Gespeichert</Alert>
                      </Fade>
                      {!!errors.api && <Alert severity="error">{errors.api}</Alert>}
                    </Grid>
                  </Grid>
                </Paper>
              </Box>
            )}
          </Grid>
        </Grid>
      </Container>
    </div>
  );
};

export default Profile;
