import React, { useEffect, useState } from 'react';
import { Helmet } from 'react-helmet';
import { makeStyles } from '@material-ui/core/styles';
import { CircularProgress, Container, Box, Grid, Typography } from '@material-ui/core';
import PageNotFound from './PageNotFound';

const useStyles = makeStyles((theme) => ({
  entryImage: {
    width: '100%',
    height: 'auto',
    minHeight: '200px',
  },
}));

const Share = (props) => {
  const classes = useStyles();

  const entryUuid = props.match.params.entryUuid;

  const [loading, setLoading] = useState(true);
  const [ready, setReady] = useState(false);
  const [elapsed, setElapsed] = useState(false);
  const [entry, setEntry] = useState(null);
  const [referrerUrl, setReferrerUrl] = useState(null);

  useEffect(() => {
    fetch(`${process.env.REACT_APP_API_URL}/entries/shared/${entryUuid}`, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${localStorage.getItem('token')}`,
      },
    })
      .then((response) => response.json())
      .then((data) => {
        setLoading(false);

        if (data.code === 423) {
          return setElapsed(true);
        }

        if (!data.code) {
          setEntry(data);
          const referrerId = data.createdBy.referrer || data.createdBy.id;
          const appRedirectUrl = `${process.env.REACT_APP_FIREBASE_APP_URL}`;

          const params = { referrer: referrerId };

          const referrerParams = encodeURIComponent(JSON.stringify(params));
          const urlParams = encodeURIComponent(`https://app.getbloomup.com/login?referrer=${referrerParams}`);
          const linkParam = `&link=${urlParams}`;

          const fallbackUrl = `https://my.getbloomup.com/signup?referrer=${referrerId}`;
          const fallbackParams = `&afl=${fallbackUrl}&ifl${fallbackUrl}=&ofl=${fallbackUrl}`;

          setReferrerUrl(`${appRedirectUrl}${linkParam}${fallbackParams}`);
          setReady(true);
        }
      })
      .catch((error) => {
        console.error(error);
      });
  }, [entryUuid]);

  return (
    <div>
      <Helmet>
        <meta property="og:type" content="website" />
        <meta property="og:image" content="https://my.getbloomup.com/logo512.png" />
      </Helmet>
      <Container maxWidth="lg">
        {loading && (
          <Box p={8} style={{ display: 'flex', justifyContent: 'center' }}>
            <CircularProgress />
          </Box>
        )}

        {!loading && !elapsed && !ready && (
          <PageNotFound text="Inhalte werden aktuell bereitgestellt, bitte versuchen Sie es später noch einmal." />
        )}
        {!loading && elapsed && (
          <PageNotFound
            text={
              <Typography variant="h5" align="center">
                Dieser Link ist nicht mehr gültig.
                <br /> Bitte wende Dich an den Sender des Links, er kann ihn gerne noch einmal mit Dir teilen.
              </Typography>
            }
          />
        )}
        {ready && (
          <>
            <Helmet>
              <title>{entry.title}</title>
              <meta name="description" content={`Geteilter Eintrag: ${entry.title}`} />
              <meta property="og:title" content={entry.title} />
              <meta property="og:description" content={`Geteilter Eintrag: ${entry.title}`} />
            </Helmet>
            <Box my={2}>
              <Typography gutterBottom>
                {entry.type === 'IDEA' ? 'Meine Idee' : 'Mein Erlebnis'} vom{' '}
                <b>
                  {new Intl.DateTimeFormat('de-DE', {
                    weekday: 'long',
                    year: 'numeric',
                    month: 'long',
                    day: '2-digit',
                  }).format(new Date(entry?.dateTime))}
                </b>{' '}
                mit Dir geteilt:
              </Typography>

              <Typography gutterBottom>
                <b>{entry.title}</b>
              </Typography>

              <Typography gutterBottom>
                {entry.textContent?.split('\n').map((item, key) => (
                  <span key={key}>
                    {item}
                    <br />
                  </span>
                ))}
              </Typography>

              <Box my={3}>
                <Grid container spacing={3}>
                  {entry.pictureHashes.map((picture, key) => (
                    <Grid item lg={4} md={6} xs={12}>
                      <img
                        key={picture}
                        className={classes.entryImage}
                        src={`${process.env.REACT_APP_API_URL}/files/${picture}`}
                        alt="Dieses Bild wird noch hochgeladen. Bitte schau später noch mal vorbei."
                      />
                    </Grid>
                  ))}
                </Grid>
              </Box>

              {/*<ImageList variant="masonry" cols={{ xl: 3, md: 2, sm: 1 }} gap={8}>
                  {entry.pictureHashes.map((picture) => (
                    <ImageListItem key={picture.img}>
                      <img
                        src={`${process.env.REACT_APP_API_URL}/files/${picture}?w=248&fit=crop&auto=format`}
                        srcSet={`${process.env.REACT_APP_API_URL}/files/${picture}?w=248&fit=crop&auto=format&dpr=2 2x`}
                        alt={picture.title}
                        loading="lazy"
                      />
                    </ImageListItem>
                  ))}
                </ImageList>*/}

              <Typography gutterBottom>
                ... geteilt durch bloomUp -meinem persönlichen Lebensbegleiter. Lass auch Du Dein Leben von bloomUp begleiten
                und lerne bloomUp kennen. Werde noch heute Bloomer... reserviere Dir schon einmal den exklusiven Friend-Tarif
                und klicke auf den Link, um Dir die{' '}
                <a href={referrerUrl} target="_blank" rel="noreferrer">
                  bloomUp-App
                </a>{' '}
                in Deinem App-Store herunter zu laden:
              </Typography>
            </Box>

            <Box my={5}>
              <a href="https://getbloomup.com">
                <img src="/images/bloomUp-my-life-logo-black.png" alt="bloomUp my life Logo" />
              </a>
            </Box>
          </>
        )}
      </Container>
    </div>
  );
};

export default Share;
