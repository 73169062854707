import React, { useEffect } from 'react';
import './App.css';
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';
import { UserContext } from './hooks/UserContext';
import { VariantContext } from './hooks/VariantContext';
import useFindUser from './hooks/useFindUser';

import AppBar from './components/AppBar';
import AppFooter from './components/AppFooter';
import RouteWithAuth from './components/RouteWithAuth';

import { MuiThemeProvider } from '@material-ui/core/styles';
import themeBloomup from './themes/default';
import themeLjs from './themes/ljs';
import { CssBaseline } from '@material-ui/core';
import Dashboard from './components/pages/Dashboard';
import Login from './components/pages/Login';
import ForgotPassword from './components/pages/ForgotPassword';
import ResetPassword from './components/pages/ResetPassword';
import Signup from './components/pages/Signup';
import Profile from './components/pages/Profile';
import Friends from './components/pages/Friends';
import Level from './components/pages/Level';
import Knowledge from './components/pages/Knowledge';
import Print from './components/pages/Print';
import Shop from './components/pages/Shop';
import Subscription from './components/pages/Subscription';
import Share from './components/pages/Share';
import Legal from './components/pages/Legal';
import Privacy from './components/pages/Privacy';
import PageNotFound from './components/pages/PageNotFound';

// NOTE: try material-kit-react for page design (https://github.com/creativetimofficial/material-kit-react)

const App = (props) => {
  const { user, setUser, isLoading } = useFindUser();

  const { host } = window.location;
  const appVariant = host.includes('ljs') ? 'ljs' : 'bloomup';

  if (appVariant === 'ljs') {
    const favicon = document.getElementById('favicon');
    favicon.setAttribute('href', '/favicon-ljs.ico');
  }

  const sharedEntryView = window.location.pathname.includes('/share/');
  const appLoginView = window.location.pathname.includes('/signup') && !window.location.search.includes('token='); // TODO: specify correct path later

  useEffect(() => {
    const tokenExpirationTime = localStorage.getItem('tokenExpirationTime');

    if (!tokenExpirationTime) return;

    const tokenRefreshInterval = tokenExpirationTime - 5000;

    setInterval(() => {
      const refreshToken = localStorage.getItem('refresh');
      const payload = {
        refreshToken: refreshToken,
      };

      fetch(`${process.env.REACT_APP_API_URL}/auth/refresh-tokens`, {
        method: 'POST',
        body: JSON.stringify(payload),
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${localStorage.getItem('token')}`,
        },
      }).then((res) => {
        if (res.status === 200) {
          res.json().then((data) => {
            localStorage.setItem('token', data.access.token);
            localStorage.setItem('refresh', data.refresh.token);
          });
        } else {
          res.json().then((error) => {
            console.error('TOKEN HEARTBEAT ERROR:', error);
          });
        }
      });
    }, tokenRefreshInterval);
  }, []);

  return (
    <VariantContext.Provider value={{ appVariant: appVariant }}>
      <MuiThemeProvider theme={appVariant === 'bloomup' ? themeBloomup : themeLjs}>
        <CssBaseline />
        <Router>
          <UserContext.Provider value={{ user, setUser, isLoading }}>
            {!sharedEntryView && !appLoginView && <AppBar />}
            <Switch>
              <RouteWithAuth path={'/'} exact /*strict*/ component={Dashboard} />
              <Route path={'/login'} exact /*strict*/ component={Login} />
              <Route path={'/signup'} exact strict component={Signup} />
              <Route path={'/forgot-password'} exact /*strict*/ component={ForgotPassword} />
              <Route path={'/reset-password'} exact /*strict*/ component={ResetPassword} />
              <Route path={'/verify-email'} component={Signup} />
              <RouteWithAuth path={'/mybloomup'} exact /*strict*/ component={Profile} />
              <RouteWithAuth path={'/bloomfriends'} exact /*strict*/ component={Friends} />
              <RouteWithAuth path={'/bloomlevel'} exact /*strict*/ component={Level} />
              <RouteWithAuth authAlsoByTokenParam path={'/glossar'} exact /*strict*/ component={Knowledge} />
              <RouteWithAuth path={'/bloomprint'} exact /*strict*/ component={Print} />
              {['superadmin', 'admin'].includes(user?.role) && (
                <RouteWithAuth path={'/shop'} exact /*strict*/ component={Shop} />
              )}
              <RouteWithAuth authAlsoByTokenParam path={'/subscription'} exact /*strict*/ component={Subscription} />
              <Route path={'/share/:entryUuid'} exact /*strict*/ component={Share} />
              <Route path={'/legal'} exact /*strict*/ component={Legal} />
              <Route path={'/privacy'} exact /*strict*/ component={Privacy} />
              <Route
                render={(route) => {
                  if (route.location.pathname === '/invitation') {
                    const params = route.location.search.replace('?', '&');
                    const url = process.env.REACT_APP_PLAYSTORE_APP_URL + params;
                    window.location.href = url;
                  }
                  return <PageNotFound />;
                }}
              />
              <Route exact /*strict*/ component={PageNotFound} />
            </Switch>
            {!sharedEntryView && !appLoginView && <AppFooter />}
          </UserContext.Provider>
        </Router>
      </MuiThemeProvider>
    </VariantContext.Provider>
  );
};

export default App;
