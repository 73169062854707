import React, { useEffect, useState, useContext } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import {
  Container,
  Grid,
  Box,
  Typography,
  List,
  ListItem,
  ListItemText,
  ListItemAvatar,
  Avatar,
  Badge,
  IconButton,
} from '@material-ui/core';
import PeopleIcon from '@material-ui/icons/People';
import PhotoIcon from '@material-ui/icons/Photo';
import TextIcon from '@material-ui/icons/Message';
import LightbulbIcon from '@material-ui/icons/Brightness5';
//import LocationOnIcon from '@material-ui/icons/LocationOn';
import Pagination from '@material-ui/lab/Pagination';
import { UserContext } from '../../hooks/UserContext';

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
    marginTop: '50px',
  },
  eventList: {
    position: 'relative',
    top: 0,
    backgroundColor: theme.palette.background.paper,
  },
  inline: {
    display: 'inline',
  },
  avatarWrapper: {
    padding: '15px',
  },
  avatar: {
    width: '290px',
    height: '200px',
    backgroundColor: 'transparent',
  },
  avatarPlaceholderContainer: {
    position: 'absolute',
    width: '290px',
    height: '200px',
    borderRadius: '3px',
    backgroundColor: '#bdbdbd',
    display: 'block',
    overflow: 'hidden',
  },
  avatarPlaceholderIcon: {
    color: 'white',
    position: 'absolute',
    left: '108px',
    top: '68px',
    width: '24px',
    height: '24px',
  },
  detailWrapper: {
    padding: '15px',
  },
  metrics: {
    position: 'absolute',
    top: '15px',
    right: '15px',
    [theme.breakpoints.down('md')]: {
      /*position: 'relative',*/
      top: '220px',
      left: '125px',
    },
  },
  location: {
    position: 'absolute',
    bottom: '20px',
  },
  badge: {
    right: -3,
    top: 13,
    border: `2px solid ${theme.palette.background.paper}`,
    padding: '0 4px',
  },
  listItem: {
    [theme.breakpoints.down('md')]: {
      display: 'block',
    },
  },
}));

const HomePage = () => {
  const classes = useStyles();
  const [selectedIndex, setSelectedIndex] = useState(null);
  const [data, setData] = useState(null);

  const { user } = useContext(UserContext);
  const userId = user?.id;

  const handleListItemClick = (event, index) => {
    setSelectedIndex(index);
  };

  useEffect(() => {
    fetch(`${process.env.REACT_APP_API_URL}/events`, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${localStorage.getItem('token')}`,
      },
    })
      .then((res) => {
        if (res.status === 200) {
          res.json().then((data) => {
            setData({ events: data.results, metrics: data.metrics, isFetching: false });
          });
        }
      })
      .catch((e) => {
        console.error(e);
      });
  }, [userId]);

  return (
    <div className={classes.root}>
      <Container maxWidth="lg">
        <Grid container direction="column" style={{ minHeight: '75vh' }}>
          <Grid item xs={12}>
            <Box m={4}>
              <Typography variant="h5">
                {'Hier hast Du eine Übersicht über Deine aktuellen bloomUpFriends-Events'}
              </Typography>
              <Typography variant="subtitle1" gutterBottom>
                {'Die Details zu den jeweiligen bloomFriends-Events findest Du in Deiner App.'}
              </Typography>
            </Box>
          </Grid>

          {!data && <></>}

          {data && !data.events.length && (
            <Grid item xs={10} style={{ margin: '20vh auto' }}>
              <Typography variant="body2">{'keine Events vorhanden'}</Typography>
            </Grid>
          )}

          <Grid item xs={12}>
            <List className={classes.eventList}>
              {data?.events &&
                data.events?.map(
                  ({ id, uuid, title, pictureHash, description, members, startDate, endDate, location }, i) => {
                    return (
                      <ListItem
                        alignItems="flex-start"
                        className={classes.listItem}
                        button
                        divider
                        selected={selectedIndex === i}
                        onClick={(event) => handleListItemClick(event, i)}
                      >
                        <ListItemAvatar className={classes.avatarWrapper}>
                          <Container className={classes.avatarPlaceholderContainer}>
                            {/*<PhotoIcon className={classes.avatarPlaceholderIcon} />*/}
                          </Container>
                          <Avatar
                            variant="rounded"
                            alt="Event1"
                            title={uuid}
                            className={classes.avatar}
                            src={`${process.env.REACT_APP_API_URL}/files/${pictureHash}`}
                          >
                            <PhotoIcon />
                          </Avatar>
                        </ListItemAvatar>

                        <ListItemText
                          className={classes.detailWrapper}
                          primary={
                            <>
                              <div className={classes.metrics}>
                                <IconButton>
                                  <Badge
                                    anchorOrigin={{
                                      vertical: 'bottom',
                                      horizontal: 'right',
                                    }}
                                    badgeContent={members.length}
                                    showZero
                                    color="primary"
                                  >
                                    <PeopleIcon />
                                  </Badge>
                                </IconButton>

                                {
                                  <IconButton>
                                    <Badge
                                      anchorOrigin={{
                                        vertical: 'bottom',
                                        horizontal: 'right',
                                      }}
                                      badgeContent={data.metrics[id].totalEntries}
                                      showZero
                                      color="primary"
                                    >
                                      <TextIcon />
                                    </Badge>
                                  </IconButton>
                                }

                                {
                                  <IconButton>
                                    <Badge
                                      anchorOrigin={{
                                        vertical: 'bottom',
                                        horizontal: 'right',
                                      }}
                                      badgeContent={data.metrics[id].totalIdeas}
                                      showZero
                                      color="primary"
                                    >
                                      <LightbulbIcon />
                                    </Badge>
                                  </IconButton>
                                }

                                {
                                  <IconButton>
                                    <Badge
                                      anchorOrigin={{
                                        vertical: 'bottom',
                                        horizontal: 'right',
                                      }}
                                      badgeContent={data.metrics[id].totalPhotos}
                                      showZero
                                      color="primary"
                                    >
                                      <PhotoIcon />
                                    </Badge>
                                  </IconButton>
                                }

                                {/*
                                <IconButton>
                                  <Badge
                                    anchorOrigin={{
                                      vertical: 'bottom',
                                      horizontal: 'right',
                                    }}
                                    badgeContent={0}
                                    showZero
                                    color="primary"
                                  >
                                    <VideoIcon />
                                  </Badge>
                                </IconButton>
                              */}
                              </div>
                              <Typography variant="h6" color="textPrimary">
                                {title}
                              </Typography>
                            </>
                          }
                          secondary={
                            <>
                              <Typography component="span" variant="body2" color="textPrimary">
                                {startDate}
                                <br />
                                {description}
                              </Typography>

                              {/*<Grid container direction="row" alignItems="center" className={classes.location}>
                            <LocationOnIcon />
                            {location}
                          </Grid>*/}
                            </>
                          }
                        />
                      </ListItem>
                    );
                  }
                )}
            </List>
          </Grid>
          {data?.events.length > 10 && (
            <Grid item xs={10}>
              {data && <Pagination count={data?.events.length / 10} color="primary" />}
            </Grid>
          )}
        </Grid>
      </Container>
    </div>
  );
};

export default HomePage;
