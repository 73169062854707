import React, { useState } from 'react';
import { Card, CardContent, CardMedia, CardActions, Button, Typography } from '@material-ui/core';
import ShowMoreText from 'react-show-more-text';
import { withStyles } from '@material-ui/core/styles';
import { subscribe } from '../events';

const styles = (theme) => ({
  productCard: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    minHeight: '600px',
    padding: '15px',
  },
  productImage: {
    objectFit: 'contain',
    padding: '16px 16px 0 16px',
    minHeight: '306px',
  },
  productDetails: {},
  productPrice: {
    padding: '0 16px',
    margin: 'auto 0 0 0',
  },
  collapsibleDescription: {
    display: 'block',
    paddingTop: '-8px',
    '& p': {
      marginBottom: 0,
    },
  },
  moreLessLink: {
    cursor: 'pointer',
    textDecoration: 'underline',
    fontSize: '12px',
    color: theme.palette.primary.main,
  },
  purchaseButton: {
    borderRadius: 25,
    padding: '10px 30px',
    backgroundColor: 'black',
    color: 'white',
  },
});

const ShopProductBox = (props) => {
  const { classes, disableAddButton, handleOrder } = props;
  const { name, description, price, original_price, yearly_price, /*attributes,*/ images } = props.data;

  const disabled = typeof disableAddButton === 'function' ? disableAddButton.call() : disableAddButton;

  /*const subheadline = attributes.find((attribute) => attribute.name === 'subheadline')?.options[0];
  const descriptionAttribute = attributes.find((attribute) => attribute.name === 'description')?.options[0];
  const interval = attributes.find((attribute) => attribute.name === 'payment_interval')?.options[0];
  const model = attributes.find((attribute) => attribute.name === 'model')?.options[0];*/

  const [cartItems, setCartItems] = useState([]); // eslint-disable-line no-unused-vars

  subscribe('onAddProductToCart', (event) => {
    const productsInCart = event.detail.items;
    setCartItems(productsInCart);
  });

  subscribe('onRemoveProductFromCart', (event) => {
    const productsInCart = event.detail.items;
    setCartItems(productsInCart);
  });

  return (
    <Card elevation={5} p={5} className={classes.productCard}>
      <CardMedia className={classes.productImage} component="img" image={images[0]?.src} alt={images[0]?.name} />
      <CardContent className={classes.productDetails}>
        <Typography gutterBottom variant="h5" component="div">
          {name}
        </Typography>
        <Typography variant="body2" color="textPrimary">
          <ShowMoreText
            lines={3}
            more="weiterlesen"
            less="kürzen"
            className="content-css"
            anchorClass={classes.moreLessLink}
            expanded={false}
            truncatedEndingComponent={'... '}
          >
            <span className={classes.collapsibleDescription} dangerouslySetInnerHTML={{ __html: description }}></span>
          </ShowMoreText>
        </Typography>
      </CardContent>
      <CardContent className={classes.productPrice}>
        <Typography variant="body2" component="span" color="textPrimary">
          <b>
            {new Intl.NumberFormat('de-DE', { style: 'currency', currency: 'EUR' }).format(price)}{' '}
            {original_price && (
              <strike>
                {new Intl.NumberFormat('de-DE', { style: 'currency', currency: 'EUR' }).format(original_price)}
              </strike>
            )}
            {yearly_price && (
              <span>
                {' '}
                oder {new Intl.NumberFormat('de-DE', { style: 'currency', currency: 'EUR' }).format(yearly_price)} im
                Jahresabo
              </span>
            )}
          </b>
        </Typography>
      </CardContent>
      <CardActions>
        {!yearly_price && (
          <Button
            variant="contained"
            className={classes.purchaseButton}
            disabled={disabled}
            onClick={() => handleOrder(props.data)}
          >
            Ordern
          </Button>
        )}
        {yearly_price && (
          <>
            <Button
              variant="contained"
              className={classes.purchaseButton}
              disabled={disabled}
              onClick={() => handleOrder(props.data)}
            >
              mtl. ordern
            </Button>
            <Button
              variant="contained"
              className={classes.purchaseButton}
              disabled={disabled}
              onClick={() => handleOrder(props.data, { period: 'yearly' })}
            >
              Jahresabo ordern
            </Button>
          </>
        )}
      </CardActions>
    </Card>
  );
};

export default withStyles(styles)(ShopProductBox);
