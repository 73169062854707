import React, { useContext } from 'react';
import { VariantContext } from '../../hooks/VariantContext';
import { useHistory } from 'react-router-dom';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { CssBaseline, Container, Grid, Avatar, Typography, TextField, Button, Link } from '@material-ui/core';
import { withStyles, makeStyles } from '@material-ui/core';
import { Alert } from '@material-ui/lab';
import styles from './Login.styles';

const validationSchema = Yup.object({
  email: Yup.string('Enter your email').email('Enter a valid email').required('Email is required'),
  password: Yup.string('').required('Enter your password'),
});

const Login = (props) => {
  const useStyles = makeStyles((theme) => styles(theme));
  const classes = useStyles();
  const history = useHistory();

  const { appVariant } = useContext(VariantContext);

  const { handleSubmit, handleChange, values, touched, errors } = useFormik({
    initialValues: {
      email: '',
      password: '',
    },
    validationSchema,
    onSubmit: (values, { setStatus, setErrors, setFieldError }) => {
      const payload = {
        email: values.email,
        password: values.password,
      };

      fetch(`${process.env.REACT_APP_API_URL}/auth/login`, {
        method: 'POST',
        body: JSON.stringify(payload),
        headers: { 'Content-Type': 'application/json' },
      })
        .then((res) => {
          if (res.status === 200) {
            res.json().then((data) => {
              localStorage.setItem('token', data.tokens.access.token);
              localStorage.setItem('refresh', data.tokens.refresh.token);
              localStorage.setItem('tokenExpirationTime', data.tokenExpirationTime);
              localStorage.setItem('messages', data.messages);
              const { pathname, search } = props.location;
              const { protocol, host } = window.location;
              const url = new URL(`${protocol}/${host}${pathname}${search}`);
              const redirectAfterLogin = url.searchParams.get('redirect') || '/';
              history.push(redirectAfterLogin);
              history.go(0);
            });
          } else {
            res.json().then((error) => {
              if (error.code === 401) {
                setFieldError('password', error.message);
              } else {
                setFieldError('api', error.message);
              }
            });
          }
        })
        .catch((error) => {
          setStatus({ success: false });
          setErrors({
            api:
              error.message === 'Failed to fetch'
                ? 'Der Server ist momentan nicht erreichbar. Bitte versuche es später noch einmal.'
                : error.message,
          });
        });
    },
  });

  return (
    <Container component="main" maxWidth="xs" style={{ minHeight: '75vh' }}>
      <CssBaseline />
      <div className={classes.paper}>
        {appVariant === 'bloomup' && <Avatar src="/images/login-symbol.png"></Avatar>}
        {appVariant === 'ljs' && <Avatar src="/images/login-symbol-ljs.png"></Avatar>}
        <Typography component="h1" variant="h5">
          Sign in
        </Typography>
        <form onSubmit={handleSubmit} className={classes.form} noValidate>
          <TextField
            variant="outlined"
            margin="normal"
            required
            fullWidth
            id="email"
            label="Email Address"
            name="email"
            autoComplete="email"
            autoFocus
            value={values.email}
            onChange={handleChange}
            error={touched.email && Boolean(errors.email)}
            helperText={touched.email && errors.email}
          />
          <TextField
            variant="outlined"
            margin="normal"
            required
            fullWidth
            name="password"
            label="Password"
            type="password"
            id="password"
            autoComplete="current-password"
            value={values.password}
            onChange={handleChange}
            error={touched.password && Boolean(errors.password)}
            helperText={touched.password && errors.password}
          />
          {!!errors.api && <Alert severity="error">{errors.api}</Alert>}
          <Button type="submit" fullWidth variant="contained" color="primary" className={classes.submit}>
            Sign In
          </Button>
          <Grid container>
            <Grid item xs>
              <Link href="/forgot-password" variant="body2">
                Forgot password?
              </Link>
            </Grid>
            <Grid item>
              <Link href="/signup" variant="body2">
                {"Don't have an account? Sign Up"}
              </Link>
            </Grid>
          </Grid>
        </form>
      </div>
    </Container>
  );
};

export default withStyles(styles, { withTheme: true })(Login);
