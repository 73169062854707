import React, { useEffect, useState } from 'react';
import jwtDecode from 'jwt-decode';
import { Route, Redirect } from 'react-router-dom';

const RouteWithAuth = ({ component: Component, ...rest }) => {
  const [isAuthenticated, setIsAuthenticated] = useState(null);

  useEffect(() => {
    let token;

    const url = new URL(window.location);
    const tokenParam = url.searchParams.get('token');

    if (rest.authAlsoByTokenParam && tokenParam) {
      token = tokenParam;
    } else {
      token = localStorage.getItem('token');
    }

    if (token) {
      try {
        let tokenExpiration = jwtDecode(token).exp;
        let dateNow = new Date();
        if (tokenExpiration < dateNow.getTime() / 1000) {
          setIsAuthenticated(false);
        } else {
          setIsAuthenticated(true);
        }
      } catch (error) {
        console.error('ERROR DECODING TOKEN', error);
      }
    } else {
      setIsAuthenticated(false);
    }
  }, [rest]);

  if (isAuthenticated === null) {
    return <></>;
  }

  const loginWithRedirect =
    window.location.pathname !== '/' ? `/login?redirect=${window.location.pathname}${window.location.search}` : '/login';

  return (
    <Route
      {...rest}
      render={(props) => (!isAuthenticated ? <Redirect to={loginWithRedirect} /> : <Component {...props} />)}
    />
  );
};

export default RouteWithAuth;
