import React, { useEffect, useState, useContext, Fragment } from 'react';
import { NavLink, useHistory } from 'react-router-dom';
import { Toolbar, Box, Container, Menu, Button, IconButton, Badge, Typography } from '@material-ui/core';
import AppBarMUI from '@material-ui/core/AppBar';
import MenuIcon from '@material-ui/icons/Menu';
import { UserContext } from '../hooks/UserContext';
import { VariantContext } from '../hooks/VariantContext';
import { subscribe } from '../events';
import { withStyles } from '@material-ui/core/styles';

const styles = (theme) => ({
  root: {
    width: '100%',
  },
  headerLogo: {
    position: 'relative',
    top: '8px',
  },
  linkList: {
    display: 'flex',
    [theme.breakpoints.down('md')]: {
      visibility: 'hidden',
    },
  },
  headerLink: {
    fontSize: '16px',
  },
  headerElementHidden: {
    display: 'none',
  },
  headerLinkSeparator: {
    lineHeight: '38px',

    [theme.breakpoints.down('md')]: {
      visibility: 'hidden',
      lineHeight: '2px !important',
    },
  },
  headerResponsiveMenu: {
    display: 'flex',
    flexGrow: 1,
    visibility: 'hidden',

    [theme.breakpoints.down('md')]: {
      visibility: 'visible',
      position: 'absolute',
      right: 0,
    },
  },
  cartBadge: {
    transform: 'scale(0.9) translate(50%, -50%)',
    position: 'relative',
    right: '10px',
    top: '20px',
  },
});

const AppBar = (props) => {
  const { classes } = props;
  const history = useHistory();

  const { user } = useContext(UserContext);

  const { appVariant } = useContext(VariantContext);

  const [anchorElNav, setAnchorElNav] = useState(null);
  const [cartItemCount, setCartItemCount] = useState(0);
  const [showHeaderMessage, setShowHeaderMessage] = useState(false);

  useEffect(() => {
    if (!user) return;
    setShowHeaderMessage(true);
  }, [user]);

  subscribe('onAddProductToCart', (event) => {
    const productsInCart = event.detail.items;
    setCartItemCount(productsInCart?.length);
  });

  subscribe('onRemoveProductFromCart', (event) => {
    const productsInCart = event.detail.items;
    setCartItemCount(productsInCart?.length);
  });

  const seesShop = () => {
    return ['superadmin', 'admin'].includes(user?.role);
  };

  const handleOpenNavMenu = (event) => {
    setAnchorElNav(event.currentTarget);
  };

  const handleCloseNavMenu = () => {
    setAnchorElNav(null);
  };

  const handleLogout = () => {
    localStorage.clear();
    history.go(0);
  };

  const RouterLink = React.forwardRef((props, ref) => <NavLink innerRef={ref} {...props} />);
  const RouterMenu = React.forwardRef((props, ref) => <LinkList innerRef={ref} {...props} />);

  const LinkList = (props, options) => {
    let links = [];

    const token = localStorage.getItem('token');
    if (!token) {
      const loginLinkList = [
        <Button
          key="login"
          component={RouterLink}
          to={'/login'}
          onClick={handleCloseNavMenu}
          color="inherit"
          className={classes.headerLink}
        >
          {'Login'}
        </Button>,
      ];
      links.push(...loginLinkList);
    } else {
      const navigationLinkList = [
        <Button
          key="home"
          component={RouterLink}
          to={'/'}
          onClick={handleCloseNavMenu}
          color="inherit"
          className={classes.headerLink}
        >
          {'Home'}
        </Button>,
        <div key="divider1" className={classes.headerLinkSeparator}>
          |
        </div>,
        <Button
          key="profile"
          component={RouterLink}
          to={'/mybloomup'}
          onClick={handleCloseNavMenu}
          color="inherit"
          className={classes.headerLink}
        >
          {'Profil'}
        </Button>,
        <div key="divider2" className={classes.headerLinkSeparator}>
          |
        </div>,
        <Button
          key="bloomfriends"
          component={RouterLink}
          to={'/bloomfriends'}
          onClick={handleCloseNavMenu}
          color="inherit"
          className={classes.headerLink}
        >
          <nobr>{appVariant === 'bloomup' ? 'bloomUp-Friends' : 'LJS-Event'}</nobr>
        </Button>,
        <div key="divider3" className={classes.headerLinkSeparator}>
          |
        </div>,
        <Button
          key="print"
          component={RouterLink}
          to={'/bloomprint'}
          onClick={handleCloseNavMenu}
          color="inherit"
          className={classes.headerLink}
        >
          <nobr>{appVariant === 'bloomup' ? 'bloomUp-Print' : 'LJS-Print'}</nobr>
        </Button>,
        <div key="divider4" className={appVariant === 'bloomup' ? classes.headerLinkSeparator : classes.headerElementHidden}>
          |
        </div>,
        <Fragment key="shopWithCartBadge">
          <Button
            key="shop"
            component={RouterLink}
            to={'/shop'}
            onClick={() => {
              history.replace('/shop');
              history.go(0);
              handleCloseNavMenu();
            }}
            color="inherit"
            className={appVariant === 'bloomup' && seesShop() ? classes.headerLink : classes.headerElementHidden}
          >
            {'Shop'}
          </Button>
          <Badge key="cartBadge" badgeContent={cartItemCount} color="primary" className={classes.cartBadge} />
          <div
            key="divider5"
            className={appVariant === 'bloomup' && seesShop() ? classes.headerLinkSeparator : classes.headerElementHidden}
          >
            |
          </div>
        </Fragment>,
        <Button
          key="glossary"
          component={RouterLink}
          to={'/glossar'}
          onClick={handleCloseNavMenu}
          color="inherit"
          className={appVariant === 'bloomup' ? classes.headerLink : classes.headerElementHidden}
        >
          {'Glossar'}
        </Button>,
        <div key="divider6" className={classes.headerLinkSeparator}>
          |
        </div>,

        <Button
          key="logout"
          component={RouterLink}
          to={'/'}
          onClick={handleLogout}
          color="inherit"
          className={classes.headerLink}
        >
          {'Logout'}
        </Button>,
      ];

      links.push(...navigationLinkList);
    }

    return <Box className={props.className}>{links}</Box>;
  };

  return (
    <div className={classes.root}>
      <AppBarMUI position="static" elevation={0} color="transparent">
        <Container maxWidth="lg">
          <Toolbar disableGutters>
            <Box mx={5} my={appVariant === 'bloomup' ? 5 : 2}>
              <RouterLink to="/" className={classes.headerLogo}>
                {appVariant === 'bloomup' && <img src="/images/bloomUp-my-life-logo-black.png" alt="bloomUp my life Logo" />}
                {appVariant === 'ljs' && <img src="/images/ljs-logo-blue.png" alt="Ljs Logo" />}
              </RouterLink>
            </Box>

            <Box className={classes.headerResponsiveMenu}>
              <IconButton
                size="medium"
                aria-label="account of current user"
                aria-controls="menu-appbar"
                aria-haspopup="true"
                onClick={handleOpenNavMenu}
                color="inherit"
              >
                <MenuIcon />
              </IconButton>
              <Menu
                id="menu-appbar"
                anchorEl={anchorElNav}
                anchorOrigin={{
                  vertical: 'bottom',
                  horizontal: 'right',
                }}
                keepMounted
                transformOrigin={{
                  vertical: 'top',
                  horizontal: 'right',
                }}
                open={Boolean(anchorElNav)}
                onClose={handleCloseNavMenu}
                /*style={{
                  display: { xs: 'block', md: 'block', lg: 'none', xl: 'none' },
                }}*/
              >
                <RouterMenu />
              </Menu>
            </Box>

            <Box m={5}>
              <RouterMenu className={classes.linkList} />
            </Box>
          </Toolbar>
        </Container>
      </AppBarMUI>
      {showHeaderMessage && (
        <Container style={{ padding: '0 0 20px 0' }}>
          <Typography style={{ padding: '5px 10px', backgroundColor: '#F14E14', color: 'white' }}>
            Wir feiern unseren Markteintritt und Du kannst bloomUp my life® für die kommende Zeit GRATIS nutzen! Zu
            Änderungen informieren wir Dich rechtzeitig.
            <br />
            Unter{' '}
            <RouterLink to="/subscription" style={{ color: 'white' }}>
              {'"mein Abo"'}
            </RouterLink>{' '}
            kannst Du Dich bereits jetzt schon einmal über unsere Abo-Modelle informieren. Wir wünschen Dir viel Spaß beim
            bloomen...
          </Typography>
        </Container>
      )}
    </div>
  );
};

export default withStyles(styles)(AppBar);
