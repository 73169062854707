import React, { useEffect, useState, useContext } from 'react';
import { Link, useHistory } from 'react-router-dom';
import { saveAs } from 'file-saver';
import { useFormik, useFormikContext } from 'formik';
import * as Yup from 'yup';
import Grid from '@material-ui/core/Grid';
import { makeStyles } from '@material-ui/core/styles';
import {
  Typography,
  List,
  ListItem,
  ListItemText,
  Button,
  Container,
  FormControl,
  TextField,
  InputLabel,
  Select,
  MenuItem,
  RadioGroup,
  ButtonGroup,
  FormControlLabel,
  Radio,
  FormGroup,
  Box,
  Card,
  CardContent,
  CircularProgress,
  FormHelperText,
  Hidden,
} from '@material-ui/core';
import PeopleIcon from '@material-ui/icons/People';
import PhotoIcon from '@material-ui/icons/Photo';
import LoyaltyIcon from '@material-ui/icons/Loyalty';
import NoteIcon from '@material-ui/icons/Note';
import LocationOnIcon from '@material-ui/icons/LocationOn';
import DeleteIcon from '@material-ui/icons/Delete';
import FolderIcon from '@material-ui/icons/Folder';
import Pagination from '@material-ui/lab/Pagination';
import { VariantContext } from '../../hooks/VariantContext';
import { UserContext } from '../../hooks/UserContext';
import StripeCheckoutModal from '../StripeCheckoutModal';

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
    marginTop: '50px',
    marginBottom: '50px',
  },
  printList: {
    position: 'relative',
    top: 0,
    backgroundColor: theme.palette.background.paper,
  },
  inline: {
    display: 'inline',
  },
  avatarWrapper: {
    padding: '15px',
  },
  avatar: {
    width: '240px',
    height: '160px',
  },
  detailWrapper: {
    padding: '15px',
  },
  metrics: {
    position: 'absolute',
    top: '15px',
    right: '15px',
  },
  location: {
    position: 'absolute',
    bottom: '20px',
  },
  badge: {
    right: -3,
    top: 13,
    border: `2px solid ${theme.palette.background.paper}`,
    padding: '0 4px',
  },
  bookOutputCard: {
    minHeight: '126px',
  },
  bookFormatCard: {
    minHeight: '88px',
    [theme.breakpoints.down('sm')]: {
      minHeight: '112px',
    },
  },
  bookTypeCard: {
    minHeight: '192px',
    [theme.breakpoints.down('sm')]: {
      minHeight: '214px',
    },
  },
}));

const Print = (props) => {
  const classes = useStyles();

  const { appVariant } = useContext(VariantContext);
  const { user } = useContext(UserContext);

  const history = useHistory();

  const [loading, setLoading] = useState(null);
  const [ready, setReady] = useState(null);
  const [prints, setPrints] = useState(null);
  const [currentPrint, setCurrentPrint] = useState(null);
  const [brandboxPreview, setBrandboxPreview] = useState(false);
  const [numPagesToPrint, setNumPagesToPrint] = useState(null);
  const [waitingForPdfDownload, setWaitingForPdfDownload] = useState(null);
  const [showPdfDownload, setShowPdfDownload] = useState(null);
  const [downloadUrl, setDownloadUrl] = useState(null);
  const [downloadFilename, setDownloadFilename] = useState(null);
  const [alreadyPrinted, setAlreadyPrinted] = useState(null);
  const [brandboxError, setBrandboxError] = useState(null);
  const [cartOpen, setCartOpen] = useState(false);
  const [quantity, setQuantity] = useState(1);
  const [paymentStart, setPaymentStart] = useState(false);
  const [paymentIsComplete, setPaymentIsComplete] = useState(null);
  const [paymentSuccess, setPaymentSuccess] = useState(null);

  const [printsInCart, setPrintsInCart] = useState([]);
  const [coupon, setCoupon] = useState(null);

  const preselectPrintId = props.location.search.split('?id=')[1];

  const url = new URL(window.location);
  const payloadParam = url.searchParams.get('payload');
  const intentParam = url.searchParams.get('payment_intent');
  const statusParam = url.searchParams.get('redirect_status');

  useEffect(() => {
    // Send print order after redirection from SofortÜberweisung payment page
    if (payloadParam && intentParam) {
      if (statusParam === 'failed') return setPaymentIsComplete(false);

      let payload;
      try {
        payload = JSON.parse(payloadParam);
      } catch (error) {
        //console.error('ERROR SOFORT REDIRECT URL:', error);
        setPaymentIsComplete(false);
        setPaymentSuccess(false);
        return false;
      }

      payload.paymentId = intentParam;
      console.log('POST PURCHASE AFTER REDIRECT', payload);
      const purchase = fetch(`${process.env.REACT_APP_API_URL}/payments/purchase-product`, {
        method: 'POST',
        body: JSON.stringify(payload),
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${localStorage.getItem('token')}`,
        },
      })
        .then((response) => response.json())
        .then((data) => {
          if (data.productId) {
            setPaymentSuccess(true);
            generatePaidPrint(data);
          }

          return data;
        })
        .then((result) => {
          if (result.error) {
            // The card had an error when trying to attach it to a customer.
            throw result;
          }
          return result;
        });
    }
    // TODO: integrate into print order workflow later
    fetch(`${process.env.REACT_APP_API_URL}/prints`, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${localStorage.getItem('token')}`,
      },
    })
      .then((response) => response.json())
      .then((data) => {
        setPrints(data.results);
        if (data.results[0]) {
          // TODO: set current print
          //setCurrentPrint(preselectPrintId || data.results[0].id);
        }
      })
      .catch((error) => {
        console.error(error);
      });
  }, []);

  const validationSchema = Yup.object({
    printId: Yup.string().required('Bitte Print auswählen'),
    format: Yup.string().required('Bitte Print-Format auswählen'),
    type: Yup.string().required('Bitte Print-Typ auswählen'),
    coupon_code: Yup.string().test('checkCouponCode', 'Dieser Coupon Code ist ungültig', async (value) => {
      if (!value) return true;
      return fetch(`${process.env.REACT_APP_API_URL}/products/coupon-code/${value}`).then(async (res) => {
        const { coupon } = await res.json();
        const discountAttribute = coupon.attributes.find((attribute) => attribute.name === 'Geldrabatt');
        const valid = !!discountAttribute;
        if (valid) setCoupon(coupon);

        return valid;
      });
    }),
  });

  const resetPreview = () => {
    setReady(false);
    setBrandboxPreview(null);
  };

  const { handleSubmit, handleChange, setFieldValue, values, touched, errors } = useFormik({
    initialValues: {
      printId: prints?.length < 2 && prints[0] ? prints[0].id : '',
      output: 'hardcover',
      format: '211x211',
      type: 'day',
      coupon_code: '',
    },
    enableReinitialize: true,
    validationSchema,
    validateOnChange: false,
    onSubmit: (values, { setStatus, setErrors, setFieldError }) => {
      if (ready) {
        console.log('FORMIK SUBMIT: ORDER PRINT', values);
        //const redirectAfterLogin = 'subscription';
        //history.push(redirectAfterLogin);
        //return;
      }

      setLoading(true);

      const payload = {
        ...values,
        preview: true,
      };

      fetch(
        `${process.env.REACT_APP_API_URL}/templates/generate`,
        {
          method: 'POST',
          body: JSON.stringify(payload),
          responseType: 'json', // 'blob'
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${localStorage.getItem('token')}`,
          },
        },
        { responseType: 'arraybuffer' }
      )
        /*.then((response) => {
          if (response.status !== 201) return;
          response.blob().then((blob) => {
            var url = URL.createObjectURL(blob);
            //saveAs(url, "preview.pdf");
            //window.open(url);
            //console.log(`https://drive.google.com/viewerng/viewer?embedded=true&url=${url}`);
            setLoading(false);
            setBrandboxPreview({ url: blob });
            setReady(true);
            return blob;
          });
        })*/
        .then((response) => {
          if (response.status !== 201) return;
          setLoading(false);
          setBrandboxPreview(true);
          setReady(true);
          return response.json();
        })
        .then((json) => {
          setNumPagesToPrint(json.numPages);
          console.log('NUM PAGES:', json);
        })
        .catch((error) => {
          console.error('GENERATE PREVIEW ERROR:', error);
        });
    },
  });

  const getPriceOfSelection = (numPages = 24) => {
    // TODO: get from woocommerce API
    let price;

    if (values.format === '210x297') price = 17.95;
    if (values.format === '211x211') price = 14.95;
    if (values.output === 'pdf') price = 4.95;

    if (values.output !== 'pdf') {
      price += 0.3 * (numPages > 24 ? numPages - 24 : 0);
    }

    return price;
  };

  const getCouponDiscount = () => {
    if (!coupon) return 0;

    const discountAttribute = coupon.attributes.find((attribute) => attribute.name === 'Zeitrabatt');
    const discount = discountAttribute.options[0];

    return discount;
  };

  const countryCode = user?.addresses?.shipping ? user?.addresses?.shipping.country : user?.addresses?.billing.country;
  const shippingCosts = {
    de: 7,
    at: 7,
    ch: 17.5,
  };
  const getShippingCosts = () => {
    if (values.output === 'pdf') return 0;
    return shippingCosts[countryCode];
  };

  const addPrintToCart = (values) => {
    setPrintsInCart([
      {
        printId: values.printId,
        output: values.output,
        format: values.format,
        type: values.type,
        title: prints.find((print) => print.id == values.printId)?.title,
        pages: numPagesToPrint,
        price: getPriceOfSelection(numPagesToPrint),
        quantity: 1,
      },
    ]);

    setCartOpen(true);
  };

  const handleIncrement = (print) => {
    //setQuantity(quantity + 1);
    print.quantity = print.quantity + 1;
    setPrintsInCart([print]);
  };

  const handleDecrement = (print) => {
    //setQuantity(quantity - 1);
    print.quantity = print.quantity - 1;
    setPrintsInCart([print]);
  };

  const generatePaidPrint = (payment) => {
    setPaymentIsComplete(true);

    const printData = { ...payment, printId: payment.printId, quantity: payment.cartContents[0].quantity };

    console.log('GENERATE PAID PRINT PAYLOAD:', printData);

    const payload = {
      ...printData,
      preview: false,
    };

    if (printData.output === 'pdf') {
      //values.printId = printData.printId;
      //console.log('WAITING', values);
      setWaitingForPdfDownload(true);
      showPdfDownload(false);
    }

    // GENERATE LIVE PDF:
    fetch(
      `${process.env.REACT_APP_API_URL}/templates/generate`,
      {
        method: 'POST',
        body: JSON.stringify(payload),
        responseType: 'blob',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${localStorage.getItem('token')}`,
        },
      },
      { responseType: 'arraybuffer' }
    )
      .then((response) => {
        const downloadUrl = `${process.env.REACT_APP_API_URL.replace('/v1', '')}/generated/${printData.printId}/${
          printData.format
        }/${printData.type}/print-output.pdf`;
        const downloadFilename = `${printData.printTitle}.pdf`;
        setDownloadUrl(downloadUrl);
        setDownloadFilename(downloadFilename);
        console.log('### GENERATE AFTER REDIRECT:', response.status, printData, payment, showPdfDownload);

        if (response.status === 400) return; // Wrong format
        if (response.status === 402) return; // No payment
        if (response.status === 405) return setAlreadyPrinted(true); // Payment used
        if (response.status !== 201) return; // all other, status must be 201 to pass

        setLoading(false);
        if (values.output === 'pdf' || printData.output === 'pdf') {
          setWaitingForPdfDownload(false);
          showPdfDownload(true);
        } else {
          handleTestOrder(printData);
        }
        setReady(true);
      })
      .catch((error) => {
        console.error('GENERATE LIVE ERROR:', error);
      });
  };

  const handleTestOrder = (printData) => {
    fetch(
      `${process.env.REACT_APP_API_URL}/printing`,
      {
        method: 'POST',
        body: JSON.stringify(printData),
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${localStorage.getItem('token')}`,
        },
      },
      { responseType: 'arraybuffer' }
    )
      .then((response) => {
        if (response.status !== 201) return;
        console.log('TESTPRINT', response);
      })
      .catch((error) => {
        console.error('PRINT ORDER ERROR:', error);
      });
  };

  if (intentParam && paymentIsComplete === null)
    return (
      <Grid
        container
        spacing={0}
        direction="column"
        alignItems="center"
        justifyContent="center"
        style={{ minHeight: '80vh' }}
      >
        <CircularProgress style={{ margin: '0 auto' }} />
      </Grid>
    );

  if (paymentIsComplete !== null) {
    return (
      <Grid
        container
        spacing={0}
        direction="column"
        alignItems="center"
        justifyContent="center"
        style={{ minHeight: '80vh' }}
      >
        <Grid item xs={6} style={{ maxWidth: '90vw' }}>
          <Typography variant="h5" gutterBottom style={{ padding: '10px' }}>
            {paymentIsComplete === true && waitingForPdfDownload === null && 'Dein Buch wird bald gedruckt.'}
            {paymentIsComplete === true &&
              !alreadyPrinted &&
              waitingForPdfDownload === true &&
              'Deine PDF wird generiert. Bitte warte einen Moment.'}
            {/*paymentIsComplete === true && alreadyPrinted && <span>Deine PDF wurde schon generiert, du kannst sie in einem <a href="/mybloomup">Profil</a> unter Belege herunterladen.</span>*/}
            {paymentIsComplete === true && showPdfDownload === true && (alreadyPrinted || waitingForPdfDownload === false) && (
              <span>
                <a href="#" onClick={() => saveAs(downloadUrl, downloadFilename) && false}>
                  Hier
                </a>{' '}
                kannst du dein fertiges PDF herunterladen. Die PDF findest du immer auch unter Belege als Download.
              </span>
            )}
            {paymentIsComplete === false && 'Bei der Zahlung ist ein Fehler aufgetreten.'}
          </Typography>
        </Grid>
        <Grid item xs={6} style={{ maxWidth: '90vw' }}>
          <Typography variant="body1">
            {paymentIsComplete === true && 'Du erhältst in Kürze eine Bestätigung per E-Mail.'}
            {paymentIsComplete === false && 'Bitte versuche es später noch einmal oder wende dich an den Support.'}
          </Typography>
        </Grid>
      </Grid>
    );
  }

  if (cartOpen) {
    const cartProductLabels = {
      day: 'Lebensbuch',
      photostream: 'Fotostream',
      '211x211': 'quadratisch',
      '210x297': 'A4 Hochformat',
    };

    return (
      <div className={classes.root}>
        <Container maxWidth="lg">
          <Typography variant="h3" gutterBottom>
            <b>Warenkorb</b>
          </Typography>
          <Grid container spacing={0} style={{ minHeight: '70vh' }}>
            <Grid item xs={12} md={8}>
              <Card>
                <CardContent>
                  <Typography variant="h6">Prints</Typography>
                  {printsInCart.map((print) => (
                    <div key={print.printId}>
                      <List dense={true}>
                        <ListItem>
                          {values.output !== 'pdf' && (
                            <ListItemText style={{ width: 'auto' }}>
                              <ButtonGroup size="small" aria-label="small outlined button group">
                                <Button disabled={printsInCart[0].quantity <= 1} onClick={() => handleDecrement(print)}>
                                  -
                                </Button>
                                <Button disabled>{printsInCart[0].quantity}</Button>
                                <Button disabled={printsInCart[0].quantity >= 100} onClick={() => handleIncrement(print)}>
                                  +
                                </Button>
                              </ButtonGroup>
                            </ListItemText>
                          )}
                          <ListItemText
                            primary={`${printsInCart[0].quantity} x ${cartProductLabels[printsInCart[0].type]} (${
                              printsInCart[0].output
                            }, ${cartProductLabels[printsInCart[0].format]}, ${printsInCart[0].format})`}
                            secondary={
                              <>
                                <Typography variant="caption">
                                  (
                                  {new Intl.NumberFormat('de-DE', { style: 'currency', currency: 'EUR' }).format(
                                    getPriceOfSelection()
                                  )}{' '}
                                  € inkl. 24 Seiten zzgl. 0,30 € je extra Seite)
                                </Typography>
                                <br />
                                <Typography variant="caption">
                                  {printsInCart[0].title} ({numPagesToPrint} Seiten
                                  {numPagesToPrint > 200 ? `, verteilt auf ${Math.ceil(numPagesToPrint / 200)} Bücher` : ''})
                                </Typography>
                              </>
                            }
                          />
                          <ListItemText
                            primary={new Intl.NumberFormat('de-DE', { style: 'currency', currency: 'EUR' }).format(
                              printsInCart[0].quantity * getPriceOfSelection(numPagesToPrint)
                            )}
                            secondary={<Typography variant="caption">inkl. 19% MwSt.</Typography>}
                          />
                        </ListItem>
                      </List>
                    </div>
                  ))}
                </CardContent>
              </Card>
            </Grid>

            <Grid item xs={6} md={4}>
              <Grid container justifyContent="center">
                <Grid item xs={12} md={8}>
                  <Typography variant="h6">{'Dein Warenkorb'}</Typography>
                </Grid>

                <Grid item xs={12} md={8}>
                  <Grid container justifyContent="center">
                    <Grid item xs={6}>
                      <Typography>{`Zwischensumme`}</Typography>
                    </Grid>
                    <Grid item xs={6}>
                      <Typography align="right">
                        {new Intl.NumberFormat('de-DE', { style: 'currency', currency: 'EUR' }).format(
                          printsInCart[0].quantity * getPriceOfSelection(numPagesToPrint)
                        )}
                      </Typography>
                      <Typography align="right">
                        <small>{`Preis inkl. 19% MwSt.`}</small>
                      </Typography>
                    </Grid>
                  </Grid>
                </Grid>

                {values.output !== 'pdf' && (
                  <Grid item xs={12} md={8}>
                    <Grid container justifyContent="center">
                      <Grid item xs={6}>
                        <Typography>{'Versand'}</Typography>
                      </Grid>
                      <Grid item xs={6}>
                        <Typography align="right">
                          {new Intl.NumberFormat('de-DE', { style: 'currency', currency: 'EUR' }).format(getShippingCosts())}
                        </Typography>
                      </Grid>
                    </Grid>
                  </Grid>
                )}

                {coupon && (
                  <Grid item xs={12} md={8}>
                    <Grid container justifyContent="center">
                      <Grid item xs={6}>
                        <Typography>{'Gutschein'}</Typography>
                      </Grid>
                      <Grid item xs={6}>
                        <Typography align="right">
                          -
                          {new Intl.NumberFormat('de-DE', { style: 'currency', currency: 'EUR' }).format(
                            getCouponDiscount()
                          )}
                        </Typography>
                      </Grid>
                    </Grid>
                  </Grid>
                )}

                <Grid item xs={12} md={8}>
                  <Grid container justifyContent="center">
                    <Grid item xs={6}>
                      <Typography>
                        <b>{'Summe'}</b>
                      </Typography>
                    </Grid>
                    <Grid item xs={6}>
                      <Typography align="right">
                        <b>
                          {new Intl.NumberFormat('de-DE', { style: 'currency', currency: 'EUR' }).format(
                            printsInCart[0].quantity * getPriceOfSelection(numPagesToPrint) +
                              getShippingCosts() -
                              getCouponDiscount()
                          )}
                        </b>
                      </Typography>
                    </Grid>
                  </Grid>
                </Grid>

                <Grid item xs={12} md={8}>
                  <br />
                  <Button onClick={() => setPaymentStart(true)} fullWidth variant="contained" color="primary" size="large">
                    Zur Kasse
                  </Button>
                  <br />
                  <br />
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Container>

        <StripeCheckoutModal
          modalOpen={paymentStart}
          onComplete={(payment) => generatePaidPrint(payment)}
          onCancel={() => {
            setPaymentStart(false);
            setPaymentIsComplete(null);
          }}
          product="print"
          paymentMethods={['sofort', 'card']}
          params={{
            printId: values.printId,
            printTitle: prints.find((print) => print.id === values.printId).title,
            output: values.output,
            format: values.format,
            type: values.type,
            pages: numPagesToPrint,
            quantity: printsInCart[0].quantity,
          }}
          cartContents={[
            // preparing for shopping cart system that comes later
            ...printsInCart,
            // { printId: values.printId, quantity: quantity, product: 847 /* <- fetch productIds from woocommerce API */ },
          ]}
          amount={printsInCart[0].quantity * getPriceOfSelection(numPagesToPrint)}
          shippingCosts={getShippingCosts()}
          couponCode={values.coupon_code}
        />
      </div>
    );
  }

  return (
    <div className={classes.root}>
      <Container maxWidth="lg">
        <form onSubmit={handleSubmit} className={classes.form} noValidate>
          <Grid container>
            <Grid item md={5} xs={12}>
              <Typography variant="h3" gutterBottom>
                {appVariant === 'bloomup' ? 'Lebensbuch bestellen' : 'Projekt-Print beauftragen'}
              </Typography>
              <br />
              <Grid container spacing={3}>
                <Grid item xs={12}>
                  <Typography>
                    <b>{'1. Wähle ein Fotobuch aus'}</b>
                  </Typography>
                </Grid>

                <Grid item xs={12} md={8}>
                  <Card elevation={5} style={{ minWidth: '290px' }}>
                    <CardContent>
                      <FormControl fullWidth variant="outlined" margin="none">
                        <InputLabel id="print-select-label">
                          {!prints?.length ? 'Keine Prints vorhanden' : 'Print'}
                        </InputLabel>
                        <Select
                          id="printId"
                          name="printId"
                          value={values.printId}
                          label="Print"
                          disabled={!prints?.length}
                          onChange={(event) => {
                            setReady(false);
                            handleChange(event);
                          }}
                          error={touched.printId && Boolean(errors.printId)}
                        >
                          {prints?.map((print) => (
                            <MenuItem value={print.id} key={print.id}>
                              {print.title}
                            </MenuItem>
                          ))}
                        </Select>
                        {touched.printId && errors.printId && (
                          <FormHelperText htmlFor="render-select" error>
                            {errors.printId}
                          </FormHelperText>
                        )}
                      </FormControl>
                    </CardContent>
                  </Card>
                </Grid>

                <Hidden xsDown>
                  <Grid item md={4} xs={1}>
                    &nbsp;
                  </Grid>
                </Hidden>

                <Grid item xs={12}>
                  <Typography gutterBottom>
                    <b>{'2. Wie soll der Print aussehen?'}</b>
                  </Typography>
                </Grid>

                <Grid item xs={12}>
                  <FormControl component="fieldset">
                    <RadioGroup aria-label="output" name="output" value={values.output} onChange={handleChange}>
                      <Grid container spacing={3}>
                        <Grid item md={5} xs={6}>
                          <Card elevation={5} className={classes.bookOutputCard}>
                            <CardContent>
                              <FormControl component="fieldset">
                                <FormGroup aria-label="position" row>
                                  <FormControlLabel
                                    value="hardcover"
                                    checked={values.output === 'hardcover'}
                                    control={<Radio />}
                                    label="Hard-cover"
                                    labelPlacement="start"
                                  />
                                </FormGroup>
                              </FormControl>
                              <br />
                              <Typography variant="caption">
                                mind. <b>24</b> - max. <b>200</b> Seiten
                                <br />
                                <b>ab 14,95 €</b>
                                <br />
                                <b>inkl. 24 Seiten</b> & 19% ges.
                                <br />
                                MwSt zzgl. 0,30 € je extra
                                <br />
                                Seite & Versand
                              </Typography>
                            </CardContent>
                          </Card>
                        </Grid>

                        <Grid item md={5} xs={6}>
                          <Card elevation={5} className={classes.bookOutputCard}>
                            <CardContent>
                              <FormControl component="fieldset">
                                <FormGroup aria-label="position" row>
                                  <FormControlLabel
                                    value="softcover"
                                    checked={values.output === 'softcover'}
                                    control={<Radio />}
                                    label="Soft-cover"
                                    labelPlacement="start"
                                  />
                                </FormGroup>
                              </FormControl>
                              <br />
                              <Typography variant="caption">
                                mind. <b>24</b> - max. <b>200</b> Seiten
                                <br />
                                <b>ab 14,95 €</b>
                                <br />
                                <b>inkl. 24 Seiten</b> & 19% ges.
                                <br />
                                MwSt zzgl. 0,30 € je extra
                                <br />
                                Seite & Versand
                              </Typography>
                            </CardContent>
                          </Card>
                        </Grid>

                        <Grid item md={5} xs={6}>
                          <Card elevation={5} className={classes.bookOutputCard}>
                            <CardContent>
                              <FormControl component="fieldset">
                                <FormGroup aria-label="position" row>
                                  <FormControlLabel
                                    value="pdf"
                                    checked={values.output === 'pdf'}
                                    control={<Radio />}
                                    label="PDF"
                                    labelPlacement="start"
                                  />
                                </FormGroup>
                              </FormControl>
                              <br />
                              <Typography noWrap variant="caption">
                                <b>4,95 €</b>
                                <br />
                                - unbegrenzte Seiten
                                <br />- ohne Wasserzeichen
                              </Typography>
                            </CardContent>
                          </Card>
                        </Grid>
                      </Grid>
                    </RadioGroup>
                  </FormControl>
                </Grid>

                <Grid item xs={12}>
                  <Typography gutterBottom>
                    <b>{'3. Welches Format hättest Du gern?'}</b>
                  </Typography>
                </Grid>

                <Grid item xs={12}>
                  <FormControl component="fieldset">
                    <RadioGroup aria-label="format" name="format" value={values.format} onChange={handleChange}>
                      <Grid container spacing={3}>
                        <Grid item md={5} xs={6}>
                          <Card elevation={2} className={classes.bookFormatCard}>
                            <CardContent>
                              <FormControl component="fieldset">
                                <FormGroup aria-label="position" row>
                                  <FormControlLabel
                                    value="211x211"
                                    checked={values.format === '211x211'}
                                    control={<Radio />}
                                    label="Quadratisch (21x21cm)"
                                    labelPlacement="start"
                                  />
                                </FormGroup>
                              </FormControl>
                            </CardContent>
                          </Card>
                        </Grid>

                        <Grid item md={5} xs={6}>
                          <Card elevation={4} className={classes.bookFormatCard}>
                            <CardContent>
                              <FormControl component="fieldset">
                                <FormGroup aria-label="position" row>
                                  <FormControlLabel
                                    value="210x297"
                                    checked={values.format === '210x297'}
                                    control={<Radio />}
                                    label="A4 Hochformat (21x29,7cm)"
                                    labelPlacement="start"
                                  />
                                </FormGroup>
                              </FormControl>
                            </CardContent>
                          </Card>
                        </Grid>

                        <Grid item md={5} xs={6}>
                          <Card elevation={4} className={classes.bookFormatCard}>
                            <CardContent>
                              <FormControl component="fieldset">
                                <FormGroup aria-label="position" row>
                                  <FormControlLabel
                                    value="297x210"
                                    checked={values.format === '297x210'}
                                    disabled={true}
                                    control={<Radio />}
                                    label="A4 quer (29,7x21cm)"
                                    labelPlacement="start"
                                  />
                                </FormGroup>
                              </FormControl>
                            </CardContent>
                          </Card>
                        </Grid>
                      </Grid>
                    </RadioGroup>
                  </FormControl>
                </Grid>

                <Grid item xs={12}>
                  <Typography gutterBottom>
                    <b>
                      {appVariant === 'bloomup'
                        ? '4. Wie möchtest Du Deine Erlebnisse & Vorhaben dargestellt haben?'
                        : '4. Wie möchtest Du Dein Projekt dargestellt haben?'}
                    </b>
                  </Typography>
                  <Typography variant="body2" gutterBottom>
                    {'Wähle Dein für Dich passendes Design:'}
                  </Typography>
                </Grid>

                <Grid item xs={12}>
                  <FormControl component="fieldset">
                    <RadioGroup aria-label="type" name="type" value={values.type} onChange={handleChange}>
                      <Grid container spacing={3}>
                        <Grid item md={5} xs={6}>
                          <Card elevation={5} className={classes.bookTypeCard}>
                            <CardContent>
                              <FormControl component="fieldset">
                                <FormGroup aria-label="position" row>
                                  <FormControlLabel
                                    value="day"
                                    checked={values.type === 'day'}
                                    control={<Radio />}
                                    label="Lebensbuch"
                                    labelPlacement="start"
                                  />
                                </FormGroup>
                              </FormControl>
                              <br />
                              <Typography variant="caption">- all Deine/Eure Einträge mit Texten &amp; Bildern</Typography>
                              <br />
                              <Typography variant="caption">- inkl. Vorwort</Typography>
                              <br />
                              <Typography variant="caption">- auf Wunsch mit Best of my Day &amp; Ideen</Typography>
                            </CardContent>
                          </Card>
                        </Grid>

                        {/*<Grid item xs={5}>
                          <Card elevation={5} className={classes.bookTypeCard}>
                            <CardContent>
                              <FormControl component="fieldset">
                                <FormGroup aria-label="position" row>
                                  <FormControlLabel
                                    value="week"
                                    checked={values.type === 'week'}
                                    disabled={true}
                                    control={<Radio />}
                                    label="Wochenübersicht"
                                    labelPlacement="start"
                                  />
                                </FormGroup>
                              </FormControl>
                              <Typography variant="body2">
                                z.B. 8 Wochen im handlichen Überblick inkl. Bilder, Texte, Ideen
                              </Typography>
                            </CardContent>
                          </Card>
                        </Grid>

                        <Grid item xs={2}>
                          &nbsp;
                        </Grid>

                        <Grid item xs={5}>
                          <Card elevation={5} className={classes.bookTypeCard}>
                            <CardContent>
                              <FormControl component="fieldset">
                                <FormGroup aria-label="position" row>
                                  <FormControlLabel
                                    value="month"
                                    checked={values.type === 'month'}
                                    disabled={true}
                                    control={<Radio />}
                                    label="Monatsübersicht"
                                    labelPlacement="start"
                                  />
                                </FormGroup>
                              </FormControl>
                              <Typography variant="body2">
                                z.B. 1 Jahr im handlichen Überblick inkl. Bilder, Texte, Ideen
                              </Typography>
                            </CardContent>
                          </Card>
                        </Grid>*/}

                        <Grid item md={5} xs={6}>
                          <Card elevation={5} className={classes.bookTypeCard}>
                            <CardContent>
                              <FormControl component="fieldset">
                                <FormGroup aria-label="position" row>
                                  <FormControlLabel
                                    value="photostream"
                                    checked={values.type === 'photostream'}
                                    control={<Radio />}
                                    label="Fotostream"
                                    labelPlacement="start"
                                  />
                                </FormGroup>
                              </FormControl>
                              <br />
                              <Typography variant="caption">Erhalte Deine Fotos in einem Buch - ohne Texte</Typography>
                            </CardContent>
                          </Card>
                        </Grid>

                        <Grid item xs={2}>
                          &nbsp;
                        </Grid>
                      </Grid>
                    </RadioGroup>
                  </FormControl>
                </Grid>

                <Grid item xs={12}>
                  <Grid container>
                    <Grid item xs={6} style={{ padding: '8px 0' }}>
                      <Typography>
                        <b>Ich habe einen Gutschein</b>
                      </Typography>
                    </Grid>
                    <Grid item xs={4}>
                      <FormControl>
                        <TextField
                          variant="outlined"
                          size="small"
                          id="coupon_code"
                          name="coupon_code"
                          autoComplete="coupon_code"
                          value={values.coupon_code}
                          onChange={handleChange}
                          error={touched.coupon_code && Boolean(errors.coupon_code)}
                          helperText={touched.coupon_code && errors.coupon_code}
                        />
                      </FormControl>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
              <Grid item xs={12}>
                &nbsp;
              </Grid>
            </Grid>
            <Grid item md={7} xs={12}>
              <Card elevation={5} style={{ minHeight: '680px' }}>
                <CardContent>
                  <Box p={8}>
                    {values.printId && ready && (
                      <Button
                        size="medium"
                        variant="contained"
                        color="primary"
                        style={{ float: 'left' }}
                        onClick={(event) => {
                          resetPreview();
                          handleSubmit(event);
                        }}
                      >
                        Neu generieren
                      </Button>
                    )}

                    <Typography align="center" variant="h5" gutterBottom>
                      {'So wird dein Buch aussehen'}
                    </Typography>

                    {numPagesToPrint > 200 && (
                      <Typography variant="body2" color="secondary" style={{ float: 'right', width: '65%' }}>
                        {
                          'ACHTUNG: Die maximale Seitenanzahl von 200 ist erreicht. Um Deinen Print vollständig drucken zu können, müssen '
                        }
                        {Math.ceil(numPagesToPrint / 200)}
                        {' Bücher gedruckt werden.'}
                      </Typography>
                    )}

                    {loading && (
                      <Box p={8} style={{ display: 'flex', justifyContent: 'center', flexDirection: 'column' }}>
                        <Typography variant="body2" align="center">
                          Dein Buch wird generiert.
                        </Typography>
                        <Typography variant="body2" align="center" gutterBottom>
                          Dies kann einige Minuten dauern.
                        </Typography>
                        <CircularProgress style={{ margin: '0 auto' }} />
                      </Box>
                    )}
                    {!loading && !ready && (
                      <Button
                        onClick={handleSubmit}
                        fullWidth
                        size="large"
                        variant="contained"
                        color="primary"
                        disabled={!prints?.length}
                      >
                        Vorschau generieren
                      </Button>
                    )}

                    {false && (
                      <Button fullWidth size="large" variant="contained" color="default" onClick={handleTestOrder}>
                        Testprint
                      </Button>
                    )}

                    {values.printId && ready && (
                      <Grid container spacing={3}>
                        <Grid item xs={12}>
                          {user && process.env.REACT_APP_API_URL.includes('my.getbloomup.com') && (
                            <embed
                              src={`https://drive.google.com/viewerng/viewer?embedded=true&url=${process.env.REACT_APP_API_URL.replace(
                                '/v1',
                                ''
                              )}/generated/${values.printId}/${values.format}/${
                                values.type
                              }/print-preview.pdf`} /*/generated/${user?.id}/brandbox-preview.pdf*/
                              width="558"
                              height="558"
                            />
                          )}
                          {user && !process.env.REACT_APP_API_URL.includes('my.getbloomup.com') && (
                            <>
                              <div style={{ color: 'white' }}>(local viewer only)</div>
                              <embed
                                src={`${process.env.REACT_APP_API_URL.split('/v1')[0]}/generated/${values.printId}/${
                                  values.format
                                }/${values.type}/print-preview.pdf`} /*/generated/${user?.id}/brandbox-preview.pdf*/
                                width="558"
                                height="558"
                                type="application/pdf"
                              />
                            </>
                          )}
                          {/*<embed src={brandboxPreview.url} width="558" height="558" ></embed>*/}
                          <p>
                            {brandboxPreview && (
                              <a
                                /*href={`${process.env.REACT_APP_API_URL.replace('/v1', '')}/generated/${currentPrint}/print-preview.pdf`}*/
                                /*download={`${prints.find((print) => print.id === values.print).title}-preview.pdf`}*/
                                /*target="_blank"*/
                                href="#"
                                onClick={() =>
                                  saveAs(
                                    `${process.env.REACT_APP_API_URL.replace('/v1', '')}/generated/${values.printId}/${
                                      values.format
                                    }/${values.type}/print-preview.pdf`,
                                    `${prints.find((print) => print.id === values.printId).title}-preview.pdf`
                                  ) && false
                                }
                              >
                                Gratis PDF Download (mit Wasserzeichen)
                              </a>
                            )}
                          </p>
                          <p>{brandboxError && <p>Error: {brandboxError}</p>}</p>
                        </Grid>
                        <Grid item xs={6}>
                          <Typography variant="h6">
                            <b>
                              {new Intl.NumberFormat('de-DE', { style: 'currency', currency: 'EUR' }).format(
                                getPriceOfSelection(numPagesToPrint)
                              )}
                            </b>{' '}
                            ({numPagesToPrint} Seiten)
                          </Typography>
                        </Grid>
                        <Grid item xs={6}>
                          <Button
                            onClick={() => addPrintToCart(values)}
                            disabled={!user?.addresses?.billing}
                            fullWidth
                            size="large"
                            variant="contained"
                            color="primary"
                          >
                            zum Warenkorb
                          </Button>
                          {!user?.addresses?.billing && (
                            <Typography variant="caption" color="secondary">
                              Du musst in Deinem <a href="/mybloomup#address_settings">Profil</a> erst eine Belegadresse
                              hinterlegen.
                            </Typography>
                          )}
                        </Grid>
                      </Grid>
                    )}
                  </Box>
                </CardContent>
              </Card>
            </Grid>
          </Grid>
        </form>
      </Container>
    </div>
  );
};

export default Print;
