import React, { useEffect, useState } from 'react';
import ReactDOM from 'react-dom';
import { Link } from 'react-router-dom';
import {
  Container,
  Box,
  Paper,
  Grid,
  Typography,
  FormControl,
  Select,
  MenuItem,
  InputLabel,
  CircularProgress,
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { LightgalleryProvider, LightgalleryItem } from 'react-lightgallery';

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
    marginTop: '50px',
    marginBottom: '50px',
  },
  backLink: {
    textDecoration: 'none',
  },
  subHeader: {
    color: 'gray',
  },
  cardContent: {
    padding: '30px 20px',
  },
}));

const Knowledge = () => {
  const classes = useStyles();

  const [pageTitle, setPageTitle] = useState(); // eslint-disable-line no-unused-vars
  const [glossaryContent, setGlossaryContent] = useState();
  const [visible, setVisible] = useState(true); // eslint-disable-line no-unused-vars

  const PhotoItem = ({ image, thumb, group }) => (
    <div style={{ maxWidth: '250px', width: '200px', padding: '5px' }}>
      <LightgalleryItem group={group} src={image} thumb={thumb}>
        <img src={image} style={{ width: '100%' }} />
      </LightgalleryItem>
    </div>
  );

  useEffect(() => {
    fetch(`${process.env.REACT_APP_API_URL}/glossary`, {
      method: 'GET',
      headers: { 'Content-Type': 'application/json' },
    })
      .then((res) => {
        if (res.status === 200) {
          res.json().then((data) => {
            setPageTitle(data.glossar_topic);

            const entriesOrdered = data.glossar_entries.reduce((accumulator, item) => {
              const firstLetter = item.glossar_entry_headline.charAt(0).toUpperCase();
              if (firstLetter in accumulator) {
                accumulator[firstLetter].push(item);
              } else {
                accumulator[firstLetter] = [item];
              }

              return accumulator;
            }, {});
            const entriesSorted = Object.fromEntries(Object.entries(entriesOrdered).sort());
            setGlossaryContent(entriesSorted);
          });
        }
      })
      .catch((e) => {
        console.error('FETCH ERROR', e);
      });
  }, []);

  const handleChange = async (filter) => {
    console.log('glossary filter', filter);
  };

  return (
    <div className={classes.root}>
      <link rel="stylesheet" href="https://cdn.jsdelivr.net/npm/lightgallery.js@1.2.0/dist/css/lightgallery.min.css" />

      <Container maxWidth="lg" style={{ minHeight: '75vh' }}>
        <Grid container spacing={3}>
          <Grid item xs={12}>
            <Box style={{ paddingTop: '50px', paddingBottom: '10px', width: '100%' }}>
              <Typography variant="h4">bloomUp Wissen: Glossar</Typography>
              <Typography variant="h6" color="primary" gutterBottom>
                <Link to="/" className={classes.backLink}>
                  ← zurück zur Übersicht
                </Link>
              </Typography>
              <br />
              <Typography variant="h6" className={classes.subHeader}>
                Wusstest Du, dass die App über 100 Funktionen hat?
                <br />
                Hier erfährst Du alles darüber.
              </Typography>
            </Box>
          </Grid>

          <Grid item md={9} xs={12}>
            <Paper elevation={5} className={classes.cardContent}>
              <Grid container spacing={3}>
                <Grid item xs={9}>
                  <Typography variant="h5" gutterBottom>
                    {'App-Begriffe'}
                  </Typography>
                </Grid>
                <Grid item xs={3}>
                  <FormControl fullWidth variant="filled">
                    <InputLabel id="glossary-select-label">Filtern</InputLabel>
                    <Select
                      labelId="glossary-select-label"
                      id="glossarySelect"
                      value={'all'}
                      label="Filtern"
                      onChange={handleChange}
                    >
                      <MenuItem value={'all'}>Alle Einträge</MenuItem>
                      <MenuItem value={'media'}>Mit Medien</MenuItem>
                    </Select>
                  </FormControl>
                </Grid>

                {!glossaryContent && (
                  <Grid item xs={12}>
                    <Box style={{ display: 'flex', justifyContent: 'center' }}>
                      <CircularProgress />
                    </Box>
                  </Grid>
                )}

                {glossaryContent &&
                  Object.keys(glossaryContent).map((letter) => {
                    let content = [
                      <Grid item xs={12}>
                        <Typography variant="h6">- {letter.toUpperCase()} -</Typography>
                      </Grid>,
                    ];

                    content.push(
                      glossaryContent[letter].map((entry) => {
                        return (
                          <>
                            <Grid key={entry.glossar_entry_headline} item xs={3}>
                              <Typography variant="subtitle2">
                                <b>{entry.glossar_entry_headline}</b>
                              </Typography>
                            </Grid>
                            <Grid item xs={9}>
                              <Typography variant="body2" dangerouslySetInnerHTML={{ __html: entry.glossar_text }} />
                              {entry.glossar_gallery && visible ? (
                                <LightgalleryProvider
                                  onBeforeOpen={() => console.info('onBeforeOpen')}
                                  onAfterOpen={() => console.info('onAfterOpen')}
                                  onSlideItemLoad={() => console.info('onSlideItemLoad')}
                                  onBeforeSlide={() => console.info('onBeforeSlide')}
                                  onAfterSlide={() => console.info('onAfterSlide')}
                                  onBeforePrevSlide={() => console.info('onBeforePrevSlide')}
                                  onBeforeNextSlide={() => console.info('onBeforeNextSlide')}
                                  onDragstart={() => console.info('onDragstart')}
                                  onDragmove={() => console.info('onDragmove')}
                                  onDragend={() => console.info('onDragend')}
                                  onSlideClick={() => console.info('onSlideClick')}
                                  onBeforeClose={() => console.info('onBeforeClose')}
                                  onCloseAfter={() => console.info('onCloseAfter')}
                                >
                                  <div
                                    style={{
                                      display: 'flex',
                                      alignItems: 'center',
                                      justifyContent: 'center',
                                    }}
                                  >
                                    {entry.glossar_gallery.map((image, i) => (
                                      <PhotoItem
                                        key={i}
                                        thumb={image.sizes['thumbnail']}
                                        image={image.sizes['large']}
                                        group="images"
                                      />
                                    ))}
                                  </div>
                                </LightgalleryProvider>
                              ) : null}

                              {entry.glossar_video_group && visible ? (
                                <LightgalleryProvider
                                  onBeforeOpen={() => console.info('onBeforeOpen')}
                                  onAfterOpen={(event, element) => {
                                    console.info('onAfterOpen');
                                    const nodes = ReactDOM.findDOMNode(element.outer).getElementsByClassName('lg-img-wrap');
                                    Array.from(nodes).forEach((node, i) => {
                                      const videoUrl = entry.glossar_video_group[i].glossar_video_entry.url;
                                      node.innerHTML = `<video controls autoplay width="90%" src="${videoUrl}"></video>`;
                                    });
                                  }}
                                  onSlideItemLoad={() => console.info('onSlideItemLoad')}
                                  onBeforeSlide={() => console.info('onBeforeSlide')}
                                  onAfterSlide={() => console.info('onAfterSlide')}
                                  onBeforePrevSlide={() => console.info('onBeforePrevSlide')}
                                  onBeforeNextSlide={() => console.info('onBeforeNextSlide')}
                                  onDragstart={() => console.info('onDragstart')}
                                  onDragmove={() => console.info('onDragmove')}
                                  onDragend={() => console.info('onDragend')}
                                  onSlideClick={() => console.info('onSlideClick')}
                                  onBeforeClose={() => console.info('onBeforeClose')}
                                  onCloseAfter={() => console.info('onCloseAfter')}
                                  plugins={['lg-video.js']}
                                >
                                  <div
                                    style={{
                                      display: 'flex',
                                      alignItems: 'center',
                                      justifyContent: 'center',
                                    }}
                                  >
                                    {entry.glossar_video_group.map((video, i) => {
                                      console.log('MAP VIDEO', video);
                                      return (
                                        <LightgalleryItem group="any" src={video.glossar_video_entry.url}>
                                          <a style={{ padding: '5px' }}>
                                            <img src={video.glossar_video_entry.icon} />
                                          </a>
                                        </LightgalleryItem>
                                      );
                                    })}
                                  </div>
                                </LightgalleryProvider>
                              ) : null}

                              {/*entry.glossar_gallery && entry.glossar_gallery.map(image => {
                                console.log(image.sizes['thumbnail-width'])
                                const { 'thumbnail': url, 'thumbnail-width': width, 'thumbnail-height': height } = image.sizes;
                                return <img src={url} width={width} height={height} />
                              })*/}
                              {/*entry.glossar_video_group && 'videos:'*/}
                            </Grid>
                          </>
                        );
                      })
                    );

                    return content;
                  })}
              </Grid>
            </Paper>
          </Grid>
        </Grid>
      </Container>
    </div>
  );
};

export default Knowledge;
