import { useState, useEffect } from 'react';
import jwtDecode from 'jwt-decode';

export default function useFindUser() {
  const [user, setUser] = useState(null);
  const [isLoading, setLoading] = useState(true);
  useEffect(() => {
    async function findUser() {
      let token = localStorage.getItem('token');
      if (token) {
        const uid = jwtDecode(token).sub;

        fetch(`${process.env.REACT_APP_API_URL}/users/${uid}`, {
          method: 'GET',
          headers: {
            'Content-Type': 'application/json',
            Authorization: 'Bearer ' + localStorage.getItem('token'),
          },
        })
          .then((res) => {
            if (res.status === 200) {
              res.json().then((data) => {
                setUser(data);
              });
            } else if (res.status === 401) {
              localStorage.clear();
              window.history.go(0);
            }
            setLoading(false);
          })
          .catch((err) => {
            setLoading(false);
          });
      }
    }

    findUser();
  }, []);
  return {
    user,
    setUser,
    isLoading,
  };
}
