import React from 'react';

import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';

const NoPageFound = (props /*{ text = 'Die Seite wurde nicht gefunden' }*/) => {
  return (
    <div>
      <Grid
        container
        spacing={0}
        direction="column"
        alignItems="center"
        justifyContent="center"
        style={{ minHeight: '70vh' }}
      >
        <Grid item xs={12} style={{ maxWidth: '90vw', marginBottom: '40px' }}>
          <Typography variant="h5" gutterBottom style={{ padding: '10px' }}>
            {props.text}
          </Typography>
        </Grid>

        <Grid item xs={12} style={{ marginBottom: '20px' }}>
          <a href="/">
            <img src="/images/bloomUp-my-life-logo-primary.png" alt="bloomUp my life logo" />
          </a>
        </Grid>

        <Grid item xs={12}>
          <a href="/" style={{ textDecoration: 'none' }}>
            <Typography variant="h6" color="primary">
              www.getbloomup.com
            </Typography>
          </a>
        </Grid>
      </Grid>
    </div>
  );
};

NoPageFound.defaultProps = {
  text: 'Die Seite wurde nicht gefunden',
};

export default NoPageFound;
