import React from 'react';
import { Card, CardContent, CardActions, Button, Typography } from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';

const styles = (theme) => ({
  contentCard: {
    display: 'flex',
    flexDirection: 'column',
    //minWidth: '290px',
    minHeight: '256px',
    padding: '15px',
  },
  contentCardAction: {
    marginTop: 'auto',
  },
  contentCardDescription: {
    minHeight: '60px',
  },
});

const OrderLinkBox = (props) => {
  const { header, description, linkText, linkColor, linkUrl } = props;
  const { classes } = props;

  return (
    <Card elevation={5} p={4} className={classes.contentCard}>
      <CardContent>
        <Typography variant="h6" gutterBottom>
          {typeof header === 'function' ? header.call() : header}
        </Typography>
        <Typography variant="body2" gutterBottom className={classes.contentCardDescription}>
          {typeof description === 'function' ? description.call() : description}
        </Typography>
      </CardContent>
      <CardActions className={classes.contentCardAction}>
        <Button
          href={typeof linkUrl === 'function' ? linkUrl.call() : linkUrl}
          fullWidth
          size="large"
          variant="contained"
          color={typeof linkColor === 'function' ? linkColor.call() : linkColor || 'primary'}
          className={classes.contentCardButton}
        >
          {typeof linkText === 'function' ? linkText.call() : linkText}
        </Button>
      </CardActions>
    </Card>
  );
};

export default withStyles(styles)(OrderLinkBox);
